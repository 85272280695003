import { FileExcelOutlined } from '@ant-design/icons';
import { Button, Select, Space, Table, Tag, Tooltip } from 'antd';
import Search from 'antd/lib/input/Search';
import { DefaultOptionType } from 'antd/lib/select';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import React, { useContext, useEffect, useState } from 'react';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import taskApi from '../../../api/TaskApi';
import ConstantLabel from '../../../components/ConstantLabel/ConstantLabel';
import LayoutComponent from '../../../components/LayoutComponent/LayoutComponent';
import TaskStatusComponent from '../../../components/TaskStatusComponent/TaskStatusComponent';
import CustomContext from '../../../context/CustomContext';
import { Page } from '../../../model/elements';
import { Task } from '../../../model/entities';
import { TaskStatusType, TaskType, taskTypes } from '../../../model/types';
import notificationService from '../../../services/NotificationService';
import tableService from '../../../services/TableService';
import styles from './TasksPage.module.scss';

/**
 * Returns the tasks page.
 * @returns the tasks page.
 */
const TasksPage = (): React.ReactElement => {
    /*** HOOKS ***/

    const intl = useIntl();
    const [tasksPage, setTasksPage] = useState<Page<Task>>();
    const [loading, setLoading] = useState<'loading' | 'exporting'>();
    const [searchText, setSearchText] = useState<string>();
    const [taskType, setTaskType] = useState<TaskType>();
    const context = useContext(CustomContext);

    /*** EFFECTS ***/

    useEffect(() => {
        const init = async () => {
            try {
                setLoading('loading');

                context.updateApplication(undefined);

                const tasksPage = await taskApi.list(0, tableService.pageSize, 'audit.updated', false);

                setTasksPage(tasksPage);
            } catch (error) {
                notificationService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [context, intl]);

    /*** METHODS ***/

    const list = async (pagination: TablePaginationConfig, filters: any, sorter: any) => {
        try {
            setLoading('loading');
            const page = pagination.current!;
            const pageSize = pagination.pageSize!;
            const sortField = sorter.field;
            const sortOrder = sorter.order === 'ascend';
            const tasksPage = await taskApi.list(page - 1, pageSize, sortField, sortOrder, searchText, taskType);
            setTasksPage(tasksPage);
        } catch (error) {
            notificationService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    const search = async (searchText: string) => {
        try {
            setLoading('loading');

            const pageSize = tasksPage!.size;
            const sortField = tasksPage!.sort.field!;
            const sortOrder = tasksPage!.sort.order!;
            const tasksPageNew = await taskApi.list(0, pageSize, sortField, sortOrder, searchText, taskType);

            setTasksPage(tasksPageNew);
            setSearchText(searchText);
        } catch (error) {
            notificationService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    const filterTaskType = async (taskType: TaskType) => {
        try {
            setLoading('loading');

            const pageSize = tasksPage!.size;
            const sortField = tasksPage!.sort.field!;
            const sortOrder = tasksPage!.sort.order!;
            const tasksPageNew = await taskApi.list(0, pageSize, sortField, sortOrder, searchText, taskType);

            setTasksPage(tasksPageNew);
            setTaskType(taskType);
        } catch (error) {
            notificationService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    const exportSpreadSheet = async () => {
        try {
            setLoading('exporting');

            const sortField = tasksPage!.sort.field!;
            const sortOrder = tasksPage!.sort.order!;
            await taskApi.exportSpreadSheet(sortField, sortOrder, searchText);
        } catch (error) {
            notificationService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    const getPath = (task: Task): string => {
        switch (task.taskType) {
            case 'SYNC':
                return 'syncs';
            case 'MEMBER_COMPANY_UPDATE':
            case 'MEMBER_STATUS_UPDATE':
            case 'MEMBER_COST_CENTER_UPDATE':
            case 'MEMBER_IMPORT':
            case 'PROJECT_MEMBER_COMPANY_UPDATE':
            case 'PROJECT_MEMBER_ROLES_UPDATE':
            case 'PROJECT_MEMBER_IMPORT':
            case 'PROJECT_MEMBER_DELETE':
                return 'members';
            case 'PROJECT_ISSUES_COPY':
                return 'project-issues';
            case 'PROJECT_IMPORT':
                return 'projects';
            case 'SYNC_SMART_DELIVERY':
                return 'smart-deliveries';
            case 'SYNC_PROJECT_CONVERSION_PROCESS':
                return 'conversion-projects';
            case 'SYNC_TASK_COPY_ISSUE_FILE':
                return 'copy-issue-files';
            default:
                return '';
        }
    };

    /*** COMPONENTS ***/

    const taskTypeOptions: DefaultOptionType[] = [
        {
            label: <FormattedMessage id="task.taskType.group.SYNC" />,
            options: syncTaskTypes.map((t) => ({
                label: <ConstantLabel value={t} prefix="task.taskType." />,
                value: t,
            })),
        },
        {
            label: <FormattedMessage id="task.taskType.group.SETUP" />,
            options: setupTaskTypes.map((t) => ({
                label: <ConstantLabel value={t} prefix="task.taskType." />,
                value: t,
            })),
        },
        {
            label: <FormattedMessage id="task.taskType.group.OTHER" />,
            options: otherTaskTypes.map((t) => ({
                label: <ConstantLabel value={t} prefix="task.taskType." />,
                value: t,
            })),
        },
    ];

    const items = tasksPage ? tasksPage.content : [];
    const columns: ColumnsType<Task> = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
            sorter: true,
            align: 'right',
            width: 50,
            render: (value: string, task: Task) => <Link to={`/task-${getPath(task)}/${task.id}`}>{value}</Link>,
        },
        {
            title: <FormattedMessage id="task.name" />,
            dataIndex: 'name',
            key: 'name',
            sorter: true,
            render: (value: string, task: Task) => (
                <Link to={`/task-${getPath(task)}/${task.id}`}>{getTaskName(task)}</Link>
            ),
        },
        {
            title: <FormattedMessage id="task.audit.creator" />,
            dataIndex: 'audit.creator.firstName',
            key: 'creator',
            width: 250,
            sorter: true,
            render: (value: string, task: Task) => (
                <Link to={`/task-${getPath(task)}/${task.id}`}>
                    {task.audit && task.audit.creator && `${task.audit.creator.firstName} ${task.audit.creator.lastName}`}
                </Link>
            ),
        },
        {
            title: <FormattedMessage id="task.audit.created" />,
            dataIndex: 'audit.created',
            key: 'created',
            sorter: true,
            width: 220,
            align: 'center',
            render: (value: string, task: Task) => (
                <Link to={`/task-${getPath(task)}/${task.id}`}>
                    <FormattedDate
                        value={task.audit!.created as any}
                        day="2-digit"
                        month="2-digit"
                        year="numeric"
                        hour="2-digit"
                        minute="2-digit"
                        second="2-digit"
                    />
                </Link>
            ),
        },
        {
            title: <FormattedMessage id="task.audit.updated" />,
            dataIndex: 'audit.updated',
            key: 'updated',
            sorter: true,
            defaultSortOrder: 'descend',
            width: 220,
            align: 'center',
            render: (value: string, task: Task) => (
                <Link to={`/task-${getPath(task)}/${task.id}`}>
                    <FormattedDate
                        value={task.audit!.updated as any}
                        day="2-digit"
                        month="2-digit"
                        year="numeric"
                        hour="2-digit"
                        minute="2-digit"
                        second="2-digit"
                    />
                </Link>
            ),
        },
        {
            title: <FormattedMessage id="task.taskType" />,
            dataIndex: 'taskType',
            key: 'taskType',
            sorter: true,
            width: 120,
            align: 'center',
            render: (taskType: TaskType, task: Task) => (
                <Link to={`/task-${getPath(task)}/${task.id}`}>
                    <CustomTaskTypeComponent taskType={taskType} />
                </Link>
            ),
        },
        {
            title: <FormattedMessage id="task.status" />,
            dataIndex: 'status',
            key: 'status',
            sorter: true,
            width: 150,
            align: 'center',
            render: (status: TaskStatusType, task: Task) => (
                <Link to={`/task-${getPath(task)}/${task.id}`}>
                    <TaskStatusComponent status={status} />
                </Link>
            ),
        },
    ];

    const placeholder: string = intl.formatMessage({ id: 'tasks.search' });
    const taskTypePlaceholder: string = intl.formatMessage({ id: 'tasks.taskType' });

    return (
        <LayoutComponent pageId="tasks">
            <div className="toolbar">
                <Search placeholder={placeholder} onSearch={search} size="large" className={styles.search} />
                <Select
                    placeholder={taskTypePlaceholder}
                    onChange={filterTaskType}
                    size="large"
                    className={styles.select}
                    allowClear
                    options={taskTypeOptions}
                />
                <Space>
                    <Tooltip title={<FormattedMessage id="tasks.exportSpreadSheet" />} placement="left">
                        <Button
                            size="large"
                            icon={<FileExcelOutlined />}
                            onClick={exportSpreadSheet}
                            loading={loading === 'exporting'}
                        ></Button>
                    </Tooltip>
                </Space>
            </div>
            <Table
                dataSource={items}
                columns={columns}
                pagination={tableService.createPagination(tasksPage, { position: ['bottomRight'] })}
                rowKey="id"
                onChange={list}
                sortDirections={['ascend', 'descend']}
                showSorterTooltip={false}
                loading={loading === 'loading'}
            />
        </LayoutComponent>
    );
};

export default TasksPage;

// task types
const syncTaskTypes: TaskType[] = ['SYNC', 'PROJECT_ISSUES_COPY'];
const setupTaskTypes: TaskType[] = [
    'PROJECT_IMPORT',
    'PROJECT_MEMBER_IMPORT',
    'MEMBER_IMPORT',
    'PROJECT_MEMBER_DELETE',
    'PROJECT_MEMBER_COMPANY_UPDATE',
    'PROJECT_MEMBER_ROLES_UPDATE',
    'MEMBER_COMPANY_UPDATE',
    'MEMBER_COST_CENTER_UPDATE',
    'MEMBER_STATUS_UPDATE',
];
const otherTaskTypes = taskTypes.filter((t) => !syncTaskTypes.includes(t) && !setupTaskTypes.includes(t));

/**
 * Returns a task name.
 * @param task the task
 * @returns  the task name
 */
const getTaskName = (task: Task): React.ReactElement | string | undefined => {
    switch (task.taskType) {
        case 'SYNC':
        case 'PROJECT_ISSUES_COPY':
        case 'SYNC_PROJECT_CONVERSION_PROCESS':
        case 'SYNC_TASK_COPY_ISSUE_FILE':
        case 'SYNC_SMART_DELIVERY':
            return task.name;
        default:
            return <FormattedMessage id={task.taskType} />;
    }
};

/**
 * Returns a custom task type component.
 * @param props the props
 * @returns the custom task type component
 */
const CustomTaskTypeComponent: React.FC<CustomTaskTypeComponentProps> = (props) => {
    const { taskType } = props;

    if (taskType && syncTaskTypes.includes(taskType)) {
        return (
            <Tag color="blue">
                <ConstantLabel value={taskType} prefix="task.taskType." />
            </Tag>
        );
    } else {
        return (
            <Tag color="default">
                <ConstantLabel value={taskType} prefix="task.taskType." />
            </Tag>
        );
    }
};

interface CustomTaskTypeComponentProps {
    taskType?: TaskType;
}
