import Icon from '@ant-design/icons';
import { Button, Popconfirm, Table, Tag, Tooltip } from 'antd';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import { Moment } from 'moment';
import React, { useEffect, useState } from 'react';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import scheduleApi from '../../../../../api/ScheduleApi';
import { Page } from '../../../../../model/elements';
import { Connection, Schedule } from '../../../../../model/entities';
import { FrequencyType } from '../../../../../model/types';
import { ReactComponent as AddSvg } from '../../../../../resources/images/add.svg';
import { ReactComponent as TrashCanSvg } from '../../../../../resources/images/trash-can.svg';
import notificationService from '../../../../../services/NotificationService';
import tableService from '../../../../../services/TableService';
import ConnectionScheduleModal from './ConnectionScheduleModal/ConnectionScheduleModal';
import styles from './ConnectionSchedulesComponent.module.scss';

/**
 * Returns the connection schedules component.
 * @props the props
 * @returns the connection schedules component.
 */
const ConnectionSchedulesComponent = (props: Props): React.ReactElement => {
    const { connection } = props;
    /*** HOOKS ***/

    const intl = useIntl();
    const [schedulesPage, setSchedulesPage] = useState<Page<Schedule>>();
    const [schedule, setSchedule] = useState<Schedule>();
    const [scheduleModalVisible, setScheduleModalVisible] = useState<boolean>();
    const [loading, setLoading] = useState<boolean>();
    const [deleting, setDeleting] = useState<number>();

    /*** EFFECTS ***/

    useEffect(() => {
        const init = async () => {
            try {
                setLoading(true);

                const page = 0;
                const size = schedulesPage?.size || tableService.pageSize;
                const sortField = schedulesPage?.sort.field || 'name';
                const sortOrder = schedulesPage?.sort.order || true;
                await listSchedules(page, size, sortField, sortOrder, connection.id!);

                setSchedule(undefined);
                setScheduleModalVisible(undefined);
            } catch (error) {
                notificationService.displayError(error, intl);
            } finally {
                setLoading(false);
            }
        };
        init();
    }, [connection.id, intl, schedulesPage?.size, schedulesPage?.sort.field, schedulesPage?.sort.order]);

    /*** METHODS ***/

    const list = async () => {
        try {
            setLoading(true);

            const page = 0;
            const size = schedulesPage?.size || tableService.pageSize;
            const sortField = schedulesPage?.sort.field || 'name';
            const sortOrder = schedulesPage?.sort.order || true;
            await listSchedules(page, size, sortField, sortOrder, connection.id!);

            setSchedule(undefined);
            setScheduleModalVisible(undefined);
        } catch (error) {
            notificationService.displayError(error, intl);
        } finally {
            setLoading(false);
        }
    };

    const paginate = async (pagination: TablePaginationConfig, filters: any, sorter: any) => {
        try {
            setLoading(true);
            const page = pagination.current! - 1;
            const size = pagination.pageSize!;
            const sortField = sorter.field;
            const sortOrder = sorter.order === 'ascend';
            await listSchedules(page, size, sortField, sortOrder, connection.id!);
        } catch (error) {
            notificationService.displayError(error, intl);
        } finally {
            setLoading(false);
        }
    };

    const listSchedules = async (
        page: number,
        size: number,
        sortField: string,
        sortOrder: boolean,
        connectionId: number,
    ) => {
        const schedulesPage = await scheduleApi.list(page, size, sortField, sortOrder, connectionId);
        setSchedulesPage(schedulesPage);
    };

    const showScheduleModal = (scheduleModalVisible: boolean, schedule?: Schedule) => {
        setSchedule(schedule);
        setScheduleModalVisible(scheduleModalVisible);
    };

    const remove = async (schedule: Schedule) => {
        try {
            setDeleting(schedule.id);
            await scheduleApi.delete(schedule);
            await list();
        } catch (error) {
            notificationService.displayError(error, intl);
        } finally {
            setDeleting(undefined);
        }
    };

    /*** COMPONENTS ***/

    const items = schedulesPage ? schedulesPage.content : [];
    const columns: ColumnsType<Schedule> = [
        {
            title: <FormattedMessage id="schedule.name" />,
            dataIndex: 'name',
            key: 'name',
            sorter: true,
            defaultSortOrder: 'ascend',
            render: (name: string, schedule: Schedule) => (
                <span className="link" onClick={() => showScheduleModal(true, schedule)}>
                    {name}
                </span>
            ),
        },
        {
            title: <FormattedMessage id="schedule.started" />,
            dataIndex: 'started',
            key: 'started',
            sorter: true,
            align: 'center',
            width: 200,
            render: (started: Moment, schedule: Schedule) => (
                <span className="link" onClick={() => showScheduleModal(true, schedule)}>
                    <FormattedDate
                        value={schedule.started as any}
                        day="2-digit"
                        month="2-digit"
                        year="numeric"
                        hour="2-digit"
                        minute="2-digit"
                    />
                </span>
            ),
        },
        {
            title: <FormattedMessage id="schedule.frequency" />,
            dataIndex: 'frequency',
            key: 'frequency',
            sorter: true,
            align: 'center',
            width: 200,
            render: (frequency: FrequencyType, schedule: Schedule) => (
                <span className="link" onClick={() => showScheduleModal(true, schedule)}>
                    {getFrequencyTag(frequency)}
                </span>
            ),
        },
        {
            dataIndex: 'id',
            key: 'action',
            align: 'center',
            width: '100px',
            render: (id: number, schedule: Schedule) => (
                <Popconfirm
                    title={<FormattedMessage id="schedule.delete" />}
                    onConfirm={() => remove(schedule)}
                    okText={<FormattedMessage id="button.yes" />}
                    cancelText={<FormattedMessage id="button.no" />}
                    placement="left"
                >
                    <Button type="ghost" icon={<Icon component={TrashCanSvg} />} loading={deleting === id} />
                </Popconfirm>
            ),
        },
    ];

    return (
        <>
            <div className={`toolbar ${styles.tab}`}>
                <Tooltip title={<FormattedMessage id="schedules.new.tooltip" />}>
                    <Button
                        type="primary"
                        size="large"
                        icon={<Icon component={AddSvg} />}
                        onClick={() => showScheduleModal(true)}
                    ></Button>
                </Tooltip>
            </div>
            <Table
                dataSource={items}
                columns={columns}
                onChange={paginate}
                pagination={tableService.createPagination(schedulesPage, { position: ['bottomRight'], pageSize: 10 })}
                rowKey="id"
                sortDirections={['ascend', 'descend']}
                showSorterTooltip={false}
                loading={loading}
            />
            {scheduleModalVisible && (
                <ConnectionScheduleModal
                    connection={connection}
                    scheduleId={schedule?.id}
                    visible={scheduleModalVisible}
                    onSave={list}
                    onCancel={() => showScheduleModal(false)}
                />
            )}
        </>
    );
};

export default ConnectionSchedulesComponent;

interface Props {
    connection: Connection;
}

const getFrequencyTag = (frequency: FrequencyType): React.ReactElement | undefined => {
    return (
        <>
            {frequency === 'DAILY' && (
                <Tag color="green">
                    <FormattedMessage id={frequency} />
                </Tag>
            )}
            {frequency === 'WEEKLY' && (
                <Tag color="blue">
                    <FormattedMessage id={frequency} />
                </Tag>
            )}
            {frequency === 'MONTHLY' && (
                <Tag color="cyan">
                    <FormattedMessage id={frequency} />
                </Tag>
            )}
            {frequency === 'ANNUALLY' && (
                <Tag color="geekblue">
                    <FormattedMessage id={frequency} />
                </Tag>
            )}
        </>
    );
};
