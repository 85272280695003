import Icon from '@ant-design/icons';
import { Button, Descriptions, Space, Table, Tooltip } from 'antd';
import Search from 'antd/lib/input/Search';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import React, { useEffect, useState } from 'react';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { Link, useParams } from 'react-router-dom';
import taskMemberActionApi from '../../../../api/TaskMemberActionApi';
import taskMemberApi from '../../../../api/TaskMemberApi';
import LayoutComponent from '../../../../components/LayoutComponent/LayoutComponent';
import TaskStatusComponent from '../../../../components/TaskStatusComponent/TaskStatusComponent';
import { Page } from '../../../../model/elements';
import { Role, TaskMember, TaskMemberAction } from '../../../../model/entities';
import { TaskStatusType } from '../../../../model/types';
import { ReactComponent as BackSvg } from '../../../../resources/images/back.svg';
import { ReactComponent as RefreshSvg } from '../../../../resources/images/refresh.svg';
import notificationService from '../../../../services/NotificationService';
import stringService from '../../../../services/StringService';
import tableService from '../../../../services/TableService';
import styles from './TaskMemberPage.module.scss';

/**
 * Returns the task member page.
 * @returns the task member page.
 */
const TaskMemberPage = (): React.ReactElement => {
    /*** HOOKS ***/

    const intl = useIntl();
    const params = useParams<ParamsType>();
    const [task, setTask] = useState<TaskMember>();
    const [taskMemberActionsPage, setTaskMemberActionsPage] = useState<Page<TaskMemberAction>>();
    const [loading, setLoading] = useState<boolean>();
    const [searchText, setSearchText] = useState<string>();

    /*** EFFECTS ***/

    useEffect(() => {
        const init = async () => {
            try {
                setLoading(true);
                const task = await taskMemberApi.get(+params.id);
                setTask(task);

                const page = 0;
                const size = taskMemberActionsPage?.size || tableService.pageSize;
                const sortField = taskMemberActionsPage?.sort.field || 'email';
                const sortOrder = taskMemberActionsPage?.sort.order || true;
                await listTaskMemberActions(page, size, sortField, sortOrder, task.id!, searchText);
            } catch (error) {
                notificationService.displayError(error, intl);
            } finally {
                setLoading(false);
            }
        };
        init();
    }, [
        intl,
        params.id,
        searchText,
        taskMemberActionsPage?.size,
        taskMemberActionsPage?.sort.field,
        taskMemberActionsPage?.sort.order,
    ]);

    /*** METHODS ***/

    const list = async () => {
        try {
            setLoading(true);
            const task = await taskMemberApi.get(+params.id);
            setTask(task);

            const page = 0;
            const size = taskMemberActionsPage?.size || tableService.pageSize;
            const sortField = taskMemberActionsPage?.sort.field || 'email';
            const sortOrder = taskMemberActionsPage?.sort.order || true;
            await listTaskMemberActions(page, size, sortField, sortOrder, task.id!, searchText);
        } catch (error) {
            notificationService.displayError(error, intl);
        } finally {
            setLoading(false);
        }
    };

    const paginate = async (pagination: TablePaginationConfig, filters: any, sorter: any) => {
        try {
            setLoading(true);
            const page = pagination.current! - 1;
            const pageSize = pagination.pageSize!;
            const sortField = sorter.field;
            const sortOrder = sorter.order === 'ascend';
            listTaskMemberActions(page, pageSize, sortField, sortOrder, task!.id!, searchText);
        } catch (error) {
            notificationService.displayError(error, intl);
        } finally {
            setLoading(false);
        }
    };

    const search = async (searchText: string) => {
        try {
            setLoading(true);
            setSearchText(searchText);

            const page = 0;
            const pageSize = taskMemberActionsPage!.size;
            const sortField = taskMemberActionsPage!.sort.field!;
            const sortOrder = taskMemberActionsPage!.sort.order!;
            listTaskMemberActions(page, pageSize, sortField, sortOrder, task!.id!, searchText);
        } catch (error) {
            notificationService.displayError(error, intl);
        } finally {
            setLoading(false);
        }
    };

    const listTaskMemberActions = async (
        page: number,
        size: number,
        sortField: string,
        sortOrder: boolean,
        taskId: number,
        searchText?: string,
    ) => {
        const taskMemberActionsPage = await taskMemberActionApi.list(
            page,
            size,
            sortField,
            sortOrder,
            taskId,
            searchText,
        );
        setTaskMemberActionsPage(taskMemberActionsPage);
    };

    /*** COMPONENTS ***/

    const placeholder: string = intl.formatMessage({ id: 'taskMemberActions.search' });
    const items = taskMemberActionsPage ? taskMemberActionsPage.content : [];
    const columns: ColumnsType<TaskMemberAction> = [
        {
            title: <FormattedMessage id="taskMemberAction.user" />,
            dataIndex: 'firstName',
            key: 'firstName',
            width: 250,
            sorter: true,
            render: (value: string, taskMemberAction: TaskMemberAction) => (
                <>
                    {taskMemberAction.firstName} {taskMemberAction.lastName}
                </>
            ),
        },
        {
            title: <FormattedMessage id="taskMemberAction.email" />,
            dataIndex: 'email',
            key: 'email',
            width: 250,
            sorter: true,
            defaultSortOrder: 'ascend',
            render: (value: string, taskMemberAction: TaskMemberAction) => value,
        },
        {
            title: <FormattedMessage id="taskMemberAction.action" />,
            dataIndex: 'action',
            key: 'action',
            render: (action: any, taskMemberAction: TaskMemberAction) => getAction(task!, taskMemberAction),
        },
        {
            title: <FormattedMessage id="taskMemberAction.audit.created" />,
            dataIndex: 'auditCreated',
            key: 'created',
            sorter: true,
            width: 240,
            align: 'center',
            render: (value: string, taskMemberAction: TaskMemberAction) => (
                <FormattedDate
                    value={taskMemberAction.audit!.created as any}
                    day="2-digit"
                    month="2-digit"
                    year="numeric"
                    hour="2-digit"
                    minute="2-digit"
                    second="2-digit"
                />
            ),
        },
        {
            title: <FormattedMessage id="taskMemberAction.status" />,
            dataIndex: 'status',
            key: 'status',
            sorter: true,
            width: 200,
            align: 'center',
            render: (status: TaskStatusType, taskMemberAction: TaskMemberAction) => getStatus(task!, taskMemberAction),
        },
    ];

    return (
        <LayoutComponent pageId="tasks">
            <Descriptions
                title={
                    <>
                        <span className={styles.title}>
                            #{task?.id} - {task?.name && <FormattedMessage id={task?.name} />}
                        </span>
                        <TaskStatusComponent status={task?.status} />
                        <Space>
                            <Tooltip title={<FormattedMessage id="button.back" />}>
                                <Link to="/tasks">
                                    <Button type="ghost" size="small" icon={<Icon component={BackSvg} />} />
                                </Link>
                            </Tooltip>
                            <Tooltip title={<FormattedMessage id="button.refresh" />}>
                                <Button
                                    type="ghost"
                                    size="small"
                                    icon={<Icon component={RefreshSvg} />}
                                    onClick={list}
                                />
                            </Tooltip>
                        </Space>
                    </>
                }
                column={2}
                size="small"
                className={styles.summary}
            >
                <Descriptions.Item label={<FormattedMessage id="task.audit.creator" />} span={2}>
                    {task?.audit?.creator.firstName} {task?.audit?.creator.lastName}
                </Descriptions.Item>
                <Descriptions.Item label={<FormattedMessage id="task.audit.created" />}>
                    <FormattedDate
                        value={task?.audit?.created as any}
                        day="2-digit"
                        month="2-digit"
                        year="numeric"
                        hour="2-digit"
                        minute="2-digit"
                        second="2-digit"
                    />
                </Descriptions.Item>
                <Descriptions.Item label={<FormattedMessage id="task.audit.updated" />}>
                    <FormattedDate
                        value={task?.audit?.updated as any}
                        day="2-digit"
                        month="2-digit"
                        year="numeric"
                        hour="2-digit"
                        minute="2-digit"
                        second="2-digit"
                    />
                </Descriptions.Item>
                <Descriptions.Item>{task?.description}</Descriptions.Item>
            </Descriptions>
            <div className="toolbar">
                <Search placeholder={placeholder} onSearch={search} size="large" className="search" />
            </div>
            <Table
                dataSource={items}
                columns={columns}
                pagination={tableService.createPagination(taskMemberActionsPage, { position: ['bottomRight'] })}
                rowKey="id"
                onChange={paginate}
                sortDirections={['ascend', 'descend']}
                showSorterTooltip={false}
                loading={loading}
            />
        </LayoutComponent>
    );
};

export default TaskMemberPage;

type ParamsType = { id: string };

const getAction = (task: TaskMember, taskMemberAction: TaskMemberAction): React.ReactElement | string | undefined => {
    switch (task.taskType) {
        case 'MEMBER_COMPANY_UPDATE':
            return (
                <>
                    <FormattedMessage id={`taskMemberAction.action.${task.taskType}`} />{' '}
                    {taskMemberAction.action.entity.company?.name}
                </>
            );
        case 'MEMBER_STATUS_UPDATE':
            return (
                <>
                    <FormattedMessage id={`taskMemberAction.action.${task.taskType}`} />{' '}
                    <FormattedMessage id={taskMemberAction.action.entity.bim360Status} />
                </>
            );

        case 'MEMBER_IMPORT':
            return (
                <>
                    <FormattedMessage id={`taskMemberAction.action.${task.taskType}`} />
                </>
            );
        case 'PROJECT_MEMBER_COMPANY_UPDATE':
            return (
                <>
                    <FormattedMessage id={`taskMemberAction.action.${task.taskType}`} />{' '}
                    {taskMemberAction.action.entity.company?.name}{' '}
                    {taskMemberAction.action.entity.project.name && (
                        <FormattedMessage id={`taskMemberAction.action.${task.taskType}.in`} />
                    )}{' '}
                    {taskMemberAction.action.entity.project.name}
                </>
            );
        case 'PROJECT_MEMBER_ROLES_UPDATE':
            return (
                <>
                    <FormattedMessage id={`taskMemberAction.action.${task.taskType}`} />{' '}
                    {taskMemberAction.action.entity.roles
                        .map((r: Role) => r.name)
                        .sort((a: string, b: string) => stringService.sort(a, b))
                        .join(', ')}{' '}
                    {taskMemberAction.action.entity.project.name && (
                        <FormattedMessage id={`taskMemberAction.action.${task.taskType}.in`} />
                    )}{' '}
                    {taskMemberAction.action.entity.project.name}
                </>
            );
        case 'PROJECT_MEMBER_IMPORT':
        case 'PROJECT_MEMBER_DELETE':
            return (
                <>
                    <FormattedMessage id={`taskMemberAction.action.${task.taskType}`} />{' '}
                    {taskMemberAction.action.entity.project.name}
                </>
            );
        default:
            break;
    }
};

const getStatus = (task: TaskMember, taskMemberAction: TaskMemberAction): React.ReactElement | string | undefined => {
    switch (task.taskType) {
        case 'MEMBER_COMPANY_UPDATE':
        case 'MEMBER_STATUS_UPDATE':
        case 'MEMBER_COST_CENTER_UPDATE':
        case 'MEMBER_IMPORT':
        case 'PROJECT_MEMBER_COMPANY_UPDATE':
        case 'PROJECT_MEMBER_ROLES_UPDATE':
        case 'PROJECT_MEMBER_IMPORT':
            return <TaskStatusComponent status={taskMemberAction.status} />;
        case 'PROJECT_MEMBER_DELETE':
            return (
                <TaskStatusComponent status={taskMemberAction.status} error={taskMemberAction.action.status?.error} />
            );
        default:
            break;
    }
};
