import { Form, Modal, Select } from 'antd';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Company } from '../../../../model/entities';
import stringService from '../../../../services/StringService';

/**
 * This component represents an update company modal.
 * @param props - the props
 */
export const UpdateRolesModal: React.FC<Props> = (props) => {
    const [form] = Form.useForm();
    const [saving, setSaving] = useState(false);
    const roleOptions = props.roles.map((role) => (
        <Select.Option key={role.id} value={role.id}>
            {role.name}
        </Select.Option>
    ));
    const cancel = async () => {
        form.resetFields();
        props.onCancel();
    };
    const update = async () => {
        try {
            setSaving(true);
            const values = await form.validateFields();
            await props.onUpdate(values);
            form.resetFields();
        } finally {
            setSaving(false);
        }
    };

    return (
        <Modal
            title={<FormattedMessage id="projectMembers.updateRoles.modal.title" />}
            open={props.visible}
            onCancel={cancel}
            onOk={async () => await update()}
            okButtonProps={{ loading: saving }}
            cancelButtonProps={{ disabled: saving }}
        >
            <Form form={form} colon={false} layout="vertical">
                <Form.Item label={<FormattedMessage id="projectMembers.updateRoles.modal.description" />} name="roles">
                    <Select
                        size="large"
                        allowClear
                        showSearch
                        filterOption={stringService.filterOptions}
                        mode="multiple"
                    >
                        {roleOptions}
                    </Select>
                </Form.Item>
            </Form>
        </Modal>
    );
};
export default UpdateRolesModal;

interface Props {
    visible?: boolean;
    onUpdate: (values: any) => Promise<void>;
    onCancel: () => void;
    roles: Company[];
}
