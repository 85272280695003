import { Folder } from '../model/entities';
import { ProviderType } from '../model/types';
import stringService from '../services/StringService';
import { proxyApiAxios } from './CustomAxios';

class FolderApi {
    list = async (provider: ProviderType, hubId: string, projectId: string, folderId?: string): Promise<Folder[]> => {
        const formattedProjectId = this.getFormattedProjectId(provider, projectId);
        const response = await proxyApiAxios.get<Folder[]>('/folders', {
            params: { provider, hubId, projectId: formattedProjectId, folderId },
        });
        const folders = response.data.sort((a, b) => stringService.sort(a.name, b.name));

        return folders;
    };

    get = async (id: string, provider: ProviderType, hubId: string, projectId: string): Promise<Folder> => {
        const formattedProjectId = this.getFormattedProjectId(provider, projectId);
        const response = await proxyApiAxios.get<Folder>(`/folders/${id}`, {
            params: { provider, hubId, projectId: formattedProjectId },
        });

        return response.data;
    };

    private getFormattedProjectId = (provider: ProviderType, projectId: string) => {
        let formattedProjectId = projectId;
        const reg = /^(b[.])/;
        if ((provider === 'ACC' || provider === 'BIM360') && !reg.test(projectId)) {
            formattedProjectId = `b.${projectId}`;
        }
        return formattedProjectId;
    };
}

const folderApi: FolderApi = new FolderApi();
export default folderApi;
