import { InfoCircleOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { TaskMember } from '../../../../../model/entities';
import styles from './ResultComponent.module.scss';

/**
 * Returns the members import result component.
 * @props the props
 * @returns the members import result component.
 */
const ResultComponent = (props: Props): React.ReactElement => {
    const { task } = props;
    /*** HOOKS ***/

    /*** EFFECTS ***/

    /*** METHODS ***/

    /*** COMPONENTS ***/

    return (
        <>
            <div className={styles.result}>
                <InfoCircleOutlined className={styles.icon} />
                <FormattedMessage id="projectMembers.import.steps.3.success" />
            </div>
            <div className={styles.buttons}>
                <Link to={`/task-members/${task.id}`}>
                    <Button type="primary">
                        <FormattedMessage id="projectMembers.import.steps.3.viewTask" tagName="span" />
                    </Button>
                </Link>
            </div>
        </>
    );
};

export default ResultComponent;

interface Props {
    task: TaskMember;
}
