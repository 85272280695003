import Layout, { Footer, Header } from 'antd/lib/layout/layout';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { PageType } from '../../model/types';
import HeaderComponent from '../HeaderComponent/HeaderComponent';
import styles from './LayoutComponent.module.scss';
import MenuComponent from '../MenuComponent/MenuComponent';

/**
 * Returns the layout component.
 * @param props the props
 * @returns the layout component
 */
const LayoutComponent = (props: Props): React.ReactElement => {
    const { children, pageId, style } = props;

    /*** HOOKS ***/

    /*** COMPONENTS */

    return (
        <Layout className={styles.layout} style={pageId === 'home' ? style : undefined}>
            <Header className={styles.header}>
                <HeaderComponent />
            </Header>
            <MenuComponent pageId={pageId} />
            <div className={styles.content} style={style}>
                {children}
            </div>
            <Footer className={styles.footer}>
                <div className={styles.links}>
                    <a href="/" target="_blank" rel="noreferrer">
                        <FormattedMessage id="footer.help" />
                    </a>
                </div>
                <a href="https://www.modelical.com">© Modelical</a>
            </Footer>
        </Layout>
    );
};

export default LayoutComponent;

interface Props {
    pageId?: PageType;
    style?: React.CSSProperties;
    children: React.ReactNode;
}
