import FileSaver from 'file-saver';
import moment from 'moment';
import { EntityWithStatus, File, FileExportExcel } from '../model/entities';
import { ProviderType } from '../model/types';
import stringService from '../services/StringService';
import { proxyApiAxios } from './CustomAxios';

class FileApi {
    list = async (provider: ProviderType, projectId: string, folderId: string, hubId?: string): Promise<File[]> => {
        const response = await proxyApiAxios.get<File[]>('/files', {
            params: { provider, projectId, folderId, hubId },
        });
        response.data.forEach((d) => (d.updatedAt = d.updatedAt && moment.utc(d.updatedAt).local()));
        const files = response.data.sort((a, b) => stringService.sort(a.name, b.name));

        return files;
    };

    search = async (
        provider: ProviderType,
        projectId: string,
        folderId: string,
        fileNames?: string,
        fileTypes?: string,
    ): Promise<File[]> => {
        const response = await proxyApiAxios.get<File[]>('/files/search', {
            params: { provider, projectId, folderId, fileNames, fileTypes },
        });
        response.data.forEach((d) => (d.updatedAt = d.updatedAt && moment.utc(d.updatedAt).local()));
        const files = response.data.sort((a, b) => stringService.sort(a.name, b.name));

        return files;
    };

    updateCustomAttributes = async (files: File[]): Promise<EntityWithStatus<File>[]> => {
        const response = await proxyApiAxios.patch<EntityWithStatus<File>[]>(`/files`, files, {
            params: { customAttributes: '' },
        });
        return response.data;
    };

    exportSpreadSheet = async (fileDtos: FileExportExcel[]) => {
        const response = await proxyApiAxios.post<Blob>(`/files/spreadsheets`, fileDtos, {
            responseType: 'blob',
        });
        FileSaver.saveAs(response.data, 'files.xlsx');
    };
}

const fileApi: FileApi = new FileApi();
export default fileApi;
