import { Divider, Dropdown, message, Space } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import React, { useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import configuration from '../../configs/IConfiguration';
import CustomContext from '../../context/CustomContext';
import { Organization } from '../../model/entities';
import cellnexLogo from '../../resources/images/cellnex/cellnex-logo.svg';
import clipIcon from '../../resources/images/clip-icon.svg';
import { ReactComponent as LogoutSvg } from '../../resources/images/logout.svg';
import modelicalLogo from '../../resources/images/modelical-logo.svg';
import authService from '../../security/IAuthService';
import SharePointComponent from '../SharePointComponent/SharePointComponent';
import styles from './HeaderComponent.module.scss';
import ProcoreComponent from '../ProcoreComponent/ProcoreComponent';

/**
 * Returns the header component.
 * @returns the header component.
 */
const HeaderComponent = (): React.ReactElement => {
    /*** HOOKS ***/

    /*** EFFECTS ***/

    /*** METHODS ***/

    /*** COMPONENTS ***/

    return (
        <div className={styles.header}>
            <div className={styles.logo}>
                <LogoComponent />
            </div>
            <div className={styles.apps}>
                <TitleComponent />
            </div>
            <div className={styles.settings}>
                <SettingsComponent />
            </div>
        </div>
    );
};

export default HeaderComponent;

/**
 * Returns the logo component.
 * @returns the logo component
 */
const LogoComponent = (): React.ReactElement => {
    return (
        <a className={styles.logobox} href={`${configuration.getParentUrl()}`}>
            <CustomImageComponent cellnex={cellnexLogo} predefined={modelicalLogo} />
        </a>
    );
};

/**
 * Returns the title component.
 * @returns the title component
 */
const TitleComponent = (): React.ReactElement => {
    /*** HOOKS ***/

    /*** COMPONENTS ***/

    return (
        <div className={styles.appMenuLabel}>
            <img src={clipIcon} alt="" />{' '}
            <Link to="/">
                <span className={styles.title}>
                    <FormattedMessage id="header.apps.link" />
                </span>
            </Link>
        </div>
    );
};

/**
 * Return the settings component.
 * @returns the settings component
 */
const SettingsComponent = (): React.ReactElement => {
    /*** HOOKS ***/

    const intl = useIntl();
    const context = useContext(CustomContext);
    const { auth, updateAuth } = context;

    /*** METHODS ***/

    const logOut = async () => {
        try {
            await authService.signOut();
            updateAuth();
        } catch (error) {
            message.error(intl.formatMessage({ id: 'status.logoutError' }));
        }
    };

    /*** VISUAL ***/

    const organization: Organization | undefined = auth?.organization;
    const organizations: ItemType[] = auth
        ? auth.organizations.map((o) => ({
              key: o.id,
              label: <a href={`/?organizationId=${o.id}`}>{o.name}</a>,
          }))
        : [];

    if (auth) {
        return (
            <Space className={styles.space}>
                {auth.initialized && (
                    <>
                        <ProcoreComponent />
                        <SharePointComponent />
                        <Link to="/settings" className={styles.spaceitem}>
                            {auth?.firstName} {auth?.lastName}
                        </Link>
                        <Divider type="vertical" className={styles.verticalDivider} />
                        <Dropdown menu={{ items: organizations }} className={styles.organizations}>
                            <span>{organization?.name}</span>
                        </Dropdown>
                    </>
                )}
                <span onClick={logOut} className={styles.signout}>
                    <LogoutSvg className={styles.logo} />
                </span>
            </Space>
        );
    } else {
        return <></>;
    }
};

/**
 * Returns the custom image component.
 * @returns the custom image component
 */
const CustomImageComponent = (props: CustomImageProps): React.ReactElement => {
    const { cellnex, predefined } = props;
    /*** HOOKS ***/

    /*** COMPONENTS ***/

    if (configuration.getTenant() === 'cellnex') {
        return <img src={cellnex} alt="" />;
    } else {
        return <img src={predefined} alt="" />;
    }
};

interface CustomImageProps {
    cellnex: any;
    predefined: any;
}
