import { Col, DatePicker, Form, Input, Modal, Row, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import scheduleApi from '../../../../../../api/ScheduleApi';
import { Connection, Schedule } from '../../../../../../model/entities';
import { frequencies } from '../../../../../../model/types';
import notificationService from '../../../../../../services/NotificationService';

/**
 * Returns the connection schedule modal.
 * @props the props
 * @returns the connection schedule modal
 */
const ConnectionScheduleModal = (props: Props): React.ReactElement => {
    const { visible, connection, onCancel, onSave, scheduleId } = props;
    /*** HOOKS ***/

    const [form] = Form.useForm();
    const intl = useIntl();
    const [schedule, setSchedule] = useState<Schedule>();
    const [loading, setLoading] = useState<'loading' | 'saving'>();

    /*** EFFECTS ***/

    useEffect(() => {
        const init = async () => {
            try {
                setLoading('loading');
                if (scheduleId) {
                    // get schedule
                    const schedule = await scheduleApi.get(scheduleId);
                    setSchedule(schedule);

                    // load form values
                    form.setFieldsValue(schedule);
                } else {
                    const schedule: Schedule = { connectionId: connection.id };
                    setSchedule(schedule);

                    // load form values
                    form.setFieldsValue(schedule);
                }
            } catch (error) {
                notificationService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [connection.id, form, intl, scheduleId]);

    /*** METHODS ***/

    const cancel = () => {
        form.resetFields();
        onCancel();
    };

    const submit = async () => {
        try {
            const values = await form.validateFields();
            await save(values);
        } catch (error) {}
    };

    const save = async (values: any) => {
        try {
            setLoading('saving');
            const scheduleUpdated = Object.assign({}, schedule, values);
            scheduleUpdated.id ? await scheduleApi.update(scheduleUpdated) : await scheduleApi.create(scheduleUpdated);
            form.resetFields();
            onSave();
        } catch (error) {
            notificationService.displayError(error, intl, [{ status: 409, message: 'schedule.status.duplicate' }]);
        } finally {
            setLoading(undefined);
        }
    };

    /*** COMPONENTS ***/

    const frequencyOptions = frequencies.map((frequency) => (
        <Select.Option key={frequency} value={frequency}>
            <FormattedMessage id={frequency} />
        </Select.Option>
    ));

    return (
        <Modal
            title={<FormattedMessage id="schedule.modal.title" />}
            open={visible}
            onCancel={cancel}
            onOk={submit}
            okButtonProps={{ loading: loading === 'loading' }}
            cancelButtonProps={{ disabled: loading === 'saving' }}
        >
            <Form form={form} colon={false} layout="vertical">
                <Row gutter={[28, 0]}>
                    <Col span={24}>
                        <Form.Item
                            label={<FormattedMessage id="schedule.name" />}
                            name="name"
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <Input maxLength={200} size="large" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[28, 0]}>
                    <Col span={12}>
                        <Form.Item
                            label={<FormattedMessage id="schedule.started" />}
                            name="started"
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <DatePicker size="large" showTime={{ format: 'HH:mm' }} format="DD/MM/YYYY HH:mm" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label={<FormattedMessage id="schedule.frequency" />} name="frequency">
                            <Select size="large" allowClear>
                                {frequencyOptions}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default ConnectionScheduleModal;

interface Props {
    visible?: boolean;
    connection: Connection;
    scheduleId?: number;
    onSave: () => Promise<void>;
    onCancel: () => void;
}
