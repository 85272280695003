import * as CryptoJS from 'crypto-js';
import Cookies from 'js-cookie';
import jwt_decode from 'jwt-decode';
import procoreApi from '../api/ProcoreApi';
import configuration from '../configs/IConfiguration';
import { ProcoreOauthResponse } from '../model/entities';
import mioAdminSettingService from './MioAdminSettingService';

class ProcoreService {
    private clientId: string | undefined;
    private readonly urlStart = window.location.protocol + '//' + window.location.host;
    private readonly redirectUrl = `${this.urlStart}/procore/oauth`;
    private readonly procoreClientIdText = 'procoreClientId';
    private readonly procoreStateCode = 'procoreStateCode';
    private readonly procoreAccessTokenText = 'procoreAccessToken';
    private readonly procoreAccessRefreshTokenText = 'procoreAccessRefreshToken';
    private readonly procoreWindow = 'procoreWindow';

    /**
     * Initializes the procore access token.
     */
    init = async (): Promise<string | undefined> => {
        let accessToken: string | undefined;
        try {
            // initialize the procore client id by organization
            this.initClientId();

            // get procore authorization code (only when it comes from procore authorization redirection) and the state code
            const authorizationCode = await this.getAuthorizationCode();
            const stateCode = await this.getStateCode();
            accessToken = this.getCachedAccessToken();

            // if authorization code is received, retrieve access and refresh tokens from procore (store in cookies)
            if (authorizationCode && stateCode === localStorage.getItem(this.procoreStateCode)) {
                accessToken = await this.loadAccessTokenFromAuthorizationCode(authorizationCode);
                localStorage.removeItem(this.procoreStateCode);
            }

            // if access token exists but it's not valid, try to refresh it
            if (accessToken && !this.isAccessTokenValid(accessToken)) {
                accessToken = await this.refreshAccessToken();
            }
            // otherwise the access token is valid

            // close procore login popup if is opened
            if (localStorage.getItem(this.procoreWindow)) {
                window.close();
                localStorage.removeItem(this.procoreWindow);
            }
        } catch (error: any) {
            this.removeAccessToken();
            this.removeRefreshAccessToken();
            console.log(error);
        }

        return accessToken;
    };

    /**
     * Returns the procore access token (from cache or via refresh access token)
     * @returns procore access token
     */
    getAccessToken = async (): Promise<string | undefined> => {
        let accessToken: string | undefined;
        try {
            accessToken = this.getCachedAccessToken();

            if (accessToken && !this.isAccessTokenValid(accessToken)) {
                accessToken = await this.refreshAccessToken();
            }
        } catch (error: any) {
            this.removeAccessToken();
            this.removeRefreshAccessToken();
            console.log(error);
        }

        return accessToken;
    };

    /**
     * Returns the procore access token (from cache or via refresh access token, otherwise redirects to login)
     * @returns procore access token
     */
    getAccessTokenOrLogin = async (): Promise<string | undefined> => {
        let accessToken: string | undefined;
        try {
            accessToken = this.getCachedAccessToken();

            if (accessToken && !this.isAccessTokenValid(accessToken)) {
                accessToken = await this.refreshAccessToken();
            }
            // if access token doesn't exist redirect to procore authorization page
            else if (!accessToken) {
                this.login();
            }
            // otherwise the access token is valid
        } catch (error: any) {
            accessToken = undefined;
            this.removeAccessToken();
            this.removeRefreshAccessToken();
            throw error;
        }

        return accessToken;
    };

    logOut = () => {
        this.removeClientId();
        this.removeAccessToken();
        this.removeRefreshAccessToken();
        localStorage.removeItem(this.procoreStateCode);
        localStorage.removeItem(this.procoreWindow);
    };

    /**
     * Return if user is connected to Procore
     * @returns a boolean to know if the user is or not connected
     */
    isConnected = (): boolean => {
        const procoreAccessToken = this.getCachedAccessToken();
        return !!procoreAccessToken && this.isAccessTokenValid(procoreAccessToken);
    };

    /**
     * Returns the procore client id from the cookie
     * @returns the procore client id from the cookie
     */
    getCachedClientId = (): string | undefined => {
        return Cookies.get(this.getCookieFullname(this.procoreClientIdText)) || undefined;
    };

    /**
     * Returns the procore access token.
     * @returns the procore access token
     */
    getCachedAccessToken = (): string | undefined => {
        return Cookies.get(this.getCookieFullname(this.procoreAccessTokenText)) || undefined;
    };

    /**
     * Returns the procore refresh access token.
     * @returns the procore refresh access token
     */
    getCachedRefreshAccessToken = (): string | undefined => {
        return Cookies.get(this.getCookieFullname(this.procoreAccessRefreshTokenText)) || undefined;
    };

    /**
     * Returns a cookie full name with the domain string of the current configuration
     * @param name cookie name initial text
     * @returns the cookie full name
     */
    private getCookieFullname = (name: string): string => {
        return name + configuration.getCookiesSubfix();
    };

    /**
     * Initializes the procore client id (property, cookie) using mio admin settings or the default one.
     * @returns the procore client id
     */
    private initClientId = (): string | undefined => {
        const procoreSetting = mioAdminSettingService.getProcoreSetting();

        // load client id
        if (procoreSetting) {
            const clientId = procoreSetting.clientId;

            this.clientId = clientId;

            // if new orgnization, reset client id and tokens
            if (this.clientId !== this.getCachedClientId()) {
                this.logOut();
                this.storeClientId(clientId);
            }

            return clientId;
        }
    };

    private login = async () => {
        this.removeAccessToken();
        this.removeRefreshAccessToken();

        // redirect to procore authorization page
        const stateCode = CryptoJS.lib.WordArray.random(34).toString();
        const procoreSetting = mioAdminSettingService.getProcoreSetting();
        if (procoreSetting) {
            // we generate the url for procore authentication
            const url = new URL(`${configuration.getProcoreUrl()}/oauth/authorize`);
            url.searchParams.set('client_id', procoreSetting.clientId);
            url.searchParams.set('response_type', 'code');
            url.searchParams.set('redirect_uri', this.redirectUrl);
            url.searchParams.set('state', stateCode);

            // store code verifier in local storage
            localStorage.setItem(this.procoreStateCode, stateCode);

            // we create the procore login popup
            const width = 600,
                height = 700;
            const left = (window.innerWidth - width) / 2;
            const top = (window.innerHeight - height) / 2;

            localStorage.setItem(this.procoreWindow, 'Procore Login');

            window.open(url, 'Procore Login', `width=${width},height=${height},top=${top},left=${left}`);
        }
    };

    private getAuthorizationCode = async () => {
        let code: string | undefined;
        try {
            const urlObj = new URL(window.location.href);
            if (window.location.pathname === '/procore/oauth' && urlObj.searchParams.has('code') && this.clientId) {
                const urlParams = new URLSearchParams(window.location.search);
                code = urlParams.get('code') as string;
            }
        } catch (error) {
            console.log(error);
        }

        return code;
    };

    private getStateCode = async () => {
        let state: string | undefined;
        try {
            const urlObj = new URL(window.location.href);
            if (window.location.pathname === '/procore/oauth' && urlObj.searchParams.has('state') && this.clientId) {
                const urlParams = new URLSearchParams(window.location.search);
                state = urlParams.get('state') as string;
            }
        } catch (error) {
            console.log(error);
        }

        return state;
    };

    private loadAccessTokenFromAuthorizationCode = async (authorizationCode: string): Promise<string | undefined> => {
        let accessToken: string | undefined;
        try {
            if (authorizationCode && this.clientId) {
                const procoreOauthResponse = await procoreApi.getToken(authorizationCode);
                accessToken = procoreOauthResponse.access_token;

                // store procore access and refresh tokens
                this.storeAccessToken(procoreOauthResponse);
                this.storeRefreshAccessToken(procoreOauthResponse);

                // remove state from local storage
                localStorage.removeItem(this.procoreStateCode);
            }
        } catch (error) {
            console.log(error);
        }

        return accessToken;
    };

    isAccessTokenValid = (accessToken: string | undefined): boolean => {
        let isValid: boolean = false;
        if (accessToken) {
            const tokenDecoded: any = jwt_decode(accessToken);
            const currentTimeInSeconds = Math.floor(Date.now() / 1000);
            isValid = currentTimeInSeconds < tokenDecoded.exp - 300;
        }

        return isValid;
    };

    private refreshAccessToken = async (): Promise<string | undefined> => {
        let accessToken: string | undefined;

        if (this.clientId) {
            const refreshToken = this.getCachedRefreshAccessToken();
            const procoreOauthResponse = await procoreApi.refreshToken(refreshToken!);

            // store access and refresh tokens in cookies
            this.storeAccessToken(procoreOauthResponse);
            this.storeRefreshAccessToken(procoreOauthResponse);

            accessToken = procoreOauthResponse.access_token;
        }

        return accessToken;
    };

    /**
     * Stores the procore client id in a cookie.
     * @param procoreClientId the procore client id
     */
    private storeClientId = (procoreClientId: string) => {
        Cookies.set(this.getCookieFullname(this.procoreClientIdText), procoreClientId, {
            domain: configuration.getParentDomain(),
            secure: window.location.protocol === 'https:',
        });
    };

    /**
     * Stores the access token in a cookie.
     * @param procoreOauthResponse the procore oauth response
     */
    private storeAccessToken = (procoreOauthResponse: ProcoreOauthResponse) => {
        Cookies.set(this.getCookieFullname(this.procoreAccessTokenText), procoreOauthResponse.access_token, {
            domain: configuration.getParentDomain(),
            secure: window.location.protocol === 'https:',
        });
    };

    /**
     * Stores the refresh token in a cookie.
     * @param procoreOauthResponse the procore oauth response
     */
    private storeRefreshAccessToken = (procoreOauthResponse: ProcoreOauthResponse) => {
        Cookies.set(this.getCookieFullname(this.procoreAccessRefreshTokenText), procoreOauthResponse.refresh_token, {
            domain: configuration.getParentDomain(),
            secure: window.location.protocol === 'https:',
        });
    };

    /**
     * Removes the client id cookie.
     */
    private removeClientId = () => {
        Cookies.remove(this.getCookieFullname(this.procoreClientIdText), {
            domain: configuration.getParentDomain(),
            secure: window.location.protocol === 'https:',
        });
    };

    /**
     * Removes the access token cookie.
     */
    private removeAccessToken = () => {
        Cookies.remove(this.getCookieFullname(this.procoreAccessTokenText), {
            domain: configuration.getParentDomain(),
            secure: window.location.protocol === 'https:',
        });
    };

    /**
     * Removes the refresh token cookie.
     */
    private removeRefreshAccessToken = () => {
        Cookies.remove(this.getCookieFullname(this.procoreAccessRefreshTokenText), {
            domain: configuration.getParentDomain(),
            secure: window.location.protocol === 'https:',
        });
    };
}

const procoreService: ProcoreService = new ProcoreService();
export default procoreService;
