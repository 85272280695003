import React, { useState } from 'react';
import styles from './UpdateCustomAttributesModal.module.scss';
import { Form, Modal, Input, DatePicker, Select, Checkbox, Switch, Tooltip } from 'antd';
import { FormattedMessage } from 'react-intl';
import { CustomAttribute, CustomAttributeDefinition } from '../../../../../model/entities';

/**
 * This component represents an update company modal.
 * @param props - the props
 */
export const UpdateCustomAttributesModal: React.FC<Props> = (props) => {
    const [form] = Form.useForm();
    const [saving, setSaving] = useState(false);
    const { customAttributeDefinitions } = props;
    const customAttributeDefinitionMap = createCustomAttributeDefinitionMap(customAttributeDefinitions);

    const cancel = async () => {
        form.resetFields();
        props.onCancel();
    };

    const update = async () => {
        try {
            setSaving(true);
            const values = await form.validateFields();
            const enabledValues = Object.entries(values)
                .filter((v) => !v[0].endsWith('_enabled'))
                .filter((v) => values[v[0] + '_enabled']);
            const enabledKeys = enabledValues.map((v) => v[0]);
            const customAttributes: CustomAttribute[] = Array.from(customAttributeDefinitionMap.entries())
                .filter((entry) => enabledKeys.includes(entry[0]))
                .map((entry) => entry[1])
                .flat()
                .map((cad) => ({
                    id: cad.id,
                    name: cad.name,
                    type: cad.type,
                    value: enabledValues.find((v) => v[0] === createcustomAttributeDefinitionKey(cad))![1] as string,
                    projectId: cad.projectId,
                }));
            await props.onUpdate(customAttributes);
            form.resetFields();
        } finally {
            setSaving(false);
        }
    };

    return (
        <Modal
            title={<FormattedMessage id="files.updateCustomAttributes.modal.title" />}
            open={props.visible}
            onCancel={cancel}
            onOk={update}
            okButtonProps={{ loading: saving, disabled: customAttributeDefinitions.length === 0 }}
            cancelButtonProps={{ disabled: saving }}
        >
            <Form form={form} colon={false} layout="vertical">
                {Array.from(customAttributeDefinitionMap.entries()).map((entry) => {
                    const id = entry[0];
                    const value = entry[1][0];
                    <Form.Item label="Delete">
                        <Checkbox />
                    </Form.Item>;
                    if (value.type === 'string') {
                        return (
                            <Form.Item
                                key={id}
                                label={
                                    <Tooltip
                                        title={
                                            <FormattedMessage id="files.updateCustomAttributes.modal.field.update" />
                                        }
                                        placement="right"
                                    >
                                        {value.name}
                                    </Tooltip>
                                }
                            >
                                <Input.Group className={styles.attribute}>
                                    <Form.Item
                                        name={id + '_enabled'}
                                        valuePropName="checked"
                                        initialValue={false}
                                        noStyle
                                    >
                                        <Switch />
                                    </Form.Item>

                                    <Form.Item name={id} noStyle>
                                        <Input size="large" maxLength={256} className={styles.input} />
                                    </Form.Item>
                                </Input.Group>
                            </Form.Item>
                        );
                    } else if (value.type === 'date') {
                        return (
                            <Form.Item
                                key={id}
                                label={
                                    <Tooltip
                                        title={
                                            <FormattedMessage id="files.updateCustomAttributes.modal.field.update" />
                                        }
                                        placement="right"
                                    >
                                        {value.name}
                                    </Tooltip>
                                }
                            >
                                <Input.Group className={styles.attribute}>
                                    <Form.Item
                                        name={id + '_enabled'}
                                        valuePropName="checked"
                                        initialValue={false}
                                        noStyle
                                    >
                                        <Switch />
                                    </Form.Item>

                                    <Form.Item name={id} noStyle>
                                        <DatePicker size="large" format="DD/MM/YYYY" className={styles.date} />
                                    </Form.Item>
                                </Input.Group>
                            </Form.Item>
                        );
                    } else if (value.type === 'array') {
                        return (
                            <Form.Item
                                key={id}
                                label={
                                    <Tooltip
                                        title={
                                            <FormattedMessage id="files.updateCustomAttributes.modal.field.update" />
                                        }
                                        placement="right"
                                    >
                                        {value.name}
                                    </Tooltip>
                                }
                            >
                                <Input.Group className={styles.attribute}>
                                    <Form.Item
                                        name={id + '_enabled'}
                                        valuePropName="checked"
                                        initialValue={false}
                                        noStyle
                                    >
                                        <Switch />
                                    </Form.Item>

                                    <Form.Item name={id} noStyle>
                                        <Select size="large" className={styles.select}>
                                            {value.options.map((o) => (
                                                <Select.Option key={o} value={o}>
                                                    {o}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Input.Group>
                            </Form.Item>
                        );
                    } else {
                        return <></>;
                    }
                })}
            </Form>
        </Modal>
    );
};
export default UpdateCustomAttributesModal;

interface Props {
    visible?: boolean;
    onUpdate: (values: any) => Promise<void>;
    onCancel: () => void;
    customAttributeDefinitions: CustomAttributeDefinition[];
    mandatory?: boolean;
}

const createcustomAttributeDefinitionKey = (customAttributeDefinition: CustomAttributeDefinition): string =>
    `${customAttributeDefinition.name}|||${customAttributeDefinition.type}|||${
        customAttributeDefinition.options ? customAttributeDefinition.options.join(',') : ''
    }`;

const createCustomAttributeDefinitionMap = (
    customAttributeDefinitions: CustomAttributeDefinition[],
): Map<string, CustomAttributeDefinition[]> => {
    const customAttributeDefinitionMap: Map<string, CustomAttributeDefinition[]> = new Map();
    for (const customAttributeDefinition of customAttributeDefinitions) {
        const key = createcustomAttributeDefinitionKey(customAttributeDefinition);
        if (!customAttributeDefinitionMap.has(key)) {
            customAttributeDefinitionMap.set(key, []);
        }
        customAttributeDefinitionMap.get(key)?.push(customAttributeDefinition);
    }

    return customAttributeDefinitionMap;
};
