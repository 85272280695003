import { IConfiguration, Platform, Tenant } from './IConfiguration';

export default class LocalConfiguration implements IConfiguration {
    // domains of environments (hardcoded - react doesn't allow changes on runtime)
    readonly awsDevDomain = 'link-dev.modelical.io';
    readonly awsPreDomain = 'link-pre.modelical.io';
    readonly awsProductionDomain = 'link.modelical.io';

    // configuration attributes
    auth: any | undefined;
    parentUrl: string | undefined;
    adminMicroserviceUrl: string;
    proxyMicroserviceUrl: string;
    syncMicroserviceUrl: string;
    mioAdminUrl: string;
    autodeskClientId: string;
    parentDomain: string;
    cookiesSubfix: string;
    procoreUrl: string;

    constructor() {
        // set up configuration attributes based on environment
        this.auth = {
            auth: {
                clientId: '7819f092-5634-499f-90c0-cf5ff111f1c3', // This is the ONLY mandatory field that you need to supply.
                authority: 'https://login.microsoftonline.com/d8c21a0f-8e79-48d2-9314-075f557b317d', // Full directory URL, in the form of https://login.microsoftonline.com/<tenant-id>
                redirectUri: 'http://localhost:3000', // Points to window.location.origin. You must register this URI on Azure Portal/App Registration.
                postLogoutRedirectUri: 'http://localhost:3000', // Indicates the page to navigate after logout.
                navigateToLoginRequestUrl: true, // If "true", will navigate back to the original request location before processing the auth code response.
                OIDCOptions: {
                    defaultScopes: ['7819f092-5634-499f-90c0-cf5ff111f1c3/.default'],
                },
            },
            cache: {
                cacheLocation: 'localStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
                storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
            },
        };

        this.parentUrl = 'https://mio-web.gentlerock-f486492f.swedencentral.azurecontainerapps.io';
        this.adminMicroserviceUrl = `http://localhost:8010`;
        this.proxyMicroserviceUrl = `http://localhost:8020`;
        this.syncMicroserviceUrl = `http://localhost:8030`;
        this.mioAdminUrl = 'http://localhost:7010';
        this.autodeskClientId = 'FCtjTCLAVpp8GJ8Mfp5q4Nu6fE3iGPn3zV9kWxm7yJxGsd5H';
        this.parentDomain = 'localhost';
        this.cookiesSubfix = 'Local';
        this.procoreUrl = 'https://login-sandbox.procore.com';
    }

    getPlatform = (): Platform => {
        return 'azure';
    };

    getAuth = (): any | undefined => {
        return this.auth;
    };

    getParentUrl = (): string | undefined => {
        return this.parentUrl;
    };

    getAdminMicroserviceUrl = (): string => {
        return this.adminMicroserviceUrl;
    };

    getProxyMicroserviceUrl = (): string => {
        return this.proxyMicroserviceUrl;
    };

    getSyncMicroserviceUrl = (): string => {
        return this.syncMicroserviceUrl;
    };

    getMioAdminUrl = (): string => {
        return this.mioAdminUrl;
    };

    getAutodeskClientId = (): string => {
        return this.autodeskClientId;
    };

    getTenant = (): Tenant => {
        return 'modelical';
    };

    getParentDomain = (): string => {
        return this.parentDomain;
    };

    getCookiesSubfix = (): string => {
        return this.cookiesSubfix;
    };

    getProcoreUrl = (): string => {
        return this.procoreUrl;
    };
}
