import Icon, { FileExcelOutlined } from '@ant-design/icons';
import { Button, Divider, Dropdown, message, Modal, Select, Space, Tag, Tooltip } from 'antd';
import Search from 'antd/lib/input/Search';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import { Key, TableRowSelection } from 'antd/lib/table/interface';
import React, { useContext, useEffect, useState } from 'react';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import companyApi from '../../../api/CompanyApi';
import hubApi from '../../../api/HubApi';
import memberApi from '../../../api/MemberApi';
import LayoutComponent from '../../../components/LayoutComponent/LayoutComponent';
import SelectTableComponent from '../../../components/SelectTableComponent/SelectTableComponent';
import CustomContext from '../../../context/CustomContext';
import { Page } from '../../../model/elements';
import { Company, Hub, Member } from '../../../model/entities';
import { Bim360Status, MemberType } from '../../../model/types';
import { ReactComponent as CaretDownSvg } from '../../../resources/images/caret-down.svg';
import { ReactComponent as CheckmarkSvg } from '../../../resources/images/checkmark.svg';
import { ReactComponent as CloudUploadSvg } from '../../../resources/images/cloud-upload.svg';
import { ReactComponent as EditSvg } from '../../../resources/images/edit.svg';
import { ReactComponent as InformationSvg } from '../../../resources/images/information.svg';
import { ReactComponent as TrashCanSvg } from '../../../resources/images/trash-can.svg';
import { ReactComponent as WarningSvg } from '../../../resources/images/warning.svg';
import notificationService from '../../../services/NotificationService';
import tableService from '../../../services/TableService';
import UpdateCompanyModal from '../ProjectMembersPage/UpdateCompanyModal/UpdateCompanyModal';
import styles from './MembersPage.module.scss';
import rolesService from '../../../services/RolesService';

/**
 * Returns the members page.
 * @returns the members page.
 */
const MembersPage = (): React.ReactElement => {
    /*** HOOKS ***/

    const intl = useIntl();
    const context = useContext(CustomContext);
    const { auth } = context;
    const [membersPage, setMembersPage] = useState<Page<Member>>();
    const [searchText, setSearchText] = useState<string>();
    const [loading, setLoading] = useState<boolean>();
    const [exporting, setExporting] = useState<boolean>();
    const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
    const [selectedMembers, setSelectedMembers] = useState<Member[]>([]);
    const [companyModalVisible, setCompanyModalVisible] = useState<boolean>();
    const [companies, setCompanies] = useState<Company[]>([]);
    const [hubs, setHubs] = useState<Hub[]>([]);
    const [selectedHubId, setSelectedHubId] = useState<string>();

    /*** EFFECTS ***/

    useEffect(() => {
        const init = async () => {
            try {
                setLoading(true);

                let hubs = await hubApi.list();
                hubs = hubs.filter((h) => auth && auth.members.some((m) => m.hubId === h.id && m.bim360 === 'ADMIN'));
                const selectedHubId = hubs.find(Boolean)?.id;

                const responses = await Promise.all([
                    memberApi.list(0, tableService.pageSize, 'lastName', true, selectedHubId!),
                    companyApi.list(selectedHubId!),
                ]);
                const membersPage = responses[0];
                membersPage.totalElements = membersPage.totalElements + 1;
                const companies = responses[1];

                setMembersPage(membersPage);
                setCompanies(companies);
                setHubs(hubs);
                setSelectedHubId(selectedHubId);
            } catch (error) {
                notificationService.displayError(error, intl);
            } finally {
                setLoading(false);
            }
        };
        init();
    }, [intl, auth]);

    /*** METHODS ***/

    const list = async () => {
        try {
            setLoading(true);
            const page = 0;
            const size = membersPage!.size;
            const sortField = membersPage!.sort.field!;
            const sortOrder = membersPage!.sort.order!;
            await listMembers(page, size, sortField, sortOrder, selectedHubId!, searchText);
        } catch (error) {
            notificationService.displayError(error, intl);
        } finally {
            setLoading(false);
        }
    };

    const search = async (searchText: string) => {
        try {
            setLoading(true);
            setSearchText(searchText);

            const page = 0;
            const size = membersPage!.size;
            const sortField = membersPage!.sort.field!;
            const sortOrder = membersPage!.sort.order!;
            await listMembers(page, size, sortField, sortOrder, selectedHubId!, searchText);
        } catch (error) {
            notificationService.displayError(error, intl);
        } finally {
            setLoading(false);
        }
    };

    const selectHub = async (selectedHubId: string) => {
        try {
            setLoading(true);
            setSelectedHubId(selectedHubId);

            // unselect all
            unselectAll();

            // list members
            const page = 0;
            const size = membersPage!.size;
            const sortField = membersPage!.sort.field!;
            const sortOrder = membersPage!.sort.order!;
            await listMembers(page, size, sortField, sortOrder, selectedHubId, searchText);

            // list companies
            const companies = await companyApi.list(selectedHubId!);
            setCompanies(companies);
        } catch (error) {
            notificationService.displayError(error, intl);
        } finally {
            setLoading(false);
        }
    };

    const paginate = async (pagination: TablePaginationConfig, filters: any, sorter: any) => {
        try {
            setLoading(true);
            const page = pagination.current! - 1;
            const size = pagination.pageSize!;
            const sortField = sorter.field;
            const sortOrder = sorter.order === 'ascend';
            await listMembers(page, size, sortField, sortOrder, selectedHubId!, searchText);
        } catch (error) {
            notificationService.displayError(error, intl);
        } finally {
            setLoading(false);
        }
    };

    const listMembers = async (
        page: number,
        size: number,
        sortField: string,
        sortOrder: boolean,
        hubId: string,
        searchText?: string,
    ) => {
        const newMembersPage = await memberApi.list(page, size, sortField, sortOrder, hubId, searchText);
        if (page > 0 && newMembersPage && membersPage && newMembersPage.numberOfElements === 0) {
            const currentMembersPage = Object.assign({}, membersPage, { totalElements: membersPage.totalElements - 1 });
            setMembersPage(currentMembersPage);
        } else {
            newMembersPage.totalElements = newMembersPage.totalElements + 1;
            setMembersPage(newMembersPage);
        }
    };

    const exportSpreadSheet = async () => {
        try {
            setExporting(true);
            const page = membersPage!.number;
            const size = membersPage!.size;
            const sortField = membersPage!.sort.field!;
            const sortOrder = membersPage!.sort.order!;
            await memberApi.exportSpreadSheet(page, size, sortField, sortOrder, selectedHubId!, searchText);
        } catch (error) {
            notificationService.displayError(error, intl);
        } finally {
            setExporting(false);
        }
    };

    /**
     * Shows the export spreesheet  modal.
     */
    const showExportSpreadSheetModal = () => {
        Modal.confirm({
            title: intl.formatMessage({ id: 'members.exportSpreadSheet.modal.title' }),
            content: <p>{intl.formatMessage({ id: 'members.exportSpreadSheet.modal.description' })}</p>,
            icon: <Icon component={InformationSvg} />,
            okText: intl.formatMessage({ id: 'button.yes' }),
            okType: 'primary',
            cancelText: intl.formatMessage({ id: 'button.no' }),
            onOk: exportSpreadSheet,
        });
    };

    const showInactivateModal = () => {
        Modal.confirm({
            title: intl.formatMessage({ id: 'members.inactivate.modal.title' }),
            icon: <Icon component={WarningSvg} />,
            okText: intl.formatMessage({ id: 'button.yes' }),
            okType: 'danger',
            cancelText: intl.formatMessage({ id: 'button.no' }),
            onOk: () => inactivate(),
        });
    };

    const showActivateModal = () => {
        Modal.confirm({
            title: intl.formatMessage({ id: 'members.activate.modal.title' }),
            icon: <Icon component={InformationSvg} />,
            okText: intl.formatMessage({ id: 'button.yes' }),
            okType: 'primary',
            cancelText: intl.formatMessage({ id: 'button.no' }),
            onOk: () => activate(),
        });
    };

    const showCompanyModal = (visible: boolean) => {
        const companyModalVisible = visible;
        setCompanyModalVisible(companyModalVisible);
    };

    const activate = async () => {
        updateStatus(true);
    };

    const inactivate = async () => {
        updateStatus(false);
    };

    const updateCompany = async (values: any) => {
        try {
            let selectedCompany = companies.find((c) => c.id === values.company);
            const members = [...selectedMembers];
            members.forEach((c) => (c.company = selectedCompany));
            const membersWithStatus = await memberApi.updateCompany(members, selectedHubId!);
            unselectAll();
            await list();
            showCompanyModal(false);

            // display message
            if (membersWithStatus.every((m) => m.status.type === 'OK')) {
                message.success(intl.formatMessage({ id: 'status.saved' }));
            } else {
                message.warning(intl.formatMessage({ id: 'status.savedWithErrors' }), 5);
            }
        } catch (error) {
            notificationService.displayError(error, intl);
        }
    };

    const updateStatus = async (checked: boolean) => {
        try {
            setLoading(true);
            const members = selectedMembers.map((m) => {
                m.bim360Status = checked ? 'active' : 'inactive';
                return m;
            });
            const membersWithStatus = await memberApi.updateStatus(members, selectedHubId!);
            unselectAll();
            await list();

            // display message
            if (membersWithStatus.every((m) => m.status.type === 'OK')) {
                checked
                    ? message.success(intl.formatMessage({ id: 'members.activate.status' }))
                    : message.success(intl.formatMessage({ id: 'members.inactivate.status' }));
            } else {
                message.warning(intl.formatMessage({ id: 'status.savedWithErrors' }), 5);
            }
        } catch (error) {
            notificationService.displayError(error, intl);
        } finally {
            setLoading(false);
        }
    };

    const select = (selectedRowKeys: Key[], selectedRows: Member[]) => {
        setSelectedRowKeys(selectedRowKeys);
        setSelectedMembers(selectedRows);
    };

    const unselectAll = () => {
        setSelectedRowKeys([]);
        setSelectedMembers([]);
    };

    const isUpdateCompaniesButtonDisabled = (): boolean => {
        return selectedMembers.length === 0;
    };

    const isInactiveButtonDisabled = (): boolean => {
        return (
            selectedMembers.length === 0 ||
            selectedMembers.filter((m) => m.bim360Status !== 'active').length > 0 ||
            selectedMembers.filter((m) => m.email === auth!.email).length > 0
        );
    };

    const isActiveButtonDisabled = (): boolean => {
        return selectedMembers.length === 0 || selectedMembers.filter((m) => m.bim360Status !== 'inactive').length > 0;
    };

    /*** COMPONENTS ***/

    const placeholder: string = intl.formatMessage({ id: 'members.search' });

    const hubOptions = hubs.map((hub) => (
        <Select.Option key={hub.id} value={hub.id}>
            {`${hub.provider} > ${hub.name}`}
        </Select.Option>
    ));

    const items = membersPage ? membersPage.content : [];
    const pagination = tableService.createPagination(membersPage);
    pagination.simple = true;
    pagination.className = styles.pagination;
    const columns: ColumnsType<Member> = [
        {
            title: <FormattedMessage id="member.lastName" />,
            dataIndex: 'lastName',
            key: 'lastName',
            sorter: true,
            defaultSortOrder: 'ascend',
        },
        {
            title: <FormattedMessage id="member.firstName" />,
            dataIndex: 'firstName',
            key: 'firstName',
            sorter: true,
        },
        {
            title: <FormattedMessage id="member.email" />,
            dataIndex: 'email',
            key: 'email',
            sorter: true,
        },
        {
            title: <FormattedMessage id="member.company" />,
            dataIndex: 'company',
            key: 'company',
            render: (value: Company) => value?.name,
        },
        {
            title: <FormattedMessage id="member.bim360" />,
            dataIndex: 'bim360',
            key: 'bim360',
            sorter: true,
            width: 105,
            align: 'center',
            render: (value: MemberType) => {
                return <FormattedMessage id={value} />;
            },
        },
        {
            title: <FormattedMessage id="member.bim360CreatedAt" />,
            dataIndex: 'bim360CreatedAt',
            key: 'bim360CreatedAt',
            sorter: true,
            width: 120,
            align: 'center',
            render: (value: MemberType) => {
                return <FormattedDate value={value} day="2-digit" month="2-digit" year="numeric" />;
            },
        },
        {
            title: <FormattedMessage id="member.bim360LastSignIn" />,
            dataIndex: 'bim360LastSignIn',
            key: 'bim360LastSignIn',
            sorter: true,
            width: 160,
            align: 'center',
            render: (value: MemberType) => {
                return value == null ? null : (
                    <FormattedDate
                        value={value}
                        day="2-digit"
                        month="2-digit"
                        year="numeric"
                        hour="2-digit"
                        minute="2-digit"
                    />
                );
            },
        },
        {
            title: <FormattedMessage id="member.bim360Status" />,
            dataIndex: 'bim360Status',
            key: 'bim360Status',
            sorter: true,
            width: 120,
            align: 'center',
            render: (value: Bim360Status) => {
                const color = value === 'active' ? 'blue' : value === 'inactive' ? 'red' : 'orange';
                return (
                    <Tag color={color}>
                        <FormattedMessage id={value} />
                    </Tag>
                );
            },
        },
    ];
    const rowSelection: TableRowSelection<Member> = {
        preserveSelectedRowKeys: true,
        selectedRowKeys,
        onChange: select,
    };

    return (
        <LayoutComponent pageId="members">
            <div className={styles.selector}>
                <label>
                    <FormattedMessage id="projectMembers.hub" />
                </label>
                <Select
                    size="large"
                    className={styles.hubs}
                    loading={loading}
                    value={selectedHubId}
                    onChange={selectHub}
                    placeholder={<FormattedMessage id="projectMembers.hub.placeholder" />}
                >
                    {hubOptions}
                </Select>
                <Divider />
            </div>
            <div className="toolbar">
                <Search placeholder={placeholder} onSearch={search} size="large" className="search" />
                <Space>
                    <Tooltip title={<FormattedMessage id="members.exportSpreadSheet" />}>
                        <Button
                            size="large"
                            icon={<FileExcelOutlined />}
                            onClick={showExportSpreadSheetModal}
                            loading={exporting}
                        ></Button>
                    </Tooltip>
                    <Link to={`/setup/members/import?hubId=${selectedHubId}`}>
                        <Tooltip title={<FormattedMessage id="members.import" />}>
                            <Button size="large" icon={<Icon component={CloudUploadSvg} />}></Button>
                        </Tooltip>
                    </Link>
                    <Dropdown
                        trigger={['click']}
                        placement="bottomRight"
                        menu={{
                            items: !rolesService.hasAnyRole(auth, ['ROLE_ADMIN'])
                                ? [
                                      {
                                          key: 'company',
                                          icon: <Icon component={EditSvg} />,
                                          onClick: () => showCompanyModal(true),
                                          disabled: isUpdateCompaniesButtonDisabled(),
                                          label: <FormattedMessage id="projectMembers.updateCompany" />,
                                      },
                                      {
                                          key: 'activate',
                                          icon: <Icon component={CheckmarkSvg} />,
                                          onClick: showActivateModal,
                                          disabled: isActiveButtonDisabled(),
                                          label: <FormattedMessage id="button.activate" />,
                                      },
                                      {
                                          key: 'inactivate',
                                          icon: <Icon component={TrashCanSvg} />,
                                          onClick: showInactivateModal,
                                          disabled: isInactiveButtonDisabled(),
                                          label: <FormattedMessage id="members.inactivate" />,
                                      },
                                  ]
                                : [
                                      {
                                          key: 'company',
                                          icon: <Icon component={EditSvg} />,
                                          onClick: () => showCompanyModal(true),
                                          disabled: isUpdateCompaniesButtonDisabled(),
                                          label: <FormattedMessage id="projectMembers.updateCompany" />,
                                      },
                                      {
                                          key: 'activate',
                                          icon: <Icon component={CheckmarkSvg} />,
                                          onClick: showActivateModal,
                                          disabled: isActiveButtonDisabled(),
                                          label: <FormattedMessage id="button.activate" />,
                                      },
                                      {
                                          key: 'inactivate',
                                          icon: <Icon component={TrashCanSvg} />,
                                          onClick: showInactivateModal,
                                          disabled: isInactiveButtonDisabled(),
                                          label: <FormattedMessage id="members.inactivate" />,
                                      },
                                  ],
                        }}
                    >
                        <Button type="primary" size="large">
                            <FormattedMessage id="button.actions" /> <Icon component={CaretDownSvg} />
                        </Button>
                    </Dropdown>
                </Space>
            </div>
            <SelectTableComponent
                dataSource={items}
                columns={columns}
                pagination={pagination}
                rowKey="email"
                onChange={paginate}
                sortDirections={['ascend', 'descend']}
                showSorterTooltip={false}
                loading={loading}
                rowSelection={rowSelection}
                selectedItems={selectedMembers.map((member) => member.email)}
                onUnselectAll={unselectAll}
            />
            <UpdateCompanyModal
                companies={companies}
                visible={companyModalVisible}
                onUpdate={updateCompany}
                onCancel={() => showCompanyModal(false)}
                mandatory={true}
            />
        </LayoutComponent>
    );
};

export default MembersPage;
