import Icon, { CaretRightOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Table, Tooltip, message } from 'antd';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import React, { useEffect, useState } from 'react';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import taskSyncApi from '../../../../../api/TaskSyncApi';
import TaskStatusComponent from '../../../../../components/TaskStatusComponent/TaskStatusComponent';
import { Page } from '../../../../../model/elements';
import { Connection, TaskSync } from '../../../../../model/entities';
import { TaskStatusType } from '../../../../../model/types';
import { ReactComponent as RefreshSvg } from '../../../../../resources/images/refresh.svg';
import notificationService from '../../../../../services/NotificationService';
import tableService from '../../../../../services/TableService';
import styles from './ConnectionTasksComponent.module.scss';
import connectionLinkApi from '../../../../../api/ConnectionLinkApi';

/**
 * Returns the connection tasks component.
 * @props the props
 * @returns the connection tasks component.
 */
const ConnectionTasksComponent = (props: Props): React.ReactElement => {
    const { connection } = props;
    /*** HOOKS ***/

    const intl = useIntl();
    const [tasksPage, setTasksPage] = useState<Page<TaskSync>>();
    const [loading, setLoading] = useState<boolean>();

    /*** EFFECTS ***/

    useEffect(() => {
        const init = async () => {
            try {
                setLoading(true);
                const page = 0;
                const size = tasksPage?.size || tableService.pageSize;
                const sortField = tasksPage?.sort.field || 'auditUpdated';
                const sortOrder = tasksPage?.sort.order || false;
                await listConnectionTasks(page, size, sortField, sortOrder, connection.id!);
            } catch (error) {
                notificationService.displayError(error, intl);
            } finally {
                setLoading(false);
            }
        };
        init();
    }, [connection.id, intl, tasksPage?.size, tasksPage?.sort.field, tasksPage?.sort.order]);

    /*** METHODS ***/

    const list = async () => {
        try {
            setLoading(true);
            const page = 0;
            const size = tasksPage?.size || tableService.pageSize;
            const sortField = tasksPage?.sort.field || 'auditUpdated';
            const sortOrder = tasksPage?.sort.order || false;
            await listConnectionTasks(page, size, sortField, sortOrder, connection.id!);
        } catch (error) {
            notificationService.displayError(error, intl);
        } finally {
            setLoading(false);
        }
    };

    const paginate = async (pagination: TablePaginationConfig, filters: any, sorter: any) => {
        try {
            setLoading(true);
            const page = pagination.current! - 1;
            const size = pagination.pageSize!;
            const sortField = sorter.field;
            const sortOrder = sorter.order === 'ascend';
            await listConnectionTasks(page, size, sortField, sortOrder, connection.id!);
        } catch (error) {
            notificationService.displayError(error, intl);
        } finally {
            setLoading(false);
        }
    };

    const listConnectionTasks = async (
        page: number,
        size: number,
        sortField: string,
        sortOrder: boolean,
        connectionId: number,
    ) => {
        const tasksPage = await taskSyncApi.list(page, size, sortField, sortOrder, connectionId);
        setTasksPage(tasksPage);
    };

    const create = async () => {
        try {
            setLoading(true);
            if (connection.id) {
                const connectionLinks = await connectionLinkApi.list(connection.id);

                if (connectionLinks.length > 0) {
                    const task: TaskSync = {
                        organizationId: connection.organizationId,
                        connectionId: connection.id,
                    };
                    await taskSyncApi.create(task);
                    await list();
                } else {
                    message.warning(intl.formatMessage({ id: 'connectionTasks.error.emptyLinks' }), 5);
                }
            }
        } catch (error) {
            notificationService.displayError(error, intl);
        } finally {
            setLoading(false);
        }
    };

    /*** COMPONENTS ***/

    const items = tasksPage ? tasksPage.content : [];
    const columns: ColumnsType<TaskSync> = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
            sorter: true,
            align: 'right',
            width: 50,
            render: (value: string, task: TaskSync) => <Link to={`/task-syncs/${task.id}`}>{value}</Link>,
        },
        {
            title: <FormattedMessage id="task.audit.creator" />,
            dataIndex: 'auditCreatorFirstName',
            key: 'creator',
            sorter: true,
            render: (value: string, task: TaskSync) => (
                <Link to={`/task-syncs/${task.id}`}>
                    {`${task.audit?.creator?.firstName} ${task.audit?.creator?.lastName}`}
                </Link>
            ),
        },
        {
            title: <FormattedMessage id="task.audit.created" />,
            dataIndex: 'auditCreated',
            key: 'created',
            sorter: true,
            width: 240,
            align: 'center',
            render: (value: string, task: TaskSync) => (
                <Link to={`/task-syncs/${task.id}`}>
                    <FormattedDate
                        value={task.audit!.created as any}
                        day="2-digit"
                        month="2-digit"
                        year="numeric"
                        hour="2-digit"
                        minute="2-digit"
                        second="2-digit"
                    />
                </Link>
            ),
        },
        {
            title: <FormattedMessage id="task.audit.updated" />,
            dataIndex: 'auditUpdated',
            key: 'updated',
            sorter: true,
            defaultSortOrder: 'descend',
            width: 240,
            align: 'center',
            render: (value: string, task: TaskSync) => (
                <Link to={`/task-syncs/${task.id}`}>
                    <FormattedDate
                        value={task.audit!.updated as any}
                        day="2-digit"
                        month="2-digit"
                        year="numeric"
                        hour="2-digit"
                        minute="2-digit"
                        second="2-digit"
                    />
                </Link>
            ),
        },
        {
            title: <FormattedMessage id="task.status" />,
            dataIndex: 'status',
            key: 'status',
            sorter: true,
            width: 200,
            align: 'center',
            render: (status: TaskStatusType, task: TaskSync) => (
                <Link to={`/task-syncs/${task.id}`}>
                    <TaskStatusComponent status={status} />
                </Link>
            ),
        },
    ];

    return (
        <>
            <div className={`toolbar ${styles.tab}`}>
                <Tooltip title={<FormattedMessage id="button.refresh" />}>
                    <Button
                        type="ghost"
                        size="large"
                        icon={<Icon component={RefreshSvg} />}
                        onClick={list}
                        className={styles.refresh}
                    />
                </Tooltip>
                <Tooltip title={<FormattedMessage id="connectionTasks.new.tooltip" />}>
                    <Popconfirm
                        title={<FormattedMessage id="connectionTasks.create" />}
                        onConfirm={create}
                        okText={<FormattedMessage id="button.yes" />}
                        cancelText={<FormattedMessage id="button.no" />}
                    >
                        <Button type="primary" size="large" icon={<CaretRightOutlined />}></Button>
                    </Popconfirm>
                </Tooltip>
            </div>
            <Table
                dataSource={items}
                columns={columns}
                pagination={tableService.createPagination(tasksPage, { position: ['bottomRight'], pageSize: 10 })}
                rowKey="id"
                onChange={paginate}
                sortDirections={['ascend', 'descend']}
                showSorterTooltip={false}
                loading={loading}
            />
        </>
    );
};

export default ConnectionTasksComponent;

interface Props {
    connection: Connection;
}
