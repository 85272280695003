import { Col, Form, Input, Modal, Row } from 'antd';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import notificationService from '../../../../../../services/NotificationService';
import ConnectionLinkSource from '../entities/ConnectionLinkSource';
import styles from './ConnectionLinkFolderModal.module.scss';

/**
 * Returns the connection link folder modal.
 * @props the props
 * @returns the connection link folder modal
 */
const ConnectionLinkFolderModal = (props: Props): React.ReactElement => {
    const { onCancel, onSave, visible } = props;
    /*** HOOKS ***/

    const [form] = Form.useForm();
    const intl = useIntl();
    const [failure, setFailure] = useState<boolean>();
    const [loading, setLoading] = useState<'saving'>();

    /*** EFFECTS ***/

    /*** METHODS ***/

    const cancel = () => {
        form.resetFields();
        setFailure(undefined);
        onCancel();
    };

    const submit = async () => {
        try {
            const values = await form.validateFields();
            await save(values);
        } catch (error) {}
    };

    const save = async (values: any) => {
        try {
            setLoading('saving');

            let path = values.path as string;
            path = path.replace(/\\/g, '/');
            if (path.includes(':/')) {
                path = path.substring(path.indexOf(':/') + 2);
            }
            path = path.replace('Shared With Me', 'Collaborate');
            path = path.replace('My Documents', 'MyDocs');

            const loaded = await onSave(path);
            if (loaded) {
                setFailure(undefined);
                cancel();
            } else {
                setFailure(true);
            }
        } catch (error) {
            notificationService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    /*** COMPONENTS ***/

    return (
        <Modal
            title={<FormattedMessage id="connectionLink.modal.folder.title" />}
            open={visible}
            onCancel={cancel}
            onOk={submit}
            okButtonProps={{ disabled: !!loading }}
            cancelButtonProps={{ disabled: !!loading }}
            width={500}
        >
            <Form form={form} colon={false} layout="vertical">
                <Row gutter={[28, 0]}>
                    <Col span={24}>
                        <Form.Item
                            label={<FormattedMessage id="connectionLink.modal.folder.path" />}
                            name={'path'}
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <Input maxLength={300} size="large" />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            {failure && (
                <p className={styles.error}>{<FormattedMessage id="connectionLink.modal.folder.path.notFound" />}</p>
            )}
        </Modal>
    );
};

export default ConnectionLinkFolderModal;

interface Props {
    visible?: boolean;
    onSave: (folderPath: string) => Promise<boolean | ConnectionLinkSource | undefined>;
    onCancel: () => void;
}
