import { Badge, Button, Image, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import procoreIcon from '../../resources/images/procore.svg';
import notificationService from '../../services/NotificationService';
import procoreService from '../../services/ProcoreService';
import styles from './ProcoreComponent.module.scss';

/**
 * Returns the procore component.
 * @returns the procore component.
 */
const ProcoreComponent = (): React.ReactElement => {
    /*** HOOKS ***/

    const intl = useIntl();
    const [active, setActive] = useState<boolean>(false);
    const [procoreAllowed, setProcoreAllowed] = useState<boolean>();

    /*** EFFECTS ***/

    useEffect(() => {
        const initialize = async () => {
            try {
                const procoreAllowed = !!procoreService.getCachedClientId();
                setProcoreAllowed(procoreAllowed);
                if (procoreAllowed) {
                    const accessToken = procoreService.getCachedAccessToken();
                    if (accessToken && procoreService.isAccessTokenValid(accessToken)) {
                        setActive(!!accessToken);
                    }
                }
            } catch (error) {
                notificationService.displayError(error, intl);
            }
        };
        initialize();

        const interval = setInterval(() => {
            initialize();
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [intl]);

    /*** METHODS ***/

    const connect = async () => {
        try {
            const accessToken = await procoreService.getAccessTokenOrLogin();
            setActive(!!accessToken);
        } catch (error) {
            notificationService.displayError(error, intl);
        }
    };

    /*** COMPONENTS ***/

    if (procoreAllowed && active) {
        return (
            <Tooltip title={<FormattedMessage id="header.settings.procore.active.tooltip" />} className={styles.icon}>
                <Badge dot offset={[-3, 5]} color="green">
                    <Button type="link" icon={<Image src={procoreIcon} preview={false} className={styles.procore} />} />
                </Badge>
            </Tooltip>
        );
    } else if (procoreAllowed && active === false) {
        return (
            <Tooltip title={<FormattedMessage id="header.settings.procore.inactive.tooltip" />} className={styles.icon}>
                <Badge dot offset={[-3, 5]}>
                    <Button
                        type="link"
                        icon={<Image src={procoreIcon} preview={false} className={styles.procore} />}
                        onClick={connect}
                    />
                </Badge>
            </Tooltip>
        );
    } else {
        return <></>;
    }
};
export default ProcoreComponent;
