import { ProviderType } from '../model/types';
import autodeskService from './AutodeskService';
import procoreService from './ProcoreService';
import sharePointService from './SharePointService';

class ProviderService {
    isConnected = async (provider: ProviderType): Promise<boolean> => {
        let connected: boolean = false;
        switch (provider) {
            case 'ACC':
            case 'BIM360':
                connected = autodeskService.isConnected();
                break;
            case 'SHAREPOINT':
                connected = await sharePointService.isConnected();
                break;
            case 'PROCORE':
                connected = procoreService.isConnected();
                break;
            default:
                break;
        }
        return connected;
    };
}

const providerService: ProviderService = new ProviderService();
export default providerService;
