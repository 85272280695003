import React from 'react';
import styles from './WrapperComponent.module.scss';
import { Col, Row } from 'antd';

const WrapperComponent: React.FC<Props> = (props: Props) => {
    const { title, children } = props;
    return (
        <Row justify="center" className={styles.wrapper}>
            <Col md={24} lg={18} xl={18} xxl={16}>
                {title && <h1>{title}</h1>}
                {children}
            </Col>
        </Row>
    );
};
export default WrapperComponent;

interface Props {
    children?: React.ReactNode;
    title?: React.ReactNode | string;
}
