import Icon from '@ant-design/icons';
import { Button, Table, Tooltip } from 'antd';
import Search from 'antd/lib/input/Search';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import connectionApi from '../../../api/ConnectionApi';
import LayoutComponent from '../../../components/LayoutComponent/LayoutComponent';
import { Page } from '../../../model/elements';
import { Connection } from '../../../model/entities';
import { ReactComponent as AddSvg } from '../../../resources/images/add.svg';
import notificationService from '../../../services/NotificationService';
import tableService from '../../../services/TableService';

/**
 * Returns the connections page.
 * @returns the connections page.
 */
const ConnectionsPage = (): React.ReactElement => {
    /*** HOOKS ***/

    const intl = useIntl();
    const [connectionsPage, setConnectionsPage] = useState<Page<Connection>>();
    const [loading, setLoading] = useState<boolean>();
    const [searchText, setSearchText] = useState<string>();

    /*** EFFECTS ***/

    useEffect(() => {
        const init = async () => {
            try {
                setLoading(true);

                const connectionsPage = await connectionApi.list(0, tableService.pageSize, 'name', true);

                setConnectionsPage(connectionsPage);
            } catch (error) {
                notificationService.displayError(error, intl);
            } finally {
                setLoading(false);
            }
        };
        init();
    }, [intl]);

    /*** METHODS ***/

    const list = async (pagination: TablePaginationConfig, filters: any, sorter: any) => {
        try {
            setLoading(true);
            const page = pagination.current!;
            const pageSize = pagination.pageSize!;
            const sortField = sorter.field;
            const sortOrder = sorter.order === 'ascend';
            const connectionsPage = await connectionApi.list(page - 1, pageSize, sortField, sortOrder, searchText);
            setConnectionsPage(connectionsPage);
        } catch (error) {
            notificationService.displayError(error, intl);
        } finally {
            setLoading(false);
        }
    };

    const search = async (searchText: string) => {
        try {
            setLoading(true);

            const pageSize = connectionsPage!.size;
            const sortField = connectionsPage!.sort.field!;
            const sortOrder = connectionsPage!.sort.order!;
            const connectionsPageNew = await connectionApi.list(0, pageSize, sortField, sortOrder, searchText);

            setConnectionsPage(connectionsPageNew);
            setSearchText(searchText);
        } catch (error) {
            notificationService.displayError(error, intl);
        } finally {
            setLoading(false);
        }
    };

    /*** COMPONENTS ***/

    const items = connectionsPage ? connectionsPage.content : [];
    const columns: ColumnsType<Connection> = [
        {
            title: <FormattedMessage id="connection.name" />,
            dataIndex: 'name',
            key: 'name',
            sorter: true,
            defaultSortOrder: 'ascend',
            width: 300,
            render: (value: string, connection: Connection) => (
                <Link to={`/sync/connections/${connection.id}`}>{value}</Link>
            ),
        },
        {
            title: <FormattedMessage id="connection.description" />,
            dataIndex: 'description',
            key: 'description',
            sorter: true,
            render: (value: string, connection: Connection) => (
                <Link to={`/sync/connections/${connection.id}`}>{value}</Link>
            ),
        },
        {
            title: <FormattedMessage id="connection.audit.creator" />,
            dataIndex: 'audit.creator.firstName',
            key: 'creator',
            sorter: true,
            width: 250,
            render: (value: string, connection: Connection) => (
                <Link to={`/sync/connections/${connection.id}`}>
                    {connection.audit?.creator.firstName} {connection.audit?.creator.lastName}
                </Link>
            ),
        },
        {
            title: <FormattedMessage id="connection.type" />,
            dataIndex: 'source.provider',
            key: 'type',
            sorter: true,
            align: 'center',
            width: 200,
            render: (value: string, connection: Connection) => (
                <Link to={`/sync/connections/${connection.id}`}>
                    {connection.source?.provider} &gt; {connection.target?.provider}
                </Link>
            ),
        },
        {
            title: <FormattedMessage id="connection.source" />,
            dataIndex: 'source.name',
            key: 'source',
            sorter: true,
            align: 'center',
            width: 200,
            render: (value: any, connection: Connection) => (
                <Link to={`/sync/connections/${connection.id}`}>{connection.source?.name}</Link>
            ),
        },
        {
            title: <FormattedMessage id="connection.target" />,
            dataIndex: 'target.name',
            key: 'target',
            sorter: true,
            align: 'center',
            width: 200,
            render: (value: any, connection: Connection) => (
                <Link to={`/sync/connections/${connection.id}`}>{connection.target?.name}</Link>
            ),
        },
    ];

    const placeholder: string = intl.formatMessage({ id: 'connections.search' });

    return (
        <LayoutComponent pageId="connections">
            <div className="toolbar">
                <Search placeholder={placeholder} onSearch={search} size="large" className="search" />
                <Link to="/sync/connections/new">
                    <Tooltip title={<FormattedMessage id="connections.new.tooltip" />}>
                        <Button type="primary" size="large" icon={<Icon component={AddSvg} />}>
                            <FormattedMessage id="connections.new" tagName="span" />
                        </Button>
                    </Tooltip>
                </Link>
            </div>
            <Table
                dataSource={items}
                columns={columns}
                pagination={tableService.createPagination(connectionsPage)}
                rowKey="id"
                onChange={list}
                sortDirections={['ascend', 'descend']}
                showSorterTooltip={false}
                loading={loading}
            />
        </LayoutComponent>
    );
};

export default ConnectionsPage;
