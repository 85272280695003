import Icon from '@ant-design/icons';
import { Button, Descriptions, Space, Table, Tooltip } from 'antd';
import Search from 'antd/lib/input/Search';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import React, { useEffect, useState } from 'react';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { Link, useParams } from 'react-router-dom';
import taskProjectIssueActionApi from '../../../../../api/TaskProjectIssueActionApi';
import taskProjectIssueActionFileApi from '../../../../../api/TaskProjectIssueActionFileApi';
import taskProjectIssueApi from '../../../../../api/TaskProjectIssueApi';
import FileSizeComponent from '../../../../../components/FileSizeComponent/FileSizeComponent';
import LayoutComponent from '../../../../../components/LayoutComponent/LayoutComponent';
import TaskStatusComponent from '../../../../../components/TaskStatusComponent/TaskStatusComponent';
import { Page } from '../../../../../model/elements';
import { TaskProjectIssue, TaskProjectIssueAction, TaskProjectIssueActionFile } from '../../../../../model/entities';
import { TaskStatusType } from '../../../../../model/types';
import { ReactComponent as BackSvg } from '../../../../../resources/images/back.svg';
import { ReactComponent as RefreshSvg } from '../../../../../resources/images/refresh.svg';
import notificationService from '../../../../../services/NotificationService';
import tableService from '../../../../../services/TableService';
import styles from './TaskProjectIssueActionPage.module.scss';

/**
 * Returns the tasks project issue action page.
 * @returns the tasks project issue action page.
 */
const TaskProjectIssueActionPage = (): React.ReactElement => {
    /*** HOOKS ***/

    const intl = useIntl();
    const [task, setTask] = useState<TaskProjectIssue>();
    const [taskProjectIssueAction, setTaskProjectIssueAction] = useState<TaskProjectIssueAction>();
    const [taskProjectIssueActionFilesPage, setTaskProjectIssueActionFilesPage] =
        useState<Page<TaskProjectIssueActionFile>>();
    const [loading, setLoading] = useState<boolean>();
    const [searchText, setSearchText] = useState<string>();
    const params = useParams<ParamsType>();

    /*** EFFECTS ***/

    useEffect(() => {
        const init = async () => {
            try {
                setLoading(true);

                const id = +params.id;
                const page = 0;
                const size = taskProjectIssueActionFilesPage?.size || tableService.pageSize;
                const sortField = taskProjectIssueActionFilesPage?.sort.field || 'auditUpdated';
                const sortOrder = taskProjectIssueActionFilesPage?.sort.order || false;
                const responses = await Promise.all([
                    taskProjectIssueActionApi.get(id),
                    listTaskProjectIssueActionFiles(page, size, sortField, sortOrder, id, searchText),
                ]);

                const taskProjectIssueAction = responses[0];
                const task = await taskProjectIssueApi.get(taskProjectIssueAction.taskId!);
                setTask(task);
                setTaskProjectIssueAction(taskProjectIssueAction);
            } catch (error) {
                notificationService.displayError(error, intl);
            } finally {
                setLoading(false);
            }
        };
        init();
    }, [
        intl,
        params.id,
        searchText,
        taskProjectIssueActionFilesPage?.size,
        taskProjectIssueActionFilesPage?.sort.field,
        taskProjectIssueActionFilesPage?.sort.order,
    ]);

    /*** METHODS ***/

    const list = async () => {
        try {
            setLoading(true);
            const id = +params.id;
            const page = 0;
            const size = taskProjectIssueActionFilesPage?.size || tableService.pageSize;
            const sortField = taskProjectIssueActionFilesPage?.sort.field || 'auditUpdated';
            const sortOrder = taskProjectIssueActionFilesPage?.sort.order || false;
            const responses = await Promise.all([
                taskProjectIssueActionApi.get(id),
                listTaskProjectIssueActionFiles(page, size, sortField, sortOrder, id, searchText),
            ]);

            const taskProjectIssueAction = responses[0];
            const task = await taskProjectIssueApi.get(taskProjectIssueAction.taskId!);
            setTask(task);
            setTaskProjectIssueAction(taskProjectIssueAction);
        } catch (error) {
            notificationService.displayError(error, intl);
        } finally {
            setLoading(false);
        }
    };

    const paginate = async (pagination: TablePaginationConfig, filters: any, sorter: any) => {
        try {
            setLoading(true);
            const page = pagination.current! - 1;
            const pageSize = pagination.pageSize!;
            const sortField = sorter.field;
            const sortOrder = sorter.order === 'ascend';
            listTaskProjectIssueActionFiles(
                page,
                pageSize,
                sortField,
                sortOrder,
                taskProjectIssueAction!.id!,
                searchText,
            );
        } catch (error) {
            notificationService.displayError(error, intl);
        } finally {
            setLoading(false);
        }
    };

    const search = async (searchText: string) => {
        try {
            setLoading(true);
            setSearchText(searchText);
            const page = 0;
            const pageSize = taskProjectIssueActionFilesPage!.size;
            const sortField = taskProjectIssueActionFilesPage!.sort.field!;
            const sortOrder = taskProjectIssueActionFilesPage!.sort.order!;
            listTaskProjectIssueActionFiles(
                page,
                pageSize,
                sortField,
                sortOrder,
                taskProjectIssueAction!.id!,
                searchText,
            );
        } catch (error) {
            notificationService.displayError(error, intl);
        } finally {
            setLoading(false);
        }
    };

    const listTaskProjectIssueActionFiles = async (
        page: number,
        size: number,
        sortField: string,
        sortOrder: boolean,
        taskProjectIssueActionId: number,
        searchText?: string,
    ) => {
        const taskProjectIssueActionFilesPage = await taskProjectIssueActionFileApi.list(
            page,
            size,
            sortField,
            sortOrder,
            taskProjectIssueActionId,
            searchText,
        );
        setTaskProjectIssueActionFilesPage(taskProjectIssueActionFilesPage);
    };

    /*** COMPONENTS ***/

    const items = taskProjectIssueActionFilesPage ? taskProjectIssueActionFilesPage.content : [];
    const columns: ColumnsType<TaskProjectIssueActionFile> = [
        {
            title: <FormattedMessage id="taskProjectIssueActionFile.name" />,
            dataIndex: 'name',
            key: 'name',
            sorter: true,
        },
        {
            title: <FormattedMessage id="taskProjectIssueActionFile.audit.created" />,
            dataIndex: 'auditCreated',
            key: 'created',
            sorter: true,
            width: 240,
            align: 'center',
            render: (value: string, taskProjectIssueActionFile: TaskProjectIssueActionFile) => (
                <FormattedDate
                    value={taskProjectIssueActionFile.audit!.updated as any}
                    day="2-digit"
                    month="2-digit"
                    year="numeric"
                    hour="2-digit"
                    minute="2-digit"
                    second="2-digit"
                />
            ),
        },
        {
            title: <FormattedMessage id="taskProjectIssueActionFile.audit.updated" />,
            dataIndex: 'auditUpdated',
            key: 'updated',
            sorter: true,
            defaultSortOrder: 'descend',
            width: 240,
            align: 'center',
            render: (value: string, taskProjectIssueActionFile: TaskProjectIssueActionFile) => (
                <FormattedDate
                    value={taskProjectIssueActionFile.audit!.updated as any}
                    day="2-digit"
                    month="2-digit"
                    year="numeric"
                    hour="2-digit"
                    minute="2-digit"
                    second="2-digit"
                />
            ),
        },
        {
            title: <FormattedMessage id="taskProjectIssueActionFile.status" />,
            dataIndex: 'status',
            key: 'status',
            sorter: true,
            width: 200,
            align: 'center',
            render: (status: TaskStatusType, taskProjectIssueActionFile: TaskProjectIssueActionFile) => (
                <TaskStatusComponent status={status} error={taskProjectIssueActionFile.error} />
            ),
        },
        {
            title: <FormattedMessage id="taskProjectIssueActionFile.size" />,
            dataIndex: 'size',
            key: 'size',
            width: 100,
            align: 'right',
            sorter: true,
            render: (value: number) => <FileSizeComponent value={value} />,
        },
    ];

    const placeholder: string = intl.formatMessage({ id: 'taskProjectIssueActionFiles.search' });

    return (
        <LayoutComponent pageId="tasks">
            <Descriptions
                title={
                    <>
                        <span className={styles.title}>
                            #{task?.id} - {task?.name} - {taskProjectIssueAction?.title}
                        </span>
                        <TaskStatusComponent status={taskProjectIssueAction?.attachmentsStatus} />
                        <Space>
                            <Tooltip title={<FormattedMessage id="button.back" />}>
                                <Link to={`/task-project-issues/${task?.id}`}>
                                    <Button type="ghost" size="small" icon={<Icon component={BackSvg} />} />
                                </Link>
                            </Tooltip>
                            <Tooltip title={<FormattedMessage id="button.refresh" />}>
                                <Button
                                    type="ghost"
                                    size="small"
                                    icon={<Icon component={RefreshSvg} />}
                                    onClick={list}
                                />
                            </Tooltip>
                        </Space>
                    </>
                }
                column={2}
                size="small"
                className={styles.summary}
            >
                <Descriptions.Item label={<FormattedMessage id="task.source" />}>
                    {task?.sourceHub?.provider} &gt; {task?.sourceHub?.name} &gt; {task?.sourceProject.name}
                </Descriptions.Item>
                <br></br>
                <Descriptions.Item label={<FormattedMessage id="task.audit.created" />}>
                    <FormattedDate
                        value={taskProjectIssueAction?.audit?.created as any}
                        day="2-digit"
                        month="2-digit"
                        year="numeric"
                        hour="2-digit"
                        minute="2-digit"
                        second="2-digit"
                    />
                </Descriptions.Item>
                <Descriptions.Item label={<FormattedMessage id="task.audit.updated" />}>
                    <FormattedDate
                        value={taskProjectIssueAction?.audit?.updated as any}
                        day="2-digit"
                        month="2-digit"
                        year="numeric"
                        hour="2-digit"
                        minute="2-digit"
                        second="2-digit"
                    />
                </Descriptions.Item>
            </Descriptions>
            <div className="toolbar">
                <Search placeholder={placeholder} onSearch={search} size="large" className="search" />
            </div>
            <Table
                dataSource={items}
                columns={columns}
                pagination={tableService.createPagination(taskProjectIssueActionFilesPage, {
                    position: ['bottomRight'],
                })}
                rowKey="id"
                onChange={paginate}
                sortDirections={['ascend', 'descend']}
                showSorterTooltip={false}
                loading={loading}
            />
        </LayoutComponent>
    );
};

export default TaskProjectIssueActionPage;

type ParamsType = { id: string };
