import { Tag } from 'antd';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ProjectIssueAccStatus, ProjectIssueBim360Status } from '../../../../model/types';

/**
 * This component represents a project issue status component.
 * @param props - the props
 */
export const ProjectIssueStatusComponent: React.FC<Props> = (props) => {
    const { status, platform } = props;

    if (platform && platform === 'BIM360') {
        switch (status) {
            case 'draft':
                return (
                    <Tag color="cyan">
                        <FormattedMessage id={`projectIssue.status.${status}`} />
                    </Tag>
                );
            case 'open':
                return (
                    <Tag color="yellow">
                        <FormattedMessage id={`projectIssue.status.${status}`} />
                    </Tag>
                );
            case 'answered':
            case 'work_completed':
                return (
                    <Tag color="blue">
                        <FormattedMessage id={`projectIssue.status.${status}`} />
                    </Tag>
                );
            case 'ready_to_inspect':
                return (
                    <Tag color="purple">
                        <FormattedMessage id={`projectIssue.status.${status}`} />
                    </Tag>
                );
            case 'not_approved':
            case 'in_dispute':
                return (
                    <Tag color="magenta">
                        <FormattedMessage id={`projectIssue.status.${status}`} />
                    </Tag>
                );
            case 'closed':
            case 'void':
                return (
                    <Tag>
                        <FormattedMessage id={`projectIssue.status.${status}`} />
                    </Tag>
                );
            default:
                return <></>;
        }
    } else if (platform && platform === 'ACC') {
        switch (status) {
            case 'draft':
                return (
                    <Tag>
                        <FormattedMessage id={`projectIssue.status.${status}`} />
                    </Tag>
                );
            case 'open':
                return (
                    <Tag color="yellow">
                        <FormattedMessage id={`projectIssue.status.${status}`} />
                    </Tag>
                );
            case 'in_progress':
                return (
                    <Tag color="cyan">
                        <FormattedMessage id={`projectIssue.status.${status}`} />
                    </Tag>
                );
            case 'completed':
                return (
                    <Tag color="green">
                        <FormattedMessage id={`projectIssue.status.${status}`} />
                    </Tag>
                );
            case 'pending':
                return (
                    <Tag color="blue">
                        <FormattedMessage id={`projectIssue.status.${status}`} />
                    </Tag>
                );
            case 'in_review':
                return (
                    <Tag color="purple">
                        <FormattedMessage id={`projectIssue.status.${status}`} />
                    </Tag>
                );
            case 'not_approved':
            case 'in_dispute':
                return (
                    <Tag color="magenta">
                        <FormattedMessage id={`projectIssue.status.${status}`} />
                    </Tag>
                );
            case 'closed':
                return (
                    <Tag>
                        <FormattedMessage id={`projectIssue.status.${status}`} />
                    </Tag>
                );
            default:
                return <></>;
        }
    } else {
        return <></>;
    }
};
export default ProjectIssueStatusComponent;

interface Props {
    status?: ProjectIssueBim360Status | ProjectIssueAccStatus;
    platform?: string;
}
