import Icon from '@ant-design/icons';
import { Button, Space, Table, Tooltip, Form } from 'antd';
import { ColumnType } from 'antd/lib/table';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { v4 as uuidv4 } from 'uuid';
import folderApi from '../../../../../../../api/FolderApi';
import { FolderDataNode } from '../../../../../../../model/elements';
import { Connection, ConnectionLinkTarget, Project } from '../../../../../../../model/entities';
import { ReactComponent as AddSvg } from '../../../../../../../resources/images/add.svg';
import { ReactComponent as CheckmarkSvg } from '../../../../../../../resources/images/checkmark.svg';
import { ReactComponent as CloseSvg } from '../../../../../../../resources/images/close-outline.svg';
import { ReactComponent as EditSvg } from '../../../../../../../resources/images/edit.svg';
import { ReactComponent as TrashCanSvg } from '../../../../../../../resources/images/trash-can.svg';
import stringService from '../../../../../../../services/StringService';
import treeFolderService from '../../../../../../../services/TreeFolderService';
import EditableCell from './EditableCell/EditableCell';

/**
 * Returns the connection link modal targets.
 * @returns the connection link modal targets
 */
const ConnectionLinkModalTargets = (props: Props): React.ReactElement => {
    const { connection, onRemove, onSave, projects, targets, loading } = props;
    /*** HOOKS ***/

    const [form] = Form.useForm();
    const [target, setTarget] = useState<ConnectionLinkTarget>();
    const [folderNodes, setFolderNodes] = useState<FolderDataNode[]>([]);
    const [editingKey, setEditingKey] = useState<string>();

    /*** EFFECTS ***/

    /*** METHODS ***/

    const listFolders = async (projectId: string) => {
        try {
            const folders = await folderApi.list(connection.target!.provider, connection.target?.id!, projectId);
            const folderNodes = folders.map((f) => treeFolderService.createFolderNode(f));
            setFolderNodes(folderNodes);
        } catch (e) {
            setFolderNodes([]);
        }
    };

    const listSubfolders = async (folderId: string) => {
        try {
            const folderNodesAux = [...folderNodes];
            await treeFolderService.loadSubnodes(folderId, folderNodesAux);
            setFolderNodes(folderNodesAux);
        } catch (e) {
            console.log(e);
        }
    };

    const isEditing = (connectionLinkTarget: ConnectionLinkTarget) => {
        return connectionLinkTarget.uuid === editingKey;
    };

    const add = () => {
        const target: ConnectionLinkTarget = { uuid: uuidv4() };
        setTarget(target);
        setEditingKey(target.uuid);
    };

    const edit = async (target: ConnectionLinkTarget) => {
        form.setFieldsValue({
            project: {
                id: target.project?.id,
            },
            folder: target.folder?.name,
        });
        await listFolders(target.project?.id!);
        setEditingKey(target.uuid);
    };

    const save = async (target: ConnectionLinkTarget) => {
        try {
            const values = await form.validateFields();
            const project = projects.find((p) => p.id === values.project.id);
            const folderNode = treeFolderService.find(values.folder, folderNodes) as FolderDataNode;
            if (folderNode) {
                const folder = Object.assign({}, folderNode?.folder, { name: folderNode?.path });
                const newTarget: ConnectionLinkTarget = {
                    uuid: target.uuid,
                    project: project,
                    folder: folder,
                };
                await onSave(newTarget);
            }
            form.resetFields();
            cancel();
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };

    const cancel = () => {
        form.resetFields();
        setTarget(undefined);
        setEditingKey(undefined);
    };

    /*** COMPONENTS ***/

    let targetsAux = targets;
    if (target) {
        targetsAux = [target, ...targetsAux];
    }

    const columns: EditableColumnsType<ConnectionLinkTarget> = [
        {
            title: <FormattedMessage id="connectionLink.targets.project" />,
            dataIndex: ['project', 'id'],
            key: 'project',
            width: 350,
            sorter: (a, b) => stringService.sort(a.project?.name, b.project?.name),
            defaultSortOrder: 'ascend',
            editable: true,
            render: (value: string, target: ConnectionLinkTarget) => target.project?.name,
        },
        {
            title: <FormattedMessage id="connectionLink.targets.folder" />,
            dataIndex: 'folder',
            key: 'folder',
            sorter: (a, b) => stringService.sort(a.folder?.name, b.folder?.name),
            editable: true,
            render: (value: string, target: ConnectionLinkTarget) => target.folder?.name,
        },
        {
            title: (
                <Tooltip title={<FormattedMessage id="connectionLink.targets.new" tagName="span" />}>
                    <Button
                        type="default"
                        icon={<Icon component={AddSvg} />}
                        onClick={add}
                        disabled={!!editingKey}
                    ></Button>
                </Tooltip>
            ),
            dataIndex: 'action',
            align: 'center',
            width: 100,
            render: (value: any, target: ConnectionLinkTarget) => {
                const editable = isEditing(target);
                return editable ? (
                    <Space size={0}>
                        <Button
                            type="link"
                            size="large"
                            key="save"
                            icon={<Icon component={CheckmarkSvg} />}
                            onClick={() => save(target)}
                        />
                        <Button
                            type="link"
                            size="large"
                            key="cancel"
                            icon={<Icon component={CloseSvg} />}
                            onClick={() => cancel()}
                        />
                    </Space>
                ) : (
                    <Space size={0}>
                        <Button
                            type="link"
                            size="large"
                            key="edit"
                            icon={<Icon component={EditSvg} />}
                            disabled={!!editingKey}
                            onClick={() => edit(target)}
                        />
                        <Button
                            type="link"
                            size="large"
                            key="remove"
                            danger
                            icon={<Icon component={TrashCanSvg} />}
                            disabled={!!editingKey}
                            onClick={() => onRemove(target)}
                        />
                    </Space>
                );
            },
        },
    ];

    const mergedColumns: any = columns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record: ConnectionLinkTarget) => ({
                record,
                identifier: col.key,
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
                projects: projects,
                folderNodes: folderNodes,
                onProjectChange: listFolders,
                onFolderChange: listSubfolders,
            }),
        };
    });

    return (
        <Form form={form} component={false}>
            <Table
                components={{
                    body: {
                        cell: EditableCell,
                    },
                }}
                dataSource={targetsAux}
                columns={mergedColumns}
                rowKey="uuid"
                sortDirections={['ascend']}
                showSorterTooltip={false}
                rowClassName="editable-row"
                pagination={{
                    onChange: cancel,
                    size: 'small',
                    hideOnSinglePage: true,
                }}
                loading={loading}
            />
        </Form>
    );
};

export default ConnectionLinkModalTargets;

interface Props {
    connection: Connection;
    targets: ConnectionLinkTarget[];
    projects: Project[];
    onSave: (target: ConnectionLinkTarget) => Promise<void>;
    onRemove: (target: ConnectionLinkTarget) => Promise<void>;
    loading?: boolean;
}

type EditableColumnsType<RecordType = unknown> = EditableColumnType<RecordType>[];

interface EditableColumnType<RecordType> extends ColumnType<RecordType> {
    editable?: boolean;
}
