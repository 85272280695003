import { Col, Form, Input, Row } from 'antd';
import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import userApi from '../../api/UserApi';
import LayoutComponent from '../../components/LayoutComponent/LayoutComponent';
import WrapperComponent from '../../components/WrapperComponent/WrapperComponent';
import notificationService from '../../services/NotificationService';
import styles from './SettingsPage.module.scss';

/**
 * Returns the settings page.
 * @returns the settings page.
 */
const SettingsPage = (): React.ReactElement => {
    /*** HOOKS ***/

    const [form] = Form.useForm();
    const intl = useIntl();

    /*** EFFECTS ***/

    useEffect(() => {
        const init = async () => {
            try {
                const user = await userApi.getPrincipal();
                form.setFieldsValue(user);
            } catch (error) {
                notificationService.displayError(error, intl);
            }
        };
        init();
    }, [form, intl]);

    /*** METHODS ***/

    /*** COMPONENTS ***/

    return (
        <LayoutComponent pageId="settings">
            <WrapperComponent title={<FormattedMessage id="settings.title" />}>
                <Form form={form} colon={false} layout="vertical">
                    <Row gutter={[28, 0]}>
                        <Col span={12}>
                            <Form.Item label={<FormattedMessage id="settings.email" />} name="email">
                                <Input size="large" disabled className={styles.field} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[28, 0]}>
                        <Col span={12}>
                            <Form.Item label={<FormattedMessage id="settings.firstName" />} name="firstName">
                                <Input size="large" disabled className={styles.field} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label={<FormattedMessage id="settings.lastName" />} name="lastName">
                                <Input size="large" disabled className={styles.field} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </WrapperComponent>
        </LayoutComponent>
    );
};

export default SettingsPage;
