import { ProcoreOauthResponse } from '../model/entities';
import { basicProxyApiAxios } from './CustomAxios';

class ProcoreApi {
    getToken = async (code: string): Promise<ProcoreOauthResponse> => {
        const response = await basicProxyApiAxios.post<ProcoreOauthResponse>('/procore/access-tokens', code, {
            headers: {
                'Content-Type': 'text/plain',
            },
        });
        return response.data;
    };

    refreshToken = async (refreshToken: string): Promise<ProcoreOauthResponse> => {
        const response = await basicProxyApiAxios.post<ProcoreOauthResponse>('/procore/refresh-tokens', refreshToken, {
            headers: {
                'Content-Type': 'text/plain',
            },
        });
        return response.data;
    };
}

const procoreApi: ProcoreApi = new ProcoreApi();
export default procoreApi;
