import Icon from '@ant-design/icons';
import { Button, Descriptions, Space, Table, Tooltip } from 'antd';
import Search from 'antd/lib/input/Search';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import React, { useEffect, useState } from 'react';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { Link, useParams } from 'react-router-dom';
import taskSyncApi from '../../../../api/TaskSyncApi';
import taskSyncFileApi from '../../../../api/TaskSyncFileApi';
import taskSyncLinkApi from '../../../../api/TaskSyncLinkApi';
import LayoutComponent from '../../../../components/LayoutComponent/LayoutComponent';
import TaskStatusComponent from '../../../../components/TaskStatusComponent/TaskStatusComponent';
import { Page } from '../../../../model/elements';
import { TaskSync, TaskSyncFileStatus, TaskSyncLink } from '../../../../model/entities';
import { TaskStatusType } from '../../../../model/types';
import { ReactComponent as BackSvg } from '../../../../resources/images/back.svg';
import { ReactComponent as RefreshSvg } from '../../../../resources/images/refresh.svg';
import notificationService from '../../../../services/NotificationService';
import tableService from '../../../../services/TableService';
import styles from './TaskSyncPage.module.scss';
import TaskSyncStatusComponent from './TaskSyncStatusComponent/TaskSyncStatusComponent';

/**
 * Returns the task sync page.
 * @returns the task sync page.
 */
const TaskSyncPage = (): React.ReactElement => {
    /*** HOOKS ***/

    const intl = useIntl();
    const params = useParams<ParamsType>();
    const [task, setTask] = useState<TaskSync>();
    const [taskSyncLinksPage, setTaskSyncLinksPage] = useState<Page<TaskSyncLink>>();
    const [taskSyncFileStatuses, setTaskSyncFileStatuses] = useState<TaskSyncFileStatus[]>([]);
    const [loading, setLoading] = useState<boolean>();
    const [searchText, setSearchText] = useState<string>();

    /*** EFFECTS ***/

    useEffect(() => {
        const init = async () => {
            try {
                setLoading(true);
                const task = await taskSyncApi.get(+params.id);
                setTask(task);

                const page = 0;
                const size = taskSyncLinksPage?.size || tableService.pageSize;
                const sortField = taskSyncLinksPage?.sort.field || 'auditUpdated';
                const sortOrder = taskSyncLinksPage?.sort.order || false;
                await listTaskSyncLinks(page, size, sortField, sortOrder, task.id!, searchText);

                await listTaskSyncFileStatuses(task.id!);
            } catch (error) {
                notificationService.displayError(error, intl);
            } finally {
                setLoading(false);
            }
        };
        init();
    }, [
        intl,
        params.id,
        searchText,
        taskSyncLinksPage?.size,
        taskSyncLinksPage?.sort.field,
        taskSyncLinksPage?.sort.order,
    ]);

    /*** METHODS ***/

    const list = async () => {
        try {
            setLoading(true);
            const task = await taskSyncApi.get(+params.id);
            setTask(task);

            const page = 0;
            const size = taskSyncLinksPage?.size || tableService.pageSize;
            const sortField = taskSyncLinksPage?.sort.field || 'auditUpdated';
            const sortOrder = taskSyncLinksPage?.sort.order || false;
            await listTaskSyncLinks(page, size, sortField, sortOrder, task.id!, searchText);

            await listTaskSyncFileStatuses(task.id!);
        } catch (error) {
            notificationService.displayError(error, intl);
        } finally {
            setLoading(false);
        }
    };

    const paginate = async (pagination: TablePaginationConfig, filters: any, sorter: any) => {
        try {
            setLoading(true);
            const page = pagination.current! - 1;
            const pageSize = pagination.pageSize!;
            const sortField = sorter.field;
            const sortOrder = sorter.order === 'ascend';
            listTaskSyncLinks(page, pageSize, sortField, sortOrder, task!.id!, searchText);
        } catch (error) {
            notificationService.displayError(error, intl);
        } finally {
            setLoading(false);
        }
    };

    const search = async (searchText: string) => {
        try {
            setLoading(true);
            setSearchText(searchText);
            const page = 0;
            const pageSize = taskSyncLinksPage!.size;
            const sortField = taskSyncLinksPage!.sort.field!;
            const sortOrder = taskSyncLinksPage!.sort.order!;
            listTaskSyncLinks(page, pageSize, sortField, sortOrder, task!.id!, searchText);
        } catch (error) {
            notificationService.displayError(error, intl);
        } finally {
            setLoading(false);
        }
    };

    const listTaskSyncLinks = async (
        page: number,
        size: number,
        sortField: string,
        sortOrder: boolean,
        taskId: number,
        searchText?: string,
    ) => {
        const taskSyncLinksPage = await taskSyncLinkApi.list(page, size, sortField, sortOrder, taskId, searchText);
        setTaskSyncLinksPage(taskSyncLinksPage);
    };

    const listTaskSyncFileStatuses = async (taskId: number) => {
        const taskSyncFileStatuses = await taskSyncFileApi.listStatuses(taskId);
        setTaskSyncFileStatuses(taskSyncFileStatuses);
    };

    /*** COMPONENTS ***/

    const placeholder: string = intl.formatMessage({ id: 'taskLinks.search' });
    const items = taskSyncLinksPage ? taskSyncLinksPage.content : [];
    const columns: ColumnsType<TaskSyncLink> = [
        {
            title: <FormattedMessage id="taskLink.source.project" />,
            dataIndex: 'source.project.name',
            key: 'project',
            sorter: true,
            render: (value: string, taskSyncLink: TaskSyncLink) => (
                <Link to={`/task-sync-links/${taskSyncLink.id}`}>{taskSyncLink.source?.project?.name}</Link>
            ),
        },
        {
            title: <FormattedMessage id="taskLink.source.folder" />,
            dataIndex: 'source.folder.name',
            key: 'folder',
            sorter: true,
            render: (value: string, taskSyncLink: TaskSyncLink) => (
                <Link to={`/task-sync-links/${taskSyncLink.id}`}>{taskSyncLink.source?.folder?.name}</Link>
            ),
        },
        {
            title: <FormattedMessage id="taskLink.audit.created" />,
            dataIndex: 'auditCreated',
            key: 'created',
            sorter: true,
            width: 240,
            align: 'center',
            render: (value: string, taskSyncLink: TaskSyncLink) => (
                <Link to={`/task-sync-links/${taskSyncLink.id}`}>
                    <FormattedDate
                        value={taskSyncLink.audit!.created as any}
                        day="2-digit"
                        month="2-digit"
                        year="numeric"
                        hour="2-digit"
                        minute="2-digit"
                        second="2-digit"
                    />
                </Link>
            ),
        },
        {
            title: <FormattedMessage id="taskLink.audit.updated" />,
            dataIndex: 'auditUpdated',
            key: 'updated',
            sorter: true,
            defaultSortOrder: 'descend',
            width: 240,
            align: 'center',
            render: (value: string, taskSyncLink: TaskSyncLink) => (
                <Link to={`/task-sync-links/${taskSyncLink.id}`}>
                    <FormattedDate
                        value={taskSyncLink.audit!.updated as any}
                        day="2-digit"
                        month="2-digit"
                        year="numeric"
                        hour="2-digit"
                        minute="2-digit"
                        second="2-digit"
                    />
                </Link>
            ),
        },
        {
            title: <FormattedMessage id="taskLink.status" />,
            dataIndex: 'status',
            key: 'status',
            sorter: true,
            width: 200,
            align: 'center',
            render: (status: TaskStatusType, taskSyncLink: TaskSyncLink) => (
                <Link to={`/task-sync-links/${taskSyncLink.id}`}>
                    <TaskStatusComponent status={status} />
                </Link>
            ),
        },
    ];

    return (
        <LayoutComponent pageId="tasks">
            <Descriptions
                title={
                    <>
                        <span className={styles.title}>
                            #{task?.id} - {task?.name}
                        </span>
                        <TaskStatusComponent status={task?.status} />
                        <Space>
                            <Tooltip title={<FormattedMessage id="button.back" />}>
                                <Link to="/tasks">
                                    <Button type="ghost" size="small" icon={<Icon component={BackSvg} />} />
                                </Link>
                            </Tooltip>
                            <Tooltip title={<FormattedMessage id="button.refresh" />}>
                                <Button
                                    type="ghost"
                                    size="small"
                                    icon={<Icon component={RefreshSvg} />}
                                    onClick={list}
                                />
                            </Tooltip>
                        </Space>
                    </>
                }
                column={2}
                size="small"
                className={styles.summary}
            >
                <Descriptions.Item label={<FormattedMessage id="task.source" />}>
                    {task?.source?.provider} &gt; {task?.source?.name}
                </Descriptions.Item>
                <Descriptions.Item label={<FormattedMessage id="task.target" />}>
                    {task?.target?.provider} &gt; {task?.target?.name}
                </Descriptions.Item>
                <Descriptions.Item label={<FormattedMessage id="task.audit.created" />}>
                    <FormattedDate
                        value={task?.audit?.created as any}
                        day="2-digit"
                        month="2-digit"
                        year="numeric"
                        hour="2-digit"
                        minute="2-digit"
                        second="2-digit"
                    />
                </Descriptions.Item>
                <Descriptions.Item label={<FormattedMessage id="task.audit.updated" />}>
                    <FormattedDate
                        value={task?.audit?.updated as any}
                        day="2-digit"
                        month="2-digit"
                        year="numeric"
                        hour="2-digit"
                        minute="2-digit"
                        second="2-digit"
                    />
                </Descriptions.Item>
                <Descriptions.Item span={2}>{task?.description}</Descriptions.Item>
            </Descriptions>
            <TaskSyncStatusComponent taskSync={task} taskSyncFileStatuses={taskSyncFileStatuses} onRetry={list} />
            <div className="toolbar">
                <Search placeholder={placeholder} onSearch={search} size="large" className="search" />
            </div>
            <Table
                dataSource={items}
                columns={columns}
                pagination={tableService.createPagination(taskSyncLinksPage, { position: ['bottomRight'] })}
                rowKey="id"
                onChange={paginate}
                sortDirections={['ascend', 'descend']}
                showSorterTooltip={false}
                loading={loading}
            />
        </LayoutComponent>
    );
};

export default TaskSyncPage;

type ParamsType = { id: string };
