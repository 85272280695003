import { ConfigProvider, message } from 'antd';
import enGB from 'antd/lib/locale/en_GB';
import countries from 'i18n-iso-countries';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import './App.less';
import memberApi from './api/MemberApi';
import LoadingComponent from './components/LoadingComponent/LoadingComponent';
import RoutesComponent from './components/RoutesComponent/RoutesComponent';
import configuration from './configs/IConfiguration';
import CustomContext from './context/CustomContext';
import messagesEn from './i18n/en.json';
import { CustomAuth, Member } from './model/entities';
import { ApplicationType, deepLinkAllowedPaths } from './model/types';
import authService from './security/IAuthService';
import autodeskService from './services/AutodeskService';
import mioAdminSettingService from './services/MioAdminSettingService';
import sharePointService from './services/SharePointService';
import stringService from './services/StringService';
import procoreService from './services/ProcoreService';

/**
 * Returns the app.
 * @returns the app.
 */
const App: React.FC = () => {
    /*** HOOKS ***/

    // create context
    const [application, updateApplication] = useState<ApplicationType>();
    const [auth, updateAuth] = useState<CustomAuth>();
    const context = { auth, updateAuth, application, updateApplication };
    const [initialized, setInitialized] = useState<boolean>(false);

    // initialize app
    useEffect(() => {
        const init = async () => {
            try {
                // set-up antd configuration
                message.config({
                    maxCount: 1,
                });

                // set-up locale
                moment.locale('en');
                countries.registerLocale(require('i18n-iso-countries/langs/en.json'));

                // store organization
                storeOrganization();

                // set-up current auth
                let auth = await authService.getAuth();

                // if organization is not selected, select first organization
                if (auth && !auth.organization && auth.organizations.length > 0) {
                    const organization = auth.organizations.sort((a, b) => stringService.sort(a.name, b.name))[0];
                    auth = await authService.getAuthByOrganization(organization);
                }

                // we get the autodesk access token
                let autodeskAccessToken: string | undefined;
                if (auth && auth.organization && auth.organization.id) {
                    await mioAdminSettingService.init(auth.organization.id);
                    autodeskAccessToken = await autodeskService.init();
                    await sharePointService.init();
                    await procoreService.init();
                    auth.members = await loadCurrentMembers();
                }
                updateAuth(auth);

                // store deep link
                storeDeeplink(auth);

                if (autodeskAccessToken || (auth && auth.id === '')) {
                    setInitialized(true);
                }
            } catch (error) {
                console.log(error);
                message.error('Internal error');
            }
        };
        init();
    }, []);

    /*** METHODS ***/

    const storeDeeplink = (auth?: CustomAuth) => {
        if (
            !auth &&
            window.location.pathname &&
            deepLinkAllowedPaths.some((p) => window.location.pathname.startsWith(`/${p}`))
        ) {
            sessionStorage.setItem('deeplink', window.location.pathname + window.location.search);
        }
    };

    const storeOrganization = () => {
        try {
            const params = new URLSearchParams(window.location.search);
            const organizationId = params.get('organizationId') || undefined;
            if (organizationId) {
                localStorage.setItem('organizationId', organizationId);
            }
        } catch (error) {
            // the first organization will be selected
        }
    };

    const loadCurrentMembers = async (): Promise<Member[]> => {
        let members: Member[] = [];
        try {
            if (autodeskService.getCachedAccessToken()) {
                members = await memberApi.getCurrentMembers();
            }
        } catch (error) {
            // access token has expired, a new one will be generated
        }

        return members;
    };

    /*** VISUAL ***/

    return (
        <div className={configuration.getTenant() === 'cellnex' ? 'cellnex' : undefined} style={{ height: '100%' }}>
            <ConfigProvider locale={enGB}>
                <CustomContext.Provider value={context}>
                    <IntlProvider locale="en-GB" defaultLocale="en-GB" messages={messagesEn}>
                        {initialized ? <RoutesComponent /> : <LoadingComponent />}
                    </IntlProvider>
                </CustomContext.Provider>
            </ConfigProvider>
        </div>
    );
};
export default App;
