import { Steps } from 'antd';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import LayoutComponent from '../../../../components/LayoutComponent/LayoutComponent';
import { EntityWithStatus, Member } from '../../../../model/entities';
import notificationService from '../../../../services/NotificationService';
import styles from './ImportPage.module.scss';
import ResultComponent from './ResultComponent/ResultComponent';
import SummaryComponent from './SummaryComponent/SummaryComponent';
import UploadComponent from './UploadComponent/UploadComponent';

/**
 * Returns the import page.
 * @returns the import page
 */
const ImportPage = (): React.ReactElement => {
    /*** HOOKS ***/
    const intl = useIntl();
    const [step, setStep] = useState<number>(0);
    const [members, setMembers] = useState<Member[]>([]);
    const [result, setResult] = useState<EntityWithStatus<Member>[]>([]);
    const [hubId, setHubId] = useState<string>();

    /*** EFFECTS ***/

    useEffect(() => {
        const init = async () => {
            try {
                const params = new URLSearchParams(window.location.search);
                const hubId = params.get('hubId') || undefined;

                setHubId(hubId);
            } catch (error) {
                notificationService.displayError(error, intl);
            }
        };
        init();
    }, [intl]);

    /*** METHODS ***/

    const showMembers = (members: Member[]) => {
        setMembers(members);
        setStep(1);
    };

    const showResults = (result: EntityWithStatus<Member>[]) => {
        setResult(result);
        setStep(2);
    };

    /*** COMPONENTS */

    return (
        <LayoutComponent pageId="members">
            {
                <>
                    <h1 className={styles.title}>
                        <FormattedMessage id="members.import.title" />
                    </h1>
                    <Steps
                        current={step}
                        className={styles.steps}
                        items={[
                            { title: <FormattedMessage id="members.import.steps.1" /> },
                            { title: <FormattedMessage id="members.import.steps.2" /> },
                            { title: <FormattedMessage id="members.import.steps.3" /> },
                        ]}
                    />
                    <div hidden={step !== 0}>
                        {hubId && <UploadComponent onLoadMembers={showMembers} hubId={hubId} changeHubId={setHubId} />}
                    </div>
                    {step === 1 && hubId && (
                        <SummaryComponent
                            members={members}
                            onSaveMembers={showResults}
                            onBack={() => setStep(step - 1)}
                            hubId={hubId}
                        />
                    )}
                    {step === 2 && result && <ResultComponent result={result} />}
                </>
            }
        </LayoutComponent>
    );
};

export default ImportPage;
