import Icon from '@ant-design/icons';
import { Button, Descriptions, Space, Table, Tag, Tooltip } from 'antd';
import Search from 'antd/lib/input/Search';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import React, { useEffect, useState } from 'react';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { Link, useParams } from 'react-router-dom';
import taskProjectIssueActionApi from '../../../../api/TaskProjectIssueActionApi';
import taskProjectIssuesApi from '../../../../api/TaskProjectIssueApi';
import LayoutComponent from '../../../../components/LayoutComponent/LayoutComponent';
import TaskStatusComponent from '../../../../components/TaskStatusComponent/TaskStatusComponent';
import { Page } from '../../../../model/elements';
import { TaskProjectIssue, TaskProjectIssueAction } from '../../../../model/entities';
import { TaskStatusType } from '../../../../model/types';
import { ReactComponent as BackSvg } from '../../../../resources/images/back.svg';
import { ReactComponent as RefreshSvg } from '../../../../resources/images/refresh.svg';
import notificationService from '../../../../services/NotificationService';
import tableService from '../../../../services/TableService';
import ProjectIssueStatusComponent from '../../../Setup/ProjectIssuesPage/ProjectIssueStatusComponent/ProjectIssueStatusComponent';
import styles from './TasksProjectIssuesPage.module.scss';

/**
 * Returns the tasks project issues page.
 * @returns the tasks project issues page.
 */
const TasksProjectIssuesPage = (): React.ReactElement => {
    /*** HOOKS ***/

    const intl = useIntl();
    const [task, setTask] = useState<TaskProjectIssue>();
    const [taskProjectIssueActionPage, setTaskProjectIssueActionPage] = useState<Page<TaskProjectIssueAction>>();
    const [loading, setLoading] = useState<boolean>();
    const [searchText, setSearchText] = useState<string>();
    const params = useParams<ParamsType>();

    /*** EFFECTS ***/

    useEffect(() => {
        const init = async () => {
            try {
                setLoading(true);

                const task = await taskProjectIssuesApi.get(+params.id);
                setTask(task);

                const page = 0;
                const size = taskProjectIssueActionPage?.size || tableService.pageSize;
                const sortField = taskProjectIssueActionPage?.sort.field || 'title';
                const sortOrder = taskProjectIssueActionPage?.sort.order || true;
                await listTaskProjectIssueActions(page, size, sortField, sortOrder, task.id!, searchText);
            } catch (error) {
                notificationService.displayError(error, intl);
            } finally {
                setLoading(false);
            }
        };
        init();
    }, [
        intl,
        params.id,
        searchText,
        taskProjectIssueActionPage?.size,
        taskProjectIssueActionPage?.sort.field,
        taskProjectIssueActionPage?.sort.order,
    ]);

    /*** METHODS ***/

    const list = async () => {
        try {
            setLoading(true);
            const task = await taskProjectIssuesApi.get(+params.id);
            setTask(task);

            const page = 0;
            const size = taskProjectIssueActionPage?.size || tableService.pageSize;
            const sortField = taskProjectIssueActionPage?.sort.field || 'title';
            const sortOrder = taskProjectIssueActionPage?.sort.order || true;
            await listTaskProjectIssueActions(page, size, sortField, sortOrder, task.id!, searchText);
        } catch (error) {
            notificationService.displayError(error, intl);
        } finally {
            setLoading(false);
        }
    };

    const paginate = async (pagination: TablePaginationConfig, filters: any, sorter: any) => {
        try {
            setLoading(true);
            const page = pagination.current! - 1;
            const pageSize = pagination.pageSize!;
            const sortField = sorter.field;
            const sortOrder = sorter.order === 'ascend';
            listTaskProjectIssueActions(page, pageSize, sortField, sortOrder, task!.id!, searchText);
        } catch (error) {
            notificationService.displayError(error, intl);
        } finally {
            setLoading(false);
        }
    };

    const search = async (searchText: string) => {
        try {
            setLoading(true);
            setSearchText(searchText);
            const page = 0;
            const pageSize = taskProjectIssueActionPage!.size;
            const sortField = taskProjectIssueActionPage!.sort.field!;
            const sortOrder = taskProjectIssueActionPage!.sort.order!;
            listTaskProjectIssueActions(page, pageSize, sortField, sortOrder, task!.id!, searchText);
        } catch (error) {
            notificationService.displayError(error, intl);
        } finally {
            setLoading(false);
        }
    };

    const listTaskProjectIssueActions = async (
        page: number,
        size: number,
        sortField: string,
        sortOrder: boolean,
        taskId: number,
        searchText?: string,
    ) => {
        const taskProjectIssueActionPage = await taskProjectIssueActionApi.list(
            page,
            size,
            sortField,
            sortOrder,
            taskId,
            searchText,
        );
        setTaskProjectIssueActionPage(taskProjectIssueActionPage);
    };

    /*** COMPONENTS ***/

    const items = taskProjectIssueActionPage ? taskProjectIssueActionPage.content : [];
    const columns: ColumnsType<TaskProjectIssueAction> = [
        {
            title: <FormattedMessage id="taskProjectIssue.title" />,
            dataIndex: 'title',
            key: 'title',
            sorter: true,
        },
        {
            title: <FormattedMessage id="taskProjectIssue.targetProject" />,
            dataIndex: 'targetProject.name',
            key: 'targetProject.name',
            sorter: true,
            width: 280,
            render: (value: string, taskProjectIssueAction: TaskProjectIssueAction) =>
                taskProjectIssueAction.targetProject?.name,
        },
        {
            title: <FormattedMessage id="taskProjectIssue.action" />,
            dataIndex: 'action',
            key: 'action',
            width: 200,
            align: 'center',
            render: (action: any, taskProjectIssueAction: TaskProjectIssueAction) => (
                <ProjectIssueStatusComponent status={taskProjectIssueAction.action.status} />
            ),
        },
        {
            title: <FormattedMessage id="taskProjectIssue.audit.created" />,
            dataIndex: 'auditCreated',
            key: 'created',
            sorter: true,
            width: 240,
            align: 'center',
            render: (value: string, taskProjectIssueAction: TaskProjectIssueAction) => (
                <FormattedDate
                    value={taskProjectIssueAction.audit!.created as any}
                    day="2-digit"
                    month="2-digit"
                    year="numeric"
                    hour="2-digit"
                    minute="2-digit"
                    second="2-digit"
                />
            ),
        },
        {
            title: <FormattedMessage id="taskProjectIssue.status" />,
            dataIndex: 'status',
            key: 'status',
            sorter: true,
            width: 200,
            align: 'center',
            render: (status: TaskStatusType, taskProjectIssueAction: TaskProjectIssueAction) => (
                <TaskStatusComponent status={status} error={taskProjectIssueAction.error} />
            ),
        },
        {
            title: <FormattedMessage id="taskProjectIssue.typeCopied" />,
            dataIndex: 'isTypeCreated',
            key: 'isTypeCreated',
            sorter: true,
            width: 200,
            align: 'center',
            render: (isTypeCreated: boolean) =>
                isTypeCreated ? (
                    <Tag color="green">
                        <FormattedMessage id="button.yes" />
                    </Tag>
                ) : (
                    <Tag color="geekblue">
                        <FormattedMessage id="taskProjectIssue.alreadyExisted" />
                    </Tag>
                ),
        },
        {
            title: <FormattedMessage id="taskProjectIssue.commentsStatus" />,
            dataIndex: 'commentsStatus',
            key: 'commentsStatus',
            sorter: true,
            width: 200,
            align: 'center',
            render: (commentsStatus: TaskStatusType) => <TaskStatusComponent status={commentsStatus} />,
        },
        {
            title: <FormattedMessage id="taskProjectIssue.attachmentsStatus" />,
            dataIndex: 'attachmentsStatus',
            key: 'attachmentsStatus',
            sorter: true,
            width: 200,
            align: 'center',
            render: (attachmentsStatus: TaskStatusType, taskProjectIssueAction: TaskProjectIssueAction) => (
                <Link to={`/task-project-issue-actions/${taskProjectIssueAction.id}`}>
                    <TaskStatusComponent status={attachmentsStatus} />
                </Link>
            ),
        },
    ];

    const placeholder: string = intl.formatMessage({ id: 'taskProjectIssues.search' });

    return (
        <LayoutComponent pageId="tasks">
            <Descriptions
                title={
                    <>
                        <span className={styles.title}>
                            #{task?.id} - {task?.name}
                        </span>
                        <TaskStatusComponent status={task?.status} />
                        <Space>
                            <Tooltip title={<FormattedMessage id="button.back" />}>
                                <Link to="/tasks">
                                    <Button type="ghost" size="small" icon={<Icon component={BackSvg} />} />
                                </Link>
                            </Tooltip>
                            <Tooltip title={<FormattedMessage id="button.refresh" />}>
                                <Button
                                    type="ghost"
                                    size="small"
                                    icon={<Icon component={RefreshSvg} />}
                                    onClick={list}
                                />
                            </Tooltip>
                        </Space>
                    </>
                }
                column={2}
                size="small"
                className={styles.summary}
            >
                <Descriptions.Item label={<FormattedMessage id="task.source" />}>
                    {task?.sourceHub?.name} &gt; {task?.sourceProject?.name}
                </Descriptions.Item>
                <br></br>
                <Descriptions.Item label={<FormattedMessage id="task.audit.created" />}>
                    <FormattedDate
                        value={task?.audit?.created as any}
                        day="2-digit"
                        month="2-digit"
                        year="numeric"
                        hour="2-digit"
                        minute="2-digit"
                        second="2-digit"
                    />
                </Descriptions.Item>
                <Descriptions.Item label={<FormattedMessage id="task.audit.updated" />}>
                    <FormattedDate
                        value={task?.audit?.updated as any}
                        day="2-digit"
                        month="2-digit"
                        year="numeric"
                        hour="2-digit"
                        minute="2-digit"
                        second="2-digit"
                    />
                </Descriptions.Item>
                <Descriptions.Item>{task?.description}</Descriptions.Item>
            </Descriptions>
            <div className="toolbar">
                <Search placeholder={placeholder} onSearch={search} size="large" className="search" />
            </div>
            <Table
                dataSource={items}
                columns={columns}
                pagination={tableService.createPagination(taskProjectIssueActionPage, { position: ['bottomRight'] })}
                rowKey="id"
                onChange={paginate}
                sortDirections={['ascend', 'descend']}
                showSorterTooltip={false}
                loading={loading}
            />
        </LayoutComponent>
    );
};

export default TasksProjectIssuesPage;

type ParamsType = { id: string };
