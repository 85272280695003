import { IConfiguration, Platform, Tenant } from './IConfiguration';

export default class CellnexConfiguration implements IConfiguration {
    // domains of environments (hardcoded - react doesn't allow changes on runtime)
    readonly irisTestDomain = 'iris.modelical.io';
    readonly irisCellnexDevDomain = 'delightfulbush-06ff8246.westeurope.azurecontainerapps.io';

    // configuration attributes
    auth: any | undefined;
    parentUrl: string | undefined;
    adminMicroserviceUrl: string;
    proxyMicroserviceUrl: string;
    syncMicroserviceUrl: string;
    mioAdminUrl: string;
    autodeskClientId: string;
    parentDomain: string;
    cookiesSubfix: string;
    procoreUrl: string;

    constructor() {
        // set up configuration attributes based on environment
        if (window.location.hostname.endsWith(this.irisCellnexDevDomain)) {
            this.auth = {
                auth: {
                    clientId: '3aedca30-d8a5-4bcd-ba80-f5e1aeaf9f5f',
                    authority: 'https://login.microsoftonline.com/05088b6e-1d96-4f6b-8918-48957eca4f00',
                    redirectUri: `https://azr-clx-dev-dtw-ca-11.${this.irisCellnexDevDomain}`,
                    postLogoutRedirectUri: `https://azr-clx-dev-dtw-ca-11.${this.irisCellnexDevDomain}`,
                    navigateToLoginRequestUrl: true,
                    OIDCOptions: {
                        defaultScopes: ['3aedca30-d8a5-4bcd-ba80-f5e1aeaf9f5f/.default'],
                    },
                },
                cache: {
                    cacheLocation: 'localStorage',
                    storeAuthStateInCookie: false,
                },
            };

            this.parentUrl = `http://iris-dev.cellnextelecom.com`;
            this.adminMicroserviceUrl = `https://azr-clx-dev-dtw-ca-09.${this.irisCellnexDevDomain}`;
            this.proxyMicroserviceUrl = `https://azr-clx-dev-dtw-ca-07.${this.irisCellnexDevDomain}`;
            this.syncMicroserviceUrl = `https://azr-clx-dev-dtw-ca-04.${this.irisCellnexDevDomain}`;
            this.mioAdminUrl = `https://azr-clx-dev-dtw-ca-08.${this.irisCellnexDevDomain}`;
            this.autodeskClientId = '5IAGyHgAlM5GoJgrfuB7ZY9CZjlFpBqKD88gxfOVnmotqBZQ';
            this.parentDomain = 'delightfulbush-06ff8246.westeurope.azurecontainerapps.io';
            this.cookiesSubfix = 'DevIrisCellnex';
            this.procoreUrl = 'https://login-sandbox.procore.com';
        } else {
            this.auth = {
                auth: {
                    clientId: 'eed14693-0040-4f6d-89bc-22b436918ef6',
                    authority: 'https://login.microsoftonline.com/d8c21a0f-8e79-48d2-9314-075f557b317d',
                    redirectUri: `https://link.${this.irisTestDomain}`,
                    postLogoutRedirectUri: `https://link.${this.irisTestDomain}`,
                    navigateToLoginRequestUrl: true,
                    OIDCOptions: {
                        defaultScopes: ['eed14693-0040-4f6d-89bc-22b436918ef6/.default'],
                    },
                },
                cache: {
                    cacheLocation: 'localStorage',
                    storeAuthStateInCookie: false,
                },
            };

            this.parentUrl = `https://iris.modelical.io`;
            this.adminMicroserviceUrl = `https://admin.link.${this.irisTestDomain}`;
            this.proxyMicroserviceUrl = `https://proxy.link.${this.irisTestDomain}`;
            this.syncMicroserviceUrl = `https://sync.link.${this.irisTestDomain}`;
            this.mioAdminUrl = `https://admin.api.${this.irisTestDomain}`;
            this.autodeskClientId = '5IAGyHgAlM5GoJgrfuB7ZY9CZjlFpBqKD88gxfOVnmotqBZQ';
            this.parentDomain = 'iris.modelical.io';
            this.cookiesSubfix = 'IrisModelicalIo';
            this.procoreUrl = 'https://login-sandbox.procore.com';
        }
    }

    getPlatform = (): Platform => {
        return 'azure';
    };

    getAuth = (): any | undefined => {
        return this.auth;
    };

    getParentUrl = (): string | undefined => {
        return this.parentUrl;
    };

    getAdminMicroserviceUrl = (): string => {
        return this.adminMicroserviceUrl;
    };

    getProxyMicroserviceUrl = (): string => {
        return this.proxyMicroserviceUrl;
    };

    getSyncMicroserviceUrl = (): string => {
        return this.syncMicroserviceUrl;
    };

    getMioAdminUrl = (): string => {
        return this.mioAdminUrl;
    };

    getAutodeskClientId = (): string => {
        return this.autodeskClientId;
    };

    getTenant = (): Tenant => {
        return 'cellnex';
    };

    getParentDomain = (): string => {
        return this.parentDomain;
    };

    getCookiesSubfix = (): string => {
        return this.cookiesSubfix;
    };

    getProcoreUrl = (): string => {
        return this.procoreUrl;
    };
}
