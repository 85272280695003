import Icon from '@ant-design/icons';
import { Button, Form, Modal, Space, Spin, Table } from 'antd';
import { ColumnType } from 'antd/lib/table';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import companyApi from '../../../../../api/CompanyApi';
import memberApi from '../../../../../api/MemberApi';
import { Company, EntityWithStatus, Member } from '../../../../../model/entities';
import { ReactComponent as CheckmarkSvg } from '../../../../../resources/images/checkmark.svg';
import { ReactComponent as CloseSvg } from '../../../../../resources/images/close-outline.svg';
import { ReactComponent as EditSvg } from '../../../../../resources/images/edit.svg';
import { ReactComponent as SaveSvg } from '../../../../../resources/images/save.svg';
import notificationService from '../../../../../services/NotificationService';
import EditableCell from './EditableCell/EditableCell';
import styles from './SummaryComponent.module.scss';

/**
 * Returns the members page import summary component.
 * @props the props
 * @returns the members page import summary component.
 */
const SummaryComponent = (props: Props): React.ReactElement => {
    const { hubId, members, onBack, onSaveMembers } = props;
    /*** HOOKS ***/

    const [form] = Form.useForm();
    const intl = useIntl();
    const [membersUpdated, setMembersUpdated] = useState<Member[]>([]);
    const [companies, setCompanies] = useState<Company[]>([]);
    const [saving, setSaving] = useState<boolean>();
    const [editingKey, setEditingKey] = useState<string>();

    /*** EFFECTS ***/

    useEffect(() => {
        const init = async () => {
            try {
                const membersUpdated = [...members];
                const companies = await companyApi.list(hubId);
                setMembersUpdated(membersUpdated);
                setCompanies(companies);
            } catch (error) {
                notificationService.displayError(error, intl);
            }
        };
        init();
    }, [hubId, intl, members]);

    /*** METHODS ***/

    const upload = async () => {
        try {
            setSaving(true);

            const result = await memberApi.import(membersUpdated, hubId);

            onSaveMembers(result);
        } catch (error) {
            notificationService.displayError(error, intl);
        } finally {
            setSaving(false);
        }
    };

    const isEditing = (record: Member) => {
        return record.email === editingKey;
    };

    const edit = (record: Member) => {
        form.setFieldsValue({
            id: undefined,
            email: '',
            firstName: '',
            lastName: '',
            company: '',
            bim360: undefined,
            ...record,
        });
        setEditingKey(record.email);
    };

    const save = async (key: React.Key) => {
        try {
            const row = (await form.validateFields()) as Member;

            const membersUpdated = [...members];
            const index = membersUpdated.findIndex((item) => key === item.email);
            if (index > -1) {
                const item = membersUpdated[index];
                membersUpdated.splice(index, 1, {
                    ...item,
                    ...row,
                });
                setMembersUpdated(membersUpdated);
                setEditingKey(undefined);
            } else {
                members.push(row);
                setMembersUpdated(membersUpdated);
                setEditingKey(undefined);
            }
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };

    /*** COMPONENTS ***/

    const columns: EditableColumnsType<Member> = [
        {
            title: <FormattedMessage id="member.email" />,
            dataIndex: 'email',
            key: 'email',
            editable: true,
        },
        {
            title: <FormattedMessage id="member.firstName" />,
            dataIndex: 'firstName',
            key: 'firstName',
            editable: true,
        },
        {
            title: <FormattedMessage id="member.lastName" />,
            dataIndex: 'lastName',
            key: 'lastName',
            editable: true,
        },
        {
            title: <FormattedMessage id="member.company" />,
            dataIndex: ['company', 'id'],
            key: 'company',
            editable: true,
            width: 200,
            render: (value: string) => companies.find((c) => c.id === value)?.name,
        },
        {
            title: <FormattedMessage id="member.bim360" />,
            dataIndex: 'bim360',
            key: 'bim360',
            align: 'center',
            width: 120,
            render: (value: string) => value && <FormattedMessage id={value} />,
        },
        {
            dataIndex: 'action',
            align: 'center',
            width: 100,
            render: (value: any, record: Member) => {
                const editable = isEditing(record);
                return editable ? (
                    <Space size={0}>
                        <Button
                            type="link"
                            size="large"
                            icon={<Icon component={CheckmarkSvg} />}
                            onClick={() => save(record.email!)}
                        />
                        <Button
                            type="link"
                            size="large"
                            icon={<Icon component={CloseSvg} />}
                            onClick={() => setEditingKey(undefined)}
                        />
                    </Space>
                ) : (
                    <Button
                        type="link"
                        size="large"
                        icon={<Icon component={EditSvg} />}
                        disabled={!!editingKey}
                        onClick={() => edit(record)}
                    />
                );
            },
        },
    ];

    const mergedColumns: any = columns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record: Member) => ({
                record,
                identifier: col.key,
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
                companies: companies,
            }),
        };
    });

    return (
        <>
            <Form form={form} component={false}>
                <Table
                    components={{
                        body: {
                            cell: EditableCell,
                        },
                    }}
                    dataSource={membersUpdated}
                    columns={mergedColumns}
                    rowKey="email"
                    sortDirections={['ascend']}
                    showSorterTooltip={false}
                    rowClassName="editable-row"
                    pagination={{
                        onChange: () => setEditingKey(undefined),
                        size: 'small',
                        hideOnSinglePage: true,
                    }}
                />
            </Form>
            <Space className={styles.buttons}>
                <Button
                    type="primary"
                    size="large"
                    onClick={upload}
                    icon={<Icon component={SaveSvg} />}
                    loading={saving}
                >
                    <FormattedMessage id="button.save" tagName="span" />
                </Button>
                <Button type="text" size="large" onClick={onBack}>
                    <FormattedMessage id="button.back" tagName="span" />
                </Button>
            </Space>
            <ImportingModal saving={saving} />
        </>
    );
};

export default SummaryComponent;

interface Props {
    members: Member[];
    onSaveMembers: (result: EntityWithStatus<Member>[]) => void;
    onBack: () => void;
    hubId: string;
}

type EditableColumnsType<RecordType = unknown> = EditableColumnType<RecordType>[];

interface EditableColumnType<RecordType> extends ColumnType<RecordType> {
    editable?: boolean;
}

const ImportingModal = (props: ModalProps): React.ReactElement => {
    const { saving } = props;

    return (
        <>
            <Modal
                title={
                    <span className={styles.head}>
                        <Spin size="small" /> <FormattedMessage id="members.import.steps.2.importing.title" />
                    </span>
                }
                open={saving}
                footer={null}
                closable={false}
                className={styles.modal}
            >
                <p className={styles.body}>
                    <FormattedMessage id="members.import.steps.2.importing.description" />
                </p>
            </Modal>
        </>
    );
};

interface ModalProps {
    saving?: boolean;
}
