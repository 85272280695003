import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import autodeskService from '../services/AutodeskService';
import authService from '../security/IAuthService';
import configuration from '../configs/IConfiguration';
import sharePointService from '../services/SharePointService';
import procoreService from '../services/ProcoreService';

// urls for each microservice
export const adminApiBaseURL = configuration.getAdminMicroserviceUrl();
export const proxyApiBaseURL = configuration.getProxyMicroserviceUrl();
export const syncApiBaseURL = configuration.getSyncMicroserviceUrl();

/****** RESOURCES APIS ***/

// request interceptor
const axiosRequestInterceptor = async (
    config: AxiosRequestConfig,
): Promise<AxiosRequestConfig | Promise<AxiosRequestConfig>> => {
    let accessToken: string | undefined;
    try {
        accessToken = await authService.getAccessToken();
    } catch (error) {
        accessToken = undefined;
    }

    const auth = authService.getCachedAuth();
    const organizationId = auth && auth.organization ? auth.organization.id : undefined;
    const autodeskToken = await autodeskService.getAccessToken();
    const sharepointToken = await sharePointService.getAccessToken();
    const procoreToken = await procoreService.getAccessToken();

    let headers = { ...config.headers };
    if (accessToken) {
        headers['Authorization'] = `Bearer ${accessToken}`;
    }
    if (autodeskToken) {
        headers['Autodesk-Access-Token'] = autodeskToken;
    }
    if (sharepointToken) {
        headers['Microsoft-Graph-Access-Token'] = sharepointToken;
    }
    if (procoreToken) {
        headers['Procore-Access-Token'] = procoreToken;
    }
    if (organizationId) {
        headers['Organization-Id'] = organizationId;
    }

    return {
        ...config,
        headers,
    };
};

// basic request interceptor
const basicAxiosRequestInterceptor = async (
    config: AxiosRequestConfig,
): Promise<AxiosRequestConfig | Promise<AxiosRequestConfig>> => {
    let accessToken: string | undefined;
    try {
        accessToken = await authService.getAccessToken();
    } catch (error) {
        accessToken = undefined;
    }

    const organizationId = authService.getOrganizationId();

    let headers = { ...config.headers };
    if (accessToken) {
        headers['Authorization'] = `Bearer ${accessToken}`;
    }
    if (organizationId) {
        headers['Organization-Id'] = organizationId;
    }

    return {
        ...config,
        headers,
    };
};

/*** ADMIN API ***/

// config
const adminApiAxiosConfig: AxiosRequestConfig = {
    baseURL: adminApiBaseURL,
};

// create axios http client
const adminApiAxios: AxiosInstance = axios.create(adminApiAxiosConfig);
adminApiAxios.interceptors.request.use(axiosRequestInterceptor);
export default adminApiAxios;

// create basic axios http client
const basicAdminApiAxiosInstance: AxiosInstance = axios.create(adminApiAxiosConfig);
basicAdminApiAxiosInstance.interceptors.request.use(basicAxiosRequestInterceptor);
export const basicAdminApiAxios = basicAdminApiAxiosInstance;

/*** PROXY API ***/

// config
const proxyApiAxiosConfig: AxiosRequestConfig = {
    baseURL: proxyApiBaseURL,
};

// create axios http client
const proxyApiAxiosInstance: AxiosInstance = axios.create(proxyApiAxiosConfig);
proxyApiAxiosInstance.interceptors.request.use(axiosRequestInterceptor);
export const proxyApiAxios = proxyApiAxiosInstance;

// create basic axios http client
const basicProxyApiAxiosInstance: AxiosInstance = axios.create(proxyApiAxiosConfig);
basicProxyApiAxiosInstance.interceptors.request.use(basicAxiosRequestInterceptor);
export const basicProxyApiAxios = basicProxyApiAxiosInstance;

/*** SYNC API ***/

// config
const syncApiAxiosConfig: AxiosRequestConfig = {
    baseURL: syncApiBaseURL,
};

// create axios http client
const syncApiAxiosInstance: AxiosInstance = axios.create(syncApiAxiosConfig);
syncApiAxiosInstance.interceptors.request.use(axiosRequestInterceptor);
export const syncApiAxios = syncApiAxiosInstance;
