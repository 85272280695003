import Icon, { FileExcelOutlined, RightCircleFilled } from '@ant-design/icons';
import { Button, Col, Divider, Dropdown, Row, Select, Space, Tag, Tooltip, message } from 'antd';
import Search from 'antd/lib/input/Search';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import { Key, TableRowSelection } from 'antd/lib/table/interface';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import hubApi from '../../../api/HubApi';
import locationApi from '../../../api/LocationApi';
import projectApi from '../../../api/ProjectApi';
import projectIssueApi from '../../../api/ProjectIssueApi';
import projectIssueRootCauseApi from '../../../api/ProjectIssueRootCauseApi';
import projectIssueTypeApi from '../../../api/ProjectIssueTypeApi';
import LayoutComponent from '../../../components/LayoutComponent/LayoutComponent';
import SelectTableComponent from '../../../components/SelectTableComponent/SelectTableComponent';
import { Page } from '../../../model/elements';
import {
    Hub,
    Location,
    Member,
    Project,
    ProjectIssue,
    ProjectIssueCustomAttribute,
    ProjectIssueFilter,
    ProjectIssueRootCause,
    ProjectIssueType,
} from '../../../model/entities';
import {
    ProjectIssueAccStatus,
    ProjectIssueBim360Status,
    projectIssueAccStatuses,
    projectIssueBim360Statuses,
} from '../../../model/types';
import { ReactComponent as CaretDownSvg } from '../../../resources/images/caret-down.svg';
import { ReactComponent as CloudUploadSvg } from '../../../resources/images/cloud-upload.svg';
import notificationService from '../../../services/NotificationService';
import stringService from '../../../services/StringService';
import tableService from '../../../services/TableService';
import CopyProjectIssuesModal from './CopyProjectIssuesModal/CopyProjectIssuesModal';
import ProjectIssueStatusComponent from './ProjectIssueStatusComponent/ProjectIssueStatusComponent';
import styles from './ProjectIssuesPage.module.scss';
import memberApi from '../../../api/MemberApi';
import debounce from 'lodash.debounce';

/**
 * Returns the project issues page.
 * @returns the project issues page.
 */
const ProjectIssuesPage = (): React.ReactElement => {
    const pageSize = 10;
    /*** HOOKS ***/

    const intl = useIntl();
    const [hubs, setHubs] = useState<Hub[]>([]);
    const [projects, setProjects] = useState<Project[]>([]);
    const [hub, setHub] = useState<Hub>();
    const [selectedProjectIds, setSelectedProjectIds] = useState<string[]>([]);
    const [types, setTypes] = useState<ProjectIssueType[]>([]);
    const [rootCauses, setRootCauses] = useState<ProjectIssueRootCause[]>([]);
    const [locations, setLocations] = useState<Location[]>([]);
    const [status, setStatus] = useState<ProjectIssueBim360Status | ProjectIssueAccStatus>();
    const [typeName, setTypeName] = useState<string>();
    const [rootCauseId, setRootCauseId] = useState<string>();
    const [projectIssuesPage, setProjectIssuesPage] = useState<Page<ProjectIssue>>();
    const [searchText, setSearchText] = useState<string>();
    const [loading, setLoading] = useState<'loading' | 'saving' | 'exporting' | 'loadingAssignees'>();
    const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
    const [selectedProjectIssues, setSelectedProjectIssues] = useState<ProjectIssue[]>([]);
    const [copyIssuesModalVisible, setCopyIssuesModalVisible] = useState<boolean>();
    const [statuses, setStatuses] = useState<readonly (ProjectIssueBim360Status | ProjectIssueAccStatus)[]>([]);
    const [assignees, setAssignees] = useState<Member[]>([]);
    const [selectedAssigneesIds, setSelectedAssigneesIds] = useState<string[]>([]);

    /*** EFFECTS ***/

    useEffect(() => {
        const init = async () => {
            try {
                // list hubs
                setLoading('loading');
                const hubs = await hubApi.list(true);
                setHubs(hubs);

                // list projects
                if (hubs.length > 0) {
                    const hub = hubs[0];
                    const responses = await Promise.all([projectApi.listUserProjects(hub.provider, hub.id)]);
                    const projects = responses[0];
                    setHub(hub);
                    setProjects(projects);
                }
            } catch (error) {
                notificationService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [intl]);

    /*** METHODS ***/

    const list = async (
        projectIds: string[],
        hub?: Hub,
        status?: ProjectIssueBim360Status | ProjectIssueAccStatus,
        typeName?: string,
        rootCauseId?: string,
        selectedAssigneesIds?: string[],
    ) => {
        // list project issues
        const page = 0;
        const size = pageSize;
        const sortField = projectIssuesPage?.sort.field || 'identifier';
        const sortOrder = projectIssuesPage?.sort.order || false;
        await listProjectIssues(
            page,
            size,
            sortField,
            sortOrder,
            projectIds,
            hub,
            status,
            typeName,
            rootCauseId,
            searchText,
            selectedAssigneesIds,
        );
    };

    const search = async (searchText: string) => {
        try {
            setLoading('loading');
            setSearchText(searchText);
            const page = 0;
            const size = pageSize;
            const sortField = projectIssuesPage!.sort.field!;
            const sortOrder = projectIssuesPage!.sort.order!;
            await listProjectIssues(
                page,
                size,
                sortField,
                sortOrder,
                selectedProjectIds,
                hub,
                status,
                typeName,
                rootCauseId,
                searchText,
                selectedAssigneesIds,
            );
        } catch (error) {
            notificationService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    const paginate = async (pagination: TablePaginationConfig, filters: any, sorter: any) => {
        try {
            setLoading('loading');
            const page = pagination.current! - 1;
            const size = pageSize;
            const sortField = sorter.field;
            const sortOrder = sorter.order === 'ascend';
            await listProjectIssues(
                page,
                size,
                sortField,
                sortOrder,
                selectedProjectIds,
                hub,
                status,
                typeName,
                rootCauseId,
                searchText,
                selectedAssigneesIds,
            );
        } catch (error) {
            notificationService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    const exportSpreadSheet = async () => {
        try {
            setLoading('exporting');

            const page = 0;
            const pageSize = 200; // to avoid too many requests
            const sortField = projectIssuesPage!.sort.field!;
            const sortOrder = projectIssuesPage!.sort.order!;
            const projectIssueFilter: ProjectIssueFilter = {
                hubId: hub?.id!,
                status: status,
                searchText: searchText,
                assigneeIds: selectedAssigneesIds,
                issueProjectFilters: selectedProjectIds.map((pid) => {
                    return {
                        projectId: pid,
                        rootCauseId: rootCauses.find(
                            (rootCause) => rootCause.id === rootCauseId && rootCause.projectId === pid,
                        )?.id,
                        issueTypeId: types.find((type) => type.title === typeName && type.projectId === pid)?.id,
                    };
                }),
            };
            await projectIssueApi.exportSpreadSheet(page, pageSize, sortField, sortOrder, projectIssueFilter);
            message.warning(intl.formatMessage({ id: 'projectIssues.exportSpreadSheetWarning' }));
        } catch (error) {
            notificationService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    const listProjectIssues = async (
        page: number,
        size: number,
        sortField: string,
        sortOrder: boolean,
        projectIds: string[],
        hub?: Hub,
        status?: ProjectIssueBim360Status | ProjectIssueAccStatus,
        typeName?: string,
        rootCauseId?: string,
        searchText?: string,
        selectedAssigneesIds?: string[],
    ) => {
        let projectIssuesPage: Page<ProjectIssue> | undefined;
        if (hub && projectIds.length > 0) {
            const projectIssueFilter: ProjectIssueFilter = {
                hubId: hub.id,
                status: status,
                searchText: searchText,
                assigneeIds: selectedAssigneesIds,
                issueProjectFilters: projectIds.map((pid) => {
                    return {
                        projectId: pid,
                        rootCauseId: rootCauses.find(
                            (rootCause) => rootCause.id === rootCauseId && rootCause.projectId === pid,
                        )?.id,
                        issueTypeId: types.find((type) => type.title === typeName && type.projectId === pid)?.id,
                    };
                }),
            };
            projectIssuesPage = await projectIssueApi.list(page, size, sortField, sortOrder, projectIssueFilter);
        }
        setProjectIssuesPage(projectIssuesPage);
    };

    const selectHub = async (hubId: string | undefined) => {
        try {
            setLoading('loading');
            const hub = hubs.find((h) => h.id === hubId);
            const projects = hub ? await projectApi.listUserProjects(hub.provider, hub.id) : [];
            const projectIds: string[] = [];
            const status = undefined;
            const typeName = undefined;
            const rootCauseId = undefined;
            const projectIssuesPage = undefined;
            let types: ProjectIssueType[] = [];
            let rootCauses: ProjectIssueRootCause[] = [];
            let locations: Location[] = [];
            let statuses: readonly (ProjectIssueBim360Status | ProjectIssueAccStatus)[] = Object.assign([]);
            const assignees: Member[] = [];
            const selectedAssigneesIds: string[] = [];

            setProjects(projects);
            setHub(hub);
            setSelectedProjectIds(projectIds);
            setStatus(status);
            setTypeName(typeName);
            setRootCauseId(rootCauseId);
            setProjectIssuesPage(projectIssuesPage);
            setTypes(types);
            setRootCauses(rootCauses);
            setLocations(locations);
            setStatuses(statuses);
            setAssignees(assignees);
            setSelectedAssigneesIds(selectedAssigneesIds);
        } catch (error) {
            notificationService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    const selectProjects = async (projectIds: string[]) => {
        try {
            setLoading('loading');
            let types: ProjectIssueType[] = [];
            let rootCauses: ProjectIssueRootCause[] = [];
            let locations: Location[] = [];
            let statuses: readonly (ProjectIssueBim360Status | ProjectIssueAccStatus)[] = Object.assign([]);
            const selectedProjects = projects.filter((project) => projectIds.includes(project.id));
            if (projectIds.length > 0) {
                const responses = await Promise.all([
                    projectIssueTypeApi.list(hub!.id, projectIds),
                    projectIssueRootCauseApi.list(hub!.id, projectIds),
                    locationApi.list(hub!.id, projectIds),
                ]);
                types = responses[0];
                rootCauses = responses[1];
                locations = responses[2];

                const bim360Statuses = selectedProjects.some((sp) => sp.platform === 'BIM360');
                const accStatuses = selectedProjects.some((sp) => sp.platform === 'ACC');
                if (bim360Statuses) {
                    statuses = projectIssueBim360Statuses;
                }
                if (accStatuses) {
                    statuses = statuses.concat(projectIssueAccStatuses);
                }

                statuses = statuses.filter((item, index) => statuses.indexOf(item) === index).sort();
            }
            const status = undefined;
            const typeName = undefined;
            const rootCauseId = undefined;
            unselectAll();
            setSelectedProjectIds(projectIds);
            setTypes(types);
            setRootCauses(rootCauses);
            setLocations(locations);
            setStatus(status);
            setTypeName(typeName);
            setRootCauseId(rootCauseId);
            setStatuses(statuses);

            await list(projectIds, hub, undefined, undefined, undefined, selectedAssigneesIds);
        } catch (error) {
            notificationService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    const selectStatus = async (status?: ProjectIssueBim360Status | ProjectIssueAccStatus) => {
        try {
            setLoading('loading');
            setStatus(status);

            await list(selectedProjectIds, hub, status, typeName, rootCauseId, selectedAssigneesIds);
        } catch (error) {
            notificationService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    const selectType = async (typeName?: string) => {
        try {
            setLoading('loading');
            setTypeName(typeName);

            await list(selectedProjectIds, hub, status, typeName, rootCauseId, selectedAssigneesIds);
        } catch (error) {
            notificationService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    const selectRootCause = async (rootCauseId?: string) => {
        try {
            setLoading('loading');
            setRootCauseId(rootCauseId);

            await list(selectedProjectIds, hub, status, typeName, rootCauseId, selectedAssigneesIds);
        } catch (error) {
            notificationService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    const selectAssigneesIds = async (selectedAssigneesIds: string[]) => {
        try {
            setLoading('loading');
            setSelectedAssigneesIds(selectedAssigneesIds);

            await list(selectedProjectIds, hub, status, typeName, rootCauseId, selectedAssigneesIds);
        } catch (error) {
            notificationService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    const searchAssignees = debounce(async (searchText: string) => {
        try {
            setLoading('loadingAssignees');
            let assignees: Member[] = [];
            if (searchText !== '') {
                const assigneesPage = await memberApi.list(0, 10, 'lastName', true, hub?.id!, searchText);
                assignees = assigneesPage.content;
            }
            setAssignees(assignees);
        } catch (error: any) {
            if (error.response.status === 403) {
                setAssignees([]);
            } else {
                notificationService.displayError(error, intl);
            }
        } finally {
            setLoading(undefined);
        }
    }, 1000);

    const select = (selectedRowKeys: Key[], selectedRows: ProjectIssue[]) => {
        setSelectedRowKeys(selectedRowKeys);
        setSelectedProjectIssues(selectedRows);
    };

    const unselectAll = () => {
        setSelectedRowKeys([]);
        setSelectedProjectIssues([]);
    };

    const isCopyButtonDisabled = (): boolean => {
        return selectedProjectIssues.length === 0;
    };

    /*** COMPONENTS ***/

    const hubOptions = hubs
        .sort((a, b) => stringService.sort(a.name, b.name))
        .map((hub) => (
            <Select.Option key={hub.id} value={hub.id}>
                {`${hub.provider} > ${hub.name}`}
            </Select.Option>
        ));
    const projectOptions = projects
        .sort((a, b) => stringService.sort(a.name, b.name))
        .map((project) => (
            <Select.Option key={project.id} value={project.id}>
                {project.name}
                {project.platform && (
                    <>
                        {' '}
                        <Tag>{project.platform}</Tag>
                    </>
                )}
            </Select.Option>
        ));
    const statusOptions = statuses.map((status: any) => (
        <Select.Option key={status} value={status}>
            <FormattedMessage id={`projectIssue.status.${status}`} />
        </Select.Option>
    ));
    const typeOptions = types
        .filter((value, index, self) => index === self.findIndex((t) => t.title === value.title))
        .sort((a, b) => stringService.sort(a.title, b.title))
        .map((type) => (
            <Select.Option key={type.title} value={type.title}>
                {type.title}
            </Select.Option>
        ));
    const rootCauseOptions = rootCauses
        .filter((value, index, self) => index === self.findIndex((t) => t.title === value.title))
        .sort((a, b) => stringService.sort(a.title, b.title))
        .map((rootCause) => (
            <Select.Option key={rootCause.id + rootCause.projectId} value={rootCause.id}>
                {rootCause.title}
            </Select.Option>
        ));
    const assigneesOptions = assignees.map((assignee) => (
        <Select.Option key={assignee.id} value={assignee.bim360AutodeskId}>
            {assignee.email}
        </Select.Option>
    ));

    const items = projectIssuesPage ? projectIssuesPage.content : [];
    const columns: ColumnsType<ProjectIssue> = [
        {
            title: <FormattedMessage id="projectIssue.identifier" />,
            dataIndex: 'identifier',
            key: 'identifier',
            sorter: true,
            defaultSortOrder: 'descend',
            width: 80,
            align: 'right',
            fixed: true,
            render: (value: number) => value,
        },
        {
            title: <FormattedMessage id="projectIssue.project" />,
            dataIndex: 'project',
            key: 'project',
            width: 250,
            sorter: true,
            fixed: true,
            render: (value: string, projectIssue: ProjectIssue) => projectIssue.project.name,
        },
        {
            title: <FormattedMessage id="projectIssue.status" />,
            dataIndex: 'status',
            key: 'status',
            sorter: true,
            width: 190,
            align: 'center',
            render: (value: ProjectIssueBim360Status | ProjectIssueAccStatus, project: ProjectIssue) => (
                <ProjectIssueStatusComponent status={value} platform={project.project.platform} />
            ),
        },
        {
            title: <FormattedMessage id="projectIssue.ngIssueTypeId" />,
            dataIndex: 'ngIssueTypeId',
            key: 'ngIssueTypeId',
            width: 190,
            render: (value: string) => types.find((p) => p.id === value)?.title,
        },
        {
            title: <FormattedMessage id="projectIssue.ngIssueSubtypeId" />,
            dataIndex: 'ngIssueSubtypeId',
            key: 'ngIssueSubtypeId',
            width: 190,
            render: (value: string) =>
                types.flatMap((type) => type.subtypes).find((subtype) => subtype.id === value)?.title,
        },
        {
            title: <FormattedMessage id="projectIssue.description" />,
            dataIndex: 'description',
            key: 'description',
            width: 190,
            ellipsis: { showTitle: true },
            render: (value: string) => value,
        },
        {
            title: <FormattedMessage id="projectIssue.assignedTo" />,
            dataIndex: 'assigned',
            key: 'assigned',
            width: 250,
            render: (value: string, projectIssue: ProjectIssue) =>
                projectIssue.assigned && projectIssue.assigned.firstName + ' ' + projectIssue.assigned.lastName,
        },
        {
            title: <FormattedMessage id="projectIssue.createdBy" />,
            dataIndex: 'createdBy',
            key: 'createdBy',
            width: 250,
            render: (value: string, projectIssue: ProjectIssue) =>
                projectIssue.createdBy && projectIssue.createdBy.firstName + ' ' + projectIssue.createdBy.lastName,
        },
        {
            title: <FormattedMessage id="projectIssue.placements" />,
            dataIndex: 'placements',
            key: 'placements',
            width: 190,
            render: (value: string, project: ProjectIssue) =>
                project.placements && project.placements.map((p) => p.name).join(', '),
        },
        {
            title: <FormattedMessage id="projectIssue.rootCauseId" />,
            dataIndex: 'rootCauseId',
            key: 'rootCauseId',
            width: 190,
            render: (value: string) => rootCauses.find((r) => r.id === value)?.title,
        },
        {
            title: <FormattedMessage id="projectIssue.lbsLocation" />,
            dataIndex: 'lbsLocation',
            key: 'lbsLocation',
            width: 190,
            render: (value: string) => locations.find((r) => r.id === value)?.path.join(' > '),
        },
        {
            title: <FormattedMessage id="projectIssue.title" />,
            dataIndex: 'title',
            key: 'title',
            sorter: true,
            width: 300,
            render: (value: string) => value,
        },
        ...getCustomAttributesColumns(items),
        {
            dataIndex: 'link',
            key: 'link',
            fixed: 'right',
            width: 150,
            render: (value: string) => (
                <a href={value} target="_blank" rel="noreferrer noopener">
                    <Button type="link" className={`${styles.goToAccButton} primary-color`}>
                        <FormattedMessage id="projectIssue.gotoAcc" />
                        <RightCircleFilled className={styles.arrowRight} />
                    </Button>
                </a>
            ),
        },
    ];

    let rowSelection: TableRowSelection<ProjectIssue> | undefined;
    rowSelection = {
        preserveSelectedRowKeys: true,
        selectedRowKeys,
        onChange: select,
    };

    const scroll = { x: 300 + 200 + (columns.length - 2) * 80 };

    return (
        <LayoutComponent pageId="projectIssues">
            <div className={styles.selectors}>
                <Row gutter={[28, 0]}>
                    <Col span={6} className={styles.selector}>
                        <label className={styles.label}>
                            <FormattedMessage id="projectIssues.hub" />
                        </label>
                        <Select
                            size="large"
                            className={styles.hubs}
                            value={hub?.id}
                            onChange={selectHub}
                            placeholder={<FormattedMessage id="projectIssues.hub.placeholder" />}
                        >
                            {hubOptions}
                        </Select>
                    </Col>
                    <Col span={10} className={styles.selector}>
                        <label className={styles.label}>
                            <FormattedMessage id="projectIssues.projects" />
                        </label>
                        <Select
                            size="large"
                            allowClear
                            mode="multiple"
                            className={styles.projects}
                            value={selectedProjectIds}
                            onChange={selectProjects}
                            placeholder={<FormattedMessage id="projectIssues.projects.placeholder" />}
                            showSearch
                            filterOption={stringService.filterOptions}
                        >
                            {projectOptions}
                        </Select>
                    </Col>
                    <Col span={4} className={styles.selector}>
                        <label className={styles.label}>
                            <FormattedMessage id="projectIssues.status" />
                        </label>
                        <Select
                            size="large"
                            allowClear
                            className={styles.statuses}
                            value={status}
                            onChange={selectStatus}
                            placeholder={<FormattedMessage id="projectIssues.status.placeholder" />}
                            showSearch
                            filterOption={stringService.filterOptions}
                        >
                            {statusOptions}
                        </Select>
                    </Col>
                    <Col span={4} className={styles.selector}>
                        <label className={styles.label}>
                            <FormattedMessage id="projectIssues.types" />
                        </label>
                        <Select
                            size="large"
                            allowClear
                            className={styles.types}
                            value={typeName}
                            onChange={selectType}
                            placeholder={<FormattedMessage id="projectIssues.types.placeholder" />}
                            showSearch
                            filterOption={stringService.filterOptions}
                        >
                            {typeOptions}
                        </Select>
                    </Col>
                </Row>
                <Row gutter={[28, 0]} className={styles.secondFilters}>
                    <Col span={6} className={styles.selector}>
                        <label className={styles.label}>
                            <FormattedMessage id="projectIssues.rootCauses" />
                        </label>
                        <Select
                            size="large"
                            allowClear
                            className={styles.rootCauses}
                            value={rootCauseId}
                            onChange={selectRootCause}
                            placeholder={<FormattedMessage id="projectIssues.rootCauses.placeholder" />}
                            showSearch
                            filterOption={stringService.filterOptions}
                        >
                            {rootCauseOptions}
                        </Select>
                    </Col>
                    <Col span={18} className={styles.selector}>
                        <label className={styles.label}>
                            <FormattedMessage id="projectIssues.assignees" />
                        </label>
                        <Select
                            size="large"
                            allowClear
                            className={styles.assignees}
                            value={selectedAssigneesIds}
                            placeholder={<FormattedMessage id="projectIssues.assignees.placeholder" />}
                            showSearch
                            filterOption={false}
                            onSearch={searchAssignees}
                            onChange={selectAssigneesIds}
                            mode="multiple"
                            onClear={() => setAssignees([])}
                            loading={loading === 'loadingAssignees'}
                        >
                            {assigneesOptions}
                        </Select>
                    </Col>
                    <Divider />
                </Row>
            </div>
            <div className="toolbar">
                <Search
                    placeholder={intl.formatMessage({ id: 'projectIssues.search' })}
                    onSearch={search}
                    size="large"
                    className="search"
                    disabled={selectedProjectIds.length === 0}
                />
                <Space>
                    <Tooltip title={<FormattedMessage id="projectIssues.exportSpreadSheet" />}>
                        <Button
                            size="large"
                            icon={<FileExcelOutlined />}
                            onClick={exportSpreadSheet}
                            loading={loading === 'exporting'}
                            disabled={selectedProjectIds.length === 0 || !projectIssuesPage}
                        ></Button>
                    </Tooltip>
                    <Tooltip
                        title={
                            selectedProjectIds.length > 1 ? (
                                <FormattedMessage id="projectIssues.importWarning" />
                            ) : (
                                <FormattedMessage id="projectIssues.import" />
                            )
                        }
                    >
                        <Link
                            to={`/setup/project-issues/import/${hub?.provider}/${hub?.id}?projectId=${selectedProjectIds[0]}`}
                            style={{
                                pointerEvents: selectedProjectIds.length === 0 ? 'none' : undefined,
                            }}
                        >
                            <Button
                                size="large"
                                icon={<Icon component={CloudUploadSvg} />}
                                disabled={selectedProjectIds.length === 0 || selectedProjectIds.length > 1}
                            ></Button>
                        </Link>
                    </Tooltip>

                    <Dropdown
                        trigger={['click']}
                        placement="bottomRight"
                        disabled={selectedProjectIds.length === 0 || selectedProjectIds.length > 1}
                        menu={{
                            items: [
                                {
                                    key: 'copy',
                                    icon: <Icon component={CloudUploadSvg} />,
                                    onClick: () => setCopyIssuesModalVisible(true),
                                    disabled: isCopyButtonDisabled(),
                                    label: <FormattedMessage id="projectIssues.copy" />,
                                },
                            ],
                        }}
                    >
                        {selectedProjectIds.length > 1 ? (
                            <Tooltip title={<FormattedMessage id="projectIssues.actionsWarning" />}>
                                <Button
                                    type="primary"
                                    size="large"
                                    disabled={selectedProjectIds.length === 0 || selectedProjectIds.length > 1}
                                >
                                    <FormattedMessage id="button.actions" /> <Icon component={CaretDownSvg} />
                                </Button>
                            </Tooltip>
                        ) : (
                            <Button
                                type="primary"
                                size="large"
                                disabled={selectedProjectIds.length === 0 || selectedProjectIds.length > 1}
                            >
                                <FormattedMessage id="button.actions" /> <Icon component={CaretDownSvg} />
                            </Button>
                        )}
                    </Dropdown>
                </Space>
            </div>
            <SelectTableComponent
                dataSource={items}
                columns={columns}
                pagination={tableService.createDynamicPagination(projectIssuesPage, { pageSize })}
                rowKey="id"
                onChange={paginate}
                sortDirections={['ascend', 'descend']}
                showSorterTooltip={false}
                loading={loading === 'loading'}
                rowSelection={rowSelection}
                selectedItems={selectedProjectIssues.map((projectIssue) => projectIssue.identifier)}
                onUnselectAll={unselectAll}
                scroll={scroll}
            />
            {hub && selectedProjectIds.length === 1 && copyIssuesModalVisible && (
                <CopyProjectIssuesModal
                    hub={hub}
                    sourceProject={projects.find((project) => project.id === selectedProjectIds[0])!}
                    projects={projects}
                    selectedProjectIssues={selectedProjectIssues}
                    onCancel={() => setCopyIssuesModalVisible(false)}
                />
            )}
        </LayoutComponent>
    );
};

export default ProjectIssuesPage;

const getCustomAttributesColumns = (items: ProjectIssue[]): ColumnsType<ProjectIssue> => {
    const customAttributesWithoutDuplicates = items
        .flatMap((i) => i.customAttributes)
        .filter(
            (value, index, self) =>
                index === self.findIndex((i) => i.attributeDefinitionId === value.attributeDefinitionId),
        )
        .sort((a, b) => stringService.sort(a.title, b.title));

    return customAttributesWithoutDuplicates.map((customAttribute) => ({
        title: customAttribute.title,
        key: customAttribute.attributeDefinitionId,
        width: 250,
        align: customAttribute.type === 'numeric' ? 'right' : undefined,
        render: (value: any, projectIssue: ProjectIssue) =>
            projectIssue.customAttributes &&
            getItemValue(
                projectIssue.customAttributes.find(
                    (ca) => ca.attributeDefinitionId === customAttribute.attributeDefinitionId,
                ),
            ),
    }));
};

const getItemValue = (item?: ProjectIssueCustomAttribute): React.ReactElement | undefined => {
    if (item && item.valueNumber) {
        return <FormattedNumber value={item.valueNumber} maximumFractionDigits={2} />;
    } else if (item) {
        return <>{item.valueText}</>;
    }
};
