import Icon from '@ant-design/icons';
import { Button, Col, Form, List, Row, Space, Upload } from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import { RuleObject, StoreValue } from 'rc-field-form/lib/interface';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import FileSizeComponent from '../../../../../../../components/FileSizeComponent/FileSizeComponent';
import { Connection, ConnectionLinkHubImport, ConnectionLinkImport } from '../../../../../../../model/entities';
import { ReactComponent as AttachmentSvg } from '../../../../../../../resources/images/attachment.svg';
import { ReactComponent as CloudUpload } from '../../../../../../../resources/images/cloud-upload.svg';
import { ReactComponent as TrashCanSvg } from '../../../../../../../resources/images/trash-can.svg';
import notificationService from '../../../../../../../services/NotificationService';
import styles from './UploadComponent.module.scss';
import connectionLinkApi from '../../../../../../../api/ConnectionLinkApi';

/**
 * Returns the connection links upload component.
 * @props the props
 * @returns the connection links upload component.
 */
const UploadComponent = (props: Props): React.ReactElement => {
    const { onConnectionLinkImports, connection } = props;
    const maxFileSize = 20 * 1024 * 1024;
    /*** HOOKS ***/

    const [form] = Form.useForm();
    const intl = useIntl();
    const [files, setFiles] = useState<UploadFile[]>([]);
    const [loading, setLoading] = useState<boolean>();

    /*** EFFECTS ***/

    /*** METHODS ***/

    const load = async (values: any) => {
        try {
            setLoading(true);

            if (connection && files.length > 0) {
                const file: any = files[0];
                const hubImport: ConnectionLinkHubImport = {
                    sourceHubId: connection.source?.id,
                    targetHubId: connection.target?.id,
                    sourceProvider: connection.source?.provider,
                    targetProvider: connection.target?.provider,
                };
                const connectionLinkImports: ConnectionLinkImport[] = await connectionLinkApi.loadSpreadsheet(
                    file,
                    hubImport,
                );
                onConnectionLinkImports(connectionLinkImports);
            }
        } catch (error) {
            notificationService.displayError(error, intl);
        } finally {
            setLoading(false);
        }
    };

    const uploadFile = (file: UploadFile) => {
        const files: UploadFile[] = [];
        if (!isFileSizeValid(file)) {
            setFiles(files);
        } else {
            files.push(file);
            setFiles(files);
        }

        return false;
    };

    const isFileSizeValid = (file: UploadFile) => !file.size || file.size <= maxFileSize;

    const validateFile = (
        rule: RuleObject,
        value: StoreValue,
        callback: (error?: string) => void,
    ): Promise<void> | void => {
        if (value && value.file && !isFileSizeValid(value.file)) {
            callback(intl.formatMessage({ id: 'status.file.size' }));
        }
        callback();
    };

    const removeFile = () => {
        const files: UploadFile[] = [];
        form.setFieldsValue({
            file: files,
        });
        setFiles(files);
    };

    /*** COMPONENTS ***/

    return (
        <Form form={form} onFinish={load} colon={false} layout="vertical">
            <Row>
                <Col span={8}>
                    <Form.Item
                        name="file"
                        valuePropName="files"
                        label={
                            <>
                                <span>
                                    <FormattedMessage id="connectionLinks.import.steps.1.upload" />
                                </span>
                                <a
                                    href={`${process.env.PUBLIC_URL}/${'M.Link_BatchLinks_Import.xlsx'}`}
                                    download={intl.formatMessage({
                                        id: 'connectionLinks.import.steps.1.upload.file',
                                    })}
                                    className={`${styles.link} primary-color`}
                                >
                                    <FormattedMessage id="connectionLinks.import.steps.1.upload.link" />
                                </a>
                            </>
                        }
                        rules={[
                            {
                                validator: validateFile,
                            },
                        ]}
                        extra={
                            <>
                                <FormattedMessage id="attachment.size" /> <FileSizeComponent value={maxFileSize} />
                            </>
                        }
                    >
                        <Upload.Dragger beforeUpload={uploadFile} fileList={files} showUploadList={false}>
                            <Icon component={CloudUpload} />{' '}
                            <FormattedMessage id="connectionLinks.import.steps.1.upload.button" />
                        </Upload.Dragger>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <List
                        className={styles.files}
                        itemLayout="horizontal"
                        dataSource={files}
                        locale={{ emptyText: <></> }}
                        renderItem={(file) => (
                            <List.Item
                                actions={[
                                    <Button
                                        icon={<Icon component={TrashCanSvg} />}
                                        danger
                                        size="small"
                                        onClick={removeFile}
                                    />,
                                ]}
                            >
                                <List.Item.Meta
                                    avatar={<Icon component={AttachmentSvg} />}
                                    title={file.name}
                                    description={<FileSizeComponent value={file.size} />}
                                />
                            </List.Item>
                        )}
                    />
                </Col>
            </Row>

            <Form.Item className="buttons" shouldUpdate>
                {() => {
                    return (
                        <Space>
                            <Button
                                type="primary"
                                htmlType="submit"
                                size="large"
                                icon={<Icon component={CloudUpload} />}
                                loading={loading}
                                disabled={!connection || files.length === 0}
                            >
                                <FormattedMessage id="button.load" tagName="span" />
                            </Button>
                            <Link to={`/sync/connections/${connection?.id}`}>
                                <Button type="text" size="large">
                                    <FormattedMessage id="button.back" tagName="span" />
                                </Button>
                            </Link>
                        </Space>
                    );
                }}
            </Form.Item>
        </Form>
    );
};

export default UploadComponent;

interface Props {
    connection?: Connection;
    onConnectionLinkImports: (connectionLinkImports: ConnectionLinkImport[]) => void;
}
