import CellnexConfiguration from './CellnexConfiguration';
import LocalConfiguration from './LocalConfiguration';
import ModelicalConfiguration from './ModelicalConfiguration';

export interface IConfiguration {
    getPlatform: () => Platform;
    getAuth: () => any | undefined;
    getParentUrl: () => string | undefined;
    getAdminMicroserviceUrl: () => string;
    getProxyMicroserviceUrl: () => string;
    getSyncMicroserviceUrl: () => string;
    getMioAdminUrl: () => string;
    getAutodeskClientId: () => string;
    getTenant: () => Tenant;
    getParentDomain: () => string;
    getCookiesSubfix: () => string;
    getProcoreUrl: () => string;
}

export type Platform = 'aws' | 'azure';
export type Tenant = 'modelical' | 'cellnex';

// Export configuration for the current client
let configuration: IConfiguration;
if (
    window.location.hostname.endsWith('iris.modelical.io') ||
    window.location.hostname.endsWith('delightfulbush-06ff8246.westeurope.azurecontainerapps.io')
) {
    configuration = new CellnexConfiguration(); // Cellnex Azure
} else if (window.location.hostname.endsWith('modelical.io')) {
    configuration = new ModelicalConfiguration(); // Modelical Azure
} else {
    configuration = new LocalConfiguration();
}
export default configuration;
