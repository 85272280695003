import Icon from '@ant-design/icons';
import { Button, Empty, List } from 'antd';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { EntityWithStatus, Member } from '../../../../../model/entities';
import { ReactComponent as ArrowLeftSvg } from '../../../../../resources/images/arrow-left.svg';
import styles from './ResultComponent.module.scss';

/**
 * Returns the members import result component.
 * @props the props
 * @returns the members import result component.
 */
const ResultComponent = (props: Props): React.ReactElement => {
    const { result } = props;
    /*** HOOKS ***/

    /*** EFFECTS ***/

    /*** METHODS ***/

    /*** COMPONENTS ***/

    return (
        <>
            <FailuresComponent result={result} />
            <SuccessesComponent result={result} />
            {result.length === 0 && <Empty className={styles.empty} />}
            <div className={styles.buttons}>
                <Link to="/setup/members">
                    <Button type="primary" size="large" icon={<Icon component={ArrowLeftSvg} />}>
                        <FormattedMessage id="members.import.steps.3.back" tagName="span" />
                    </Button>
                </Link>
            </div>
        </>
    );
};

export default ResultComponent;

interface Props {
    result: EntityWithStatus<Member>[];
}

const FailuresComponent = (props: Props): React.ReactElement => {
    const { result } = props;

    /*** HOOKS ***/

    const intl = useIntl();

    /*** COMPONENTS ***/

    const failures = result.filter((r) => r.status.type !== 'OK');
    if (failures.length > 0) {
        return (
            <List
                header={
                    <h2>
                        <FormattedMessage id="members.import.steps.3.failure" />
                    </h2>
                }
                dataSource={failures}
                renderItem={(failure, index) => {
                    const member = failure.entity;
                    const email = member.email || '...';
                    const name = [member.firstName, member.lastName].filter((i) => !!i).join(' ');
                    const description = [name, member.company?.name].filter((i) => !!i).join(', ') || '...';
                    return (
                        <List.Item key={index}>
                            <List.Item.Meta title={email} description={description} />
                            <span className={styles.error}>
                                {failure.status.error
                                    ? intl.formatMessage({ id: failure.status.error })
                                    : failure.status.message}
                            </span>
                        </List.Item>
                    );
                }}
                className={styles.failure}
            />
        );
    } else {
        return <></>;
    }
};

const SuccessesComponent = (props: Props): React.ReactElement => {
    const { result } = props;

    /*** COMPONENTS ***/

    const successes = result.filter((r) => r.status.type === 'OK');
    if (successes.length > 0) {
        return (
            <List
                header={
                    <h2>
                        <FormattedMessage id="members.import.steps.3.success" />
                    </h2>
                }
                dataSource={successes}
                renderItem={(success, index) => {
                    const member = success.entity;
                    const email = member.email;
                    const name = `${member.firstName || '...'} ${member.lastName || '...'}`;
                    const description = [name, member.company?.name].filter((i) => i).join(', ');
                    return (
                        <List.Item key={index}>
                            <List.Item.Meta title={email} description={description} />
                        </List.Item>
                    );
                }}
                className={styles.success}
            />
        );
    } else {
        return <></>;
    }
};
