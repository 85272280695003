import { SearchOutlined } from '@ant-design/icons';
import { Button, Col, Form, Modal, Row, Select, Tag, Tooltip, TreeSelect } from 'antd';
import Checkbox, { CheckboxChangeEvent } from 'antd/lib/checkbox/Checkbox';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { v4 as uuidv4 } from 'uuid';
import connectionLinkApi from '../../../../../../api/ConnectionLinkApi';
import fileApi from '../../../../../../api/FileApi';
import folderApi from '../../../../../../api/FolderApi';
import projectApi from '../../../../../../api/ProjectApi';
import { FolderDataNode } from '../../../../../../model/elements';
import {
    Connection,
    ConnectionLink,
    ConnectionLinkTarget,
    File,
    Folder,
    Project,
} from '../../../../../../model/entities';
import notificationService from '../../../../../../services/NotificationService';
import stringService from '../../../../../../services/StringService';
import treeFolderService from '../../../../../../services/TreeFolderService';
import ConnectionLinkFolderModal from '../ConnectionLinkFolderModal/ConnectionLinkFolderModal';
import styles from './ConnectionLinkModal.module.scss';
import ConnectionLinkModalTargets from './ConnectionLinkModalTargets/ConnectionLinkModalTargets';

/**
 * Returns the connection link modal.
 * @returns the connection link modal.
 */
const ConnectionLinkModal = (props: Props): React.ReactElement => {
    const { connection, onCancel, onSave, connectionLinkId, visible } = props;
    /*** HOOKS ***/

    const intl = useIntl();
    const [form] = Form.useForm();
    const [connectionLink, setConnectionLink] = useState<ConnectionLink>();
    const [sourceProjects, setSourceProjects] = useState<Project[]>([]);
    const [targetProjects, setTargetProjects] = useState<Project[]>([]);
    const [sourceFolderNodes, setSourceFolderNodes] = useState<FolderDataNode[]>([]);
    const [sourceFolderNode, setSourceFolderNode] = useState<FolderDataNode>();
    const [files, setFiles] = useState<File[]>([]);
    const [targets, setTargets] = useState<ConnectionLinkTarget[]>([]);
    const [connectionLinkFolderModalVisible, setConnectionLinkFolderModalVisible] = useState<boolean>();
    const [saving, setSaving] = useState<boolean>();
    const [loading, setLoading] = useState<'projects' | 'folders' | 'files' | 'targets'>();

    /*** EFFECTS ***/

    useEffect(() => {
        const init = async () => {
            try {
                setLoading('projects');
                if (connectionLinkId) {
                    // get connection link, source folders and build folder nodes
                    const responses = await Promise.all([
                        connectionLinkApi.get(connectionLinkId),
                        projectApi.list(connection.source!.provider, connection.source!.id),
                        projectApi.list(connection.target!.provider, connection.target!.id),
                    ]);
                    const connectionLink = responses[0];
                    const sourceProjects = responses[1];
                    const targetProjects = responses[2];
                    const sourceFolders = await folderApi.list(
                        connection.source!.provider,
                        connection.source!.id!,
                        connectionLink.source!.project!.id!,
                    );
                    const sourceFolderNodes = sourceFolders.map((f) => treeFolderService.createFolderNode(f));

                    // get source folder node (currently selected)
                    let sourceFolderNode = sourceFolderNodes.find(
                        (f) => f.folder.id === connectionLink.source?.folder?.id,
                    );
                    if (!sourceFolderNode) {
                        try {
                            const sourceFolder = await folderApi.get(
                                connectionLink.source!.folder!.id!,
                                connection.source!.provider,
                                connection.source!.id!,
                                connectionLink.source!.project!.id!,
                            );
                            sourceFolderNode = treeFolderService.createFolderNode(sourceFolder);
                        } catch (error) {
                            // folder has been deleted
                        }
                    }

                    // get targets (include uuid for editing)
                    const targets = connectionLink.targets.map((t) => Object.assign({}, t, { uuid: uuidv4() }));
                    setConnectionLink(connectionLink);
                    setTargets(targets);
                    setSourceProjects(sourceProjects);
                    setTargetProjects(targetProjects);
                    setSourceFolderNodes(sourceFolderNodes);
                    setSourceFolderNode(sourceFolderNode);

                    // list files of source folder node
                    if (sourceFolderNode) {
                        try {
                            setLoading('files');
                            const files = sourceFolderNode.folder
                                ? await fileApi.list(
                                      sourceFolderNode.folder.provider!,
                                      sourceFolderNode.folder.projectId!,
                                      sourceFolderNode.folder.id!,
                                      sourceFolderNode.folder.hubId,
                                  )
                                : [];
                            setFiles(files);
                        } catch (error) {
                            notificationService.displayError(error, intl);
                        } finally {
                            setLoading(undefined);
                        }
                    }

                    // load form values
                    const values: any = Object.assign({}, connectionLink);
                    form.setFieldsValue(values);
                    form.setFieldValue(['source', 'folder'], sourceFolderNode?.folder.name);
                } else {
                    const responses = await Promise.all([
                        projectApi.list(connection.source!.provider, connection.source!.id),
                        projectApi.list(connection.target!.provider, connection.target!.id),
                    ]);
                    const sourceProjects = responses[0];
                    const targetProjects = responses[1];
                    const connectionLink: ConnectionLink = {
                        connectionId: connection.id,
                        source: {
                            includeAllFiles: true,
                            includeSubfolders: false,
                            files: [],
                            extensions: [],
                        },
                        status: 'ENABLED',
                        targets: [],
                    };
                    setConnectionLink(connectionLink);
                    setSourceProjects(sourceProjects);
                    setTargetProjects(targetProjects);
                    form.setFieldsValue(connectionLink);
                }
            } catch (error) {
                notificationService.displayError(error, intl, [
                    { status: 500, message: 'connectionLink.status.notFound' },
                ]);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [connection.id, connection.source, connection.target, connectionLinkId, form, intl]);

    /*** METHODS ***/

    const listFolders = async (projectId?: string) => {
        try {
            setLoading('folders');

            // load source folders
            const sourceFolders = projectId
                ? await folderApi.list(connection.source!.provider, connection.source!.id!, projectId)
                : [];
            const sourceFolderNodes = sourceFolders.map((f) => treeFolderService.createFolderNode(f));
            setSourceFolderNodes(sourceFolderNodes);

            // load project
            if (connectionLink && connectionLink.source) {
                const newConnectionLink = Object.assign({}, connectionLink);
                connectionLink.source.project = sourceProjects.find((p) => p.id === projectId);
                setConnectionLink(newConnectionLink);
            }
        } catch (error) {
            notificationService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    const updateSource = async (id: string, sourceFolderNodes: FolderDataNode[]) => {
        const folderNode: FolderDataNode = treeFolderService.find(id, sourceFolderNodes) as FolderDataNode;
        form.resetFields([['source', 'files']]);
        await listFiles(folderNode?.folder);
    };

    const toggleFiles = (event: CheckboxChangeEvent) => {
        const connectionLinkAux: ConnectionLink = Object.assign({}, connectionLink);
        connectionLinkAux.source!.includeAllFiles = event.target.checked;
        setConnectionLink(connectionLinkAux);
    };

    const listFiles = async (folder?: Folder) => {
        try {
            setLoading('files');
            const files = folder
                ? await fileApi.list(folder.provider!, folder.projectId!, folder.id!, folder.hubId)
                : [];
            setFiles(files);
        } catch (error) {
            notificationService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    const cancel = () => {
        form.resetFields();
        onCancel();
    };

    const submit = async () => {
        try {
            const values = await form.validateFields();
            await save(values);
        } catch (error) {}
    };

    const save = async (values: any) => {
        try {
            setSaving(true);

            const sourceProject = sourceProjects.find((p) => p.id === values.source.project.id);
            const selectedSourceFolderNode =
                (treeFolderService.find(values.source.folder, sourceFolderNodes) as FolderDataNode) || sourceFolderNode;
            const connectionLinkAux: ConnectionLink = Object.assign({}, connectionLink, {
                source: {
                    project: sourceProject,
                    folder: {
                        id: selectedSourceFolderNode?.folder.id,
                        name:
                            connectionLink?.source?.folder?.id === selectedSourceFolderNode?.folder.id
                                ? connectionLink?.source?.folder?.name
                                : selectedSourceFolderNode?.path,
                    },
                    includeAllFiles: values.source.includeAllFiles || false,
                    includeSubfolders: values.source.includeSubfolders || false,
                    maintainFolderStructure: values.source.maintainFolderStructure || false,
                    files: values.source.files,
                },
                targets: targets,
            });
            connectionLinkAux.id
                ? await connectionLinkApi.update(connectionLinkAux)
                : await connectionLinkApi.create(connectionLinkAux);
            form.resetFields();
            onSave();
        } catch (error: any) {
            if (error.response.status === 409 && error.response.data) {
                notificationService.displayError(error, intl, [
                    { status: 409, message: 'connectionLink.status.duplicate' },
                ]);
            } else {
                notificationService.displayError(error, intl);
            }
        } finally {
            setSaving(false);
        }
    };

    const loadSubfolderNodes = async (folderId: string) => {
        const sourceFolderNodesAux = [...sourceFolderNodes];
        await treeFolderService.loadSubnodes(folderId, sourceFolderNodesAux);
        setSourceFolderNodes(sourceFolderNodesAux);
    };

    const loadSubfolderNodesByPath = async (folderPath: string): Promise<boolean> => {
        let loaded: boolean = false;
        const folderNames = folderPath
            .split('/')
            .map((folderName) => folderName.trim())
            .filter(Boolean);
        const sourceFolderNodesAux = [...sourceFolderNodes];

        let currentFolderNodes = sourceFolderNodesAux;

        // iterate the folder names
        for (let i = 0; i < folderNames.length; i++) {
            // get folder node by title
            const folderName = folderNames[i];
            const folderNode = currentFolderNodes.find((sfn) => sfn.title === folderName);

            // if folder node exists, load its subnodes
            if (folderNode && folderNode.folder.id) {
                const node = await treeFolderService.loadSubnodes(folderNode.folder.id, sourceFolderNodesAux);
                currentFolderNodes = node && node.children ? (node.children as FolderDataNode[]) : [];

                if (node && i === folderNames.length - 1) {
                    setSourceFolderNodes(sourceFolderNodesAux);
                    form.setFieldsValue({ source: { folder: node.key } });
                    await updateSource(node.key as string, sourceFolderNodesAux);
                    loaded = true;
                }
            }
        }

        return loaded;
    };

    const saveTarget = async (target: ConnectionLinkTarget) => {
        let targetsAux = [...targets];
        const storedTarget = targetsAux.find((t) => t.uuid === target.uuid);
        if (storedTarget) {
            storedTarget.folder = target.folder;
            storedTarget.project = target.project;
        } else {
            targetsAux = [target, ...targetsAux];
        }
        setTargets(targetsAux);
    };

    const removeTarget = async (target: ConnectionLinkTarget) => {
        const targetsAux = targets.filter((t) => t.uuid !== target.uuid);
        setTargets(targetsAux);
    };

    /*** COMPONENTS ***/

    const sourceProjectOptions = sourceProjects
        .sort((a, b) => stringService.sort(a.name, b.name))
        .map((project) => (
            <Select.Option key={project.id} value={project.id}>
                {project.name}
            </Select.Option>
        ));
    const fileOptions = files.map((file) => (
        <Select.Option key={file.id!} value={file.name!}>
            {file.name}
        </Select.Option>
    ));

    return (
        <Modal
            title={
                <>
                    <FormattedMessage id="connectionLink.modal.title" />
                    {connectionLink && connectionLink.cloned && (
                        <Tag className={styles.tag} color="orange">
                            <FormattedMessage id="connectionLink.cloned" />
                        </Tag>
                    )}
                </>
            }
            visible={visible}
            onCancel={cancel}
            onOk={submit}
            okButtonProps={{ loading: saving, disabled: !!loading || targets.length === 0 }}
            cancelButtonProps={{ disabled: saving }}
            width={1200}
        >
            <Form form={form} colon={false} layout="vertical">
                <Row gutter={[28, 0]}>
                    <Col span={12}>
                        <Form.Item
                            label={<FormattedMessage id="connectionLink.source.project" />}
                            name={['source', 'project', 'id']}
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <Select
                                size="large"
                                showSearch
                                filterOption={stringService.filterOptions}
                                onChange={(projectId: string) => listFolders(projectId)}
                                loading={loading === 'projects'}
                                disabled={connectionLink && connectionLink.cloned}
                            >
                                {sourceProjectOptions}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label={
                                <div className={`${styles.label} ${styles.action}`}>
                                    <FormattedMessage id="connectionLink.source.folder" />
                                    <Tooltip title={<FormattedMessage id="connectionLink.source.folder.search" />}>
                                        <Button
                                            type="link"
                                            size="small"
                                            icon={<SearchOutlined />}
                                            onClick={() => setConnectionLinkFolderModalVisible(true)}
                                            disabled={!connectionLink?.source?.project?.id || connectionLink?.cloned}
                                        />
                                    </Tooltip>
                                    <ConnectionLinkFolderModal
                                        onSave={loadSubfolderNodesByPath}
                                        onCancel={() => setConnectionLinkFolderModalVisible(false)}
                                        visible={connectionLinkFolderModalVisible}
                                    />
                                </div>
                            }
                            name={['source', 'folder']}
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <TreeSelect
                                style={{ width: '100%' }}
                                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                treeData={sourceFolderNodes}
                                loadData={(folderNode: any) => loadSubfolderNodes(folderNode.folder.id)}
                                allowClear
                                size="large"
                                onChange={(id: string) => updateSource(id, sourceFolderNodes)}
                                loading={loading === 'folders'}
                                disabled={connectionLink && connectionLink.cloned}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={[28, 0]}>
                    <Col span={4}>
                        <Form.Item name={['source', 'includeAllFiles']} valuePropName="checked">
                            <Checkbox
                                onChange={(event) => toggleFiles(event)}
                                disabled={connectionLink && connectionLink.cloned}
                            >
                                <FormattedMessage id="connectionLink.includeAllFiles" />
                            </Checkbox>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item name={['source', 'includeSubfolders']} valuePropName="checked">
                            <Checkbox disabled={connectionLink && connectionLink.cloned}>
                                <FormattedMessage id="connectionLink.includeSubfolders" />
                            </Checkbox>
                        </Form.Item>
                    </Col>
                    <Col span={16}>
                        <Form.Item name={['source', 'maintainFolderStructure']} valuePropName="checked">
                            <Checkbox disabled={connectionLink && connectionLink.cloned}>
                                <FormattedMessage id="connectionLink.maintainFolderStructure" />
                            </Checkbox>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[28, 0]}>
                    <Col span={24}>
                        <Form.Item
                            name={['source', 'files']}
                            hidden={!connectionLink || connectionLink.source?.includeAllFiles}
                        >
                            <Select
                                size="large"
                                allowClear
                                mode="multiple"
                                showSearch
                                filterOption={stringService.filterOptions}
                                placeholder={<FormattedMessage id="connectionLink.files.placeholder" />}
                                loading={loading === 'files'}
                                disabled={connectionLink && connectionLink.cloned}
                            >
                                {fileOptions}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <ConnectionLinkModalTargets
                connection={connection}
                targets={targets}
                projects={targetProjects}
                onSave={saveTarget}
                onRemove={removeTarget}
                loading={loading === 'targets' || loading === 'projects'}
            />
        </Modal>
    );
};

export default ConnectionLinkModal;

interface Props {
    visible?: boolean;
    connection: Connection;
    connectionLinkId?: number;
    onSave: () => Promise<void>;
    onCancel: () => void;
}
