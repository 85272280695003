export type AuthorityType = 'ROLE_ADMIN' | 'ROLE_USER';

export type ApplicationType = 'SYNC' | 'SETUP';

export type PageType =
    | 'home'
    | 'settings'
    | 'projects'
    | 'projectMembers'
    | 'projectIssues'
    | 'projectSubmissions'
    | 'members'
    | 'connections'
    | 'files'
    | 'tasks';

export const deepLinkAllowedPaths = ['setup', 'sync', 'task', 'settings'] as const;
export type DeepLinkAllowedPathType = (typeof deepLinkAllowedPaths)[number];

export const providerTypes = ['BIM360', 'ACC', 'SHAREPOINT', 'PROCORE'] as const;
export type ProviderType = (typeof providerTypes)[number];

export type DataNodeType = 'HUB' | 'PROJECT' | 'FOLDER';

export type CustomAttributeDefinitionType = 'string' | 'date' | 'array';

export const statuses = ['ENABLED', 'DISABLED'] as const;
export type StatusType = (typeof statuses)[number];

export const memberTypes = ['ADMIN', 'USER'] as const;
export type MemberType = (typeof memberTypes)[number];

export const userTypes = ['AMAZON', 'VENDOR'] as const;
export type UserType = (typeof userTypes)[number];

export const bim360Statuses = ['active', 'inactive', 'pending', 'not_invited'] as const;
export type Bim360Status = (typeof bim360Statuses)[number];

export const accesses = ['none', 'member', 'administrator'] as const;
export type AccessType = (typeof accesses)[number];

export const serviceTypes = [
    'projectAdministration',
    'documentManagement',
    'projectManagement',
    'modelCoordination',
    'fieldManagement',
    'costManagement',
    'quantification',
    'assets',
    'designCollaboration',
    'insight',
] as const;
export type ServiceType = (typeof serviceTypes)[number];

export const frequencies = ['DAILY', 'WEEKLY', 'MONTHLY', 'ANNUALLY'] as const;
export type FrequencyType = (typeof frequencies)[number];

export const taskTypes = [
    'SYNC',
    'MEMBER_COMPANY_UPDATE',
    'MEMBER_STATUS_UPDATE',
    'MEMBER_COST_CENTER_UPDATE',
    'MEMBER_IMPORT',
    'PROJECT_IMPORT',
    'PROJECT_MEMBER_COMPANY_UPDATE',
    'PROJECT_MEMBER_ROLES_UPDATE',
    'PROJECT_MEMBER_IMPORT',
    'PROJECT_MEMBER_DELETE',
    'PROJECT_ISSUES_COPY',
    'SYNC_TASK_COPY_ISSUE_FILE',
    'SYNC_PROJECT_CONVERSION_PROCESS',
    'SYNC_SMART_DELIVERY',
] as const;
export type TaskType = (typeof taskTypes)[number];

export const taskStatusTypes = [
    'CREATED',
    'IN_PROGRESS',
    'SYNCHRONIZING',
    'DOWNLOADING',
    'UPLOADING',
    'SUCCESSFUL',
    'ERROR',
    'FAILED',
    'EMPTY',
] as const;
export type TaskStatusType = (typeof taskStatusTypes)[number];

export const projectStatusTypes = ['ACTIVE', 'PENDING', 'ARCHIVED', 'SUSPENDED'] as const;
export type ProjectStatusType = (typeof projectStatusTypes)[number];

export const entityStatusErrorTypes = [
    'CONNECTION_NOT_SUPPORTED',
    'SOURCE_FOLDER_NOT_FOUND',
    'SOURCE_FILE_NOT_FOUND',
    'SOURCE_FILE_TYPE_NOT_ALLOWED',
    'TARGET_FOLDER_NOT_FOUND',
    'TARGET_FOLDER_NOT_AUTHORIZED',
    'TOO_MANY_REQUESTS',
    'CONNECTION_NOT_SUPPORTED',
    'SOURCE_FOLDER_NOT_FOUND',
    'SOURCE_FILE_NOT_FOUND',
    'SOURCE_FILE_TYPE_NOT_ALLOWED',
    'TARGET_FOLDER_NOT_FOUND',
    'TARGET_FOLDER_NOT_AUTHORIZED',
    'MEMBER_DUPLICATE',
    'EMAIL_INVALID',
    'PROJECT_MEMBER_DUPLICATE',
    'PROJECT_ISSUE_DUPLICATE',
    'PROJECT_ISSUE_TYPE_NOT_FOUND',
    'PROJECT_ISSUE_SUBTYPE_NOT_FOUND',
    'PROJECT_ISSUE_STATUS_NOT_VALID',
    'TARGET_FILE_SIZE_NOT_VALID',
    'CREATION_PROJECT',
] as const;
export type EntityStatusErrorType = (typeof entityStatusErrorTypes)[number];

export const projectTypes = ['SELF_DEVELOPED', 'LEASED_BUILDING'] as const;
export type ProjectType = (typeof projectTypes)[number];

export const projectImportTypes = [
    'Court House',
    'Military Facility',
    'Prison / Correctional Facility',
    'Museum',
    'Religious Building',
    'Recreation Building',
    'Education Facility',
    'Research Facility / Laboratory',
    'Library',
    'Government Building',
    'Dormitory',
    'Theme Park',
    'Parking Structure /Garage',
    'Warehouse (non-manufacturing)',
    'Stadium/Arena',
    'Restaurant',
    'Office',
    'Data Center',
    'Convention Center',
    'Hotel / Motel',
    'Retail',
    'Performing Arts',
    'Oil & Gas',
    'Solar Farm',
    'Power Plant',
    'Plant',
    'Manufacturing / Factory',
    'Wind Farm',
    'Mining Facility',
    'Utilities',
    'Dams / Flood Control / Reservoirs',
    'Harbor / River Development',
    'Water Supply',
    'Streets / Roads / Highways',
    'Tunnel',
    'Transportation Building',
    'Bridge',
    'Seaport',
    'Airport',
    'Waste Water / Sewers',
    'Canal / Waterway',
    'Rail',
    'Hospital',
    'Assisted Living / Nursing Home',
    'Medical Laboratory',
    'Medical Office',
    'OutPatient Surgery Center',
    'Other',
    'Component',
    'Demonstration Project',
    'Training Project',
    'Multi-Facility Housing',
    'Single-Facility Housing',
] as const;
export type ProjectImportType = (typeof projectImportTypes)[number];

export const bim360ProjectServicesTypes = [
    { key: 'Insight', value: undefined },
    { key: 'Document Management', value: 'doc_manager' },
    { key: 'Project Management', value: 'plan' },
    { key: 'Field Management', value: 'field' },
    { key: 'Assets', value: 'field' },
    { key: 'Model Coordination', value: 'glue' },
    { key: 'Design Collaboration', value: undefined },
    { key: 'BIM 360 Field', value: 'field' },
    { key: 'BIM 360 Plan', value: 'plan' },
    { key: 'BIM 360 Glue', value: 'glue' },
];

export const currencyTypes = [
    'USD',
    'AUD',
    'CAD',
    'EUR',
    'GBP',
    'ALL',
    'AZN',
    'BYR',
    'BRL',
    'BGN',
    'CNY',
    'HRK',
    'CZK',
    'DKK',
    'EEK',
    'HKD',
    'HUF',
    'ISK',
    'INR',
    'IRR',
    'ILS',
    'JPY',
    'KZT',
    'KRW',
    'KPW',
    'KGS',
    'LVL',
    'LTL',
    'MKD',
    'MNT',
    'ANG',
    'NOK',
    'PKR',
    'PLN',
    'RON',
    'RUB',
    'SAR',
    'RSD',
    'SGD',
    'ZAR',
    'SEK',
    'CHF',
    'TWD',
    'TRL',
    'UAH',
    'UZS',
    'YER',
    'PHP',
    'NZD',
] as const;
export type CurrencyType = (typeof currencyTypes)[number];

export const projectSubmissionDesignTemplateTypes = ['ARS', 'TNS', 'IXD', 'AMZL', 'REI'] as const;
export type ProjectSubmissionDesignTemplateType = (typeof projectSubmissionDesignTemplateTypes)[number];

export const projectSubmissionStates = ['DRAFT', 'SUBMITTED', 'APPROVED', 'REJECTED'] as const;
export type ProjectSubmissionState = (typeof projectSubmissionStates)[number];

export const projectIssueBim360Statuses = [
    'answered',
    'closed',
    'draft',
    'in_dispute',
    'not_approved',
    'open',
    'ready_to_inspect',
    'void',
    'work_completed',
] as const;
export type ProjectIssueBim360Status = (typeof projectIssueBim360Statuses)[number];

export const projectIssueAccStatuses = [
    'closed',
    'completed',
    'draft',
    'in_dispute',
    'in_progress',
    'in_review',
    'not_approved',
    'open',
    'pending',
] as const;
export type ProjectIssueAccStatus = (typeof projectIssueAccStatuses)[number];

export const projectMemberProducts = [
    'autoSpecs',
    'build',
    'cost',
    'designCollaboration',
    'docs',
    'insight',
    'modelCoordination',
    'takeoff',
] as const;
export type ProjectMemberProductType = (typeof projectMemberProducts)[number];
