import { Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import fileApi from '../../../api/FileApi';
import hubApi from '../../../api/HubApi';
import LayoutComponent from '../../../components/LayoutComponent/LayoutComponent';
import { FolderDataNode } from '../../../model/elements';
import { CustomAttributeDefinition, File, Hub } from '../../../model/entities';
import notificationService from '../../../services/NotificationService';
import stringService from '../../../services/StringService';
import FilesComponent from './FilesComponent/FilesComponent';
import styles from './FilesPage.module.scss';
import FoldersComponent from './FoldersComponent/FoldersComponent';

/**
 * Returns the files page.
 * @returns the files page.
 */
const FilesPage = (): React.ReactElement => {
    /*** HOOKS ***/

    const intl = useIntl();
    const [loading, setLoading] = useState<boolean>();
    const [searchText, setSearchText] = useState<string>();
    const [hubs, setHubs] = useState<Hub[]>([]);
    const [files, setFiles] = useState<File[]>([]);
    const [filteredFiles, setFilteredFiles] = useState<File[]>([]);
    const [customAttributeDefinitions, setCustomAttributeDefinitions] = useState<CustomAttributeDefinition[]>([]);

    /*** EFFECTS ***/

    useEffect(() => {
        const init = async () => {
            try {
                setLoading(true);

                let hubs = await hubApi.list();
                setHubs(hubs);
            } catch (error) {
                notificationService.displayError(error, intl);
            } finally {
                setLoading(false);
            }
        };
        init();
    }, [intl]);

    /*** METHODS ***/

    const listFiles = async (folderNode: FolderDataNode, selected: boolean) => {
        try {
            setLoading(true);

            // load or remove files from selected or unselected folders
            let filesUpdated: File[] = [...files];
            if (selected) {
                const folderFiles = await listFolderFiles(folderNode);
                filesUpdated = filesUpdated.concat(folderFiles);
            } else {
                filesUpdated = filesUpdated.filter(
                    (file) =>
                        file.projectId !== folderNode.folder.projectId ||
                        file.selectedFolderId !== folderNode.folder.id,
                );
            }

            // filter files
            const filteredFiles = filterFiles(filesUpdated, searchText);

            // lit custom attribute definitions
            const customAttributeDefinitions: CustomAttributeDefinition[] = []; // await this.listCustomAttributeDefinitions(files);

            setFiles(filesUpdated);
            setFilteredFiles(filteredFiles);
            setCustomAttributeDefinitions(customAttributeDefinitions);
        } catch (error) {
            notificationService.displayError(error, intl);
        } finally {
            setLoading(false);
        }
    };

    const listFolderFiles = async (folderNode: FolderDataNode) => {
        const folder = folderNode.folder;
        const files: File[] = await fileApi.list(folder.provider!, folder.projectId!, folder.id!, folder.hubId);
        files.forEach((file) => {
            file.selectedFolderId = folder.id;
            file.path = folderNode.path;
        });

        return files;
    };

    const searchFiles = (searchText?: string) => {
        const fiteredFiles = filterFiles(files, searchText);
        setFilteredFiles(fiteredFiles);
        setSearchText(searchText);
    };

    const filterFiles = (files: File[], searchText?: string): File[] => {
        let filteredFiles = [...files];
        const searchTerms = stringService.normalize(searchText).trim().split(' ');
        for (const searchTerm of searchTerms) {
            filteredFiles = filteredFiles.filter(
                (f) =>
                    (f.name && stringService.normalize(f.name).includes(searchTerm)) ||
                    (f.path && stringService.normalize(f.path).includes(searchTerm)) ||
                    (f.updatedBy && stringService.normalize(f.updatedBy).includes(searchTerm)) ||
                    (f.version && f.version.toString() === searchTerm),
            );
        }

        return filteredFiles;
    };
    /* const listCustomAttributeDefinitions = async (files: File[]) => {
        const folders: Folder[] = files
            .map((file) => ({ id: file.folderId, projectId: file.projectId, subfolders: [] }))
            .filter((v, i, a) => a.findIndex((t) => t.id === v.id && t.projectId === v.projectId) === i);

        return folders.length > 0 ? await customAttributeDefinitionApi.list(folders) : [];
    }; */

    /*** COMPONENTS ***/

    return (
        <LayoutComponent pageId="files" style={{ display: 'flex', paddingTop: 0, paddingBottom: 0 }}>
            <Row className={styles.container}>
                <Col flex="20%" className={styles.sidebar}>
                    {hubs.length > 0 && <FoldersComponent hubs={hubs} onFolderSelected={listFiles} />}
                </Col>
                <Col flex="80%" className={styles.main}>
                    <FilesComponent
                        files={filteredFiles}
                        customAttributeDefinitions={customAttributeDefinitions}
                        loading={loading}
                        onSearch={searchFiles}
                    />
                </Col>
            </Row>
        </LayoutComponent>
    );
};

export default FilesPage;
